import { makeAutoObservable } from "mobx";

class StyleStore {
  themePredefinis = [
    {
      id: 1,
      nom: "default",
      main: "#101a4f",
      mainLight: "#7081dd",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#101a4f",
      headerColor: "#101a4f",
      testBackground: "#e1e6ff",
      testElements: "#7081dd",
      testTypo: "#000",
    },
    {
      id: 2,
      nom: "violet",
      main: "#55387C",
      mainLight: "#A888D4",
      mainLightest: "#DAD4E6",
      mainBackground: "#fafafa",
      secondaryBackground: "#DAD4E6",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#55387C",
      headerColor: "#55387C",
      testBackground: "#DAD4E6",
      testElements: "#A888D4",
      testTypo: "#000",
    },
    {
      id: 3,
      nom: "bleu clair",
      main: "#3B5E8C",
      mainLight: "#7994B8",
      mainLightest: "#DFE9F2",
      mainBackground: "#fafafa",
      secondaryBackground: "#DFE9F2",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#3B5E8C",
      headerColor: "#3B5E8C",
      testBackground: "#DFE9F2",
      testElements: "#7994B8",
      testTypo: "#000",
    },
    {
      id: 4,
      nom: "orange foncé",
      main: "#BC4639",
      mainLight: "#D4A59A",
      mainLightest: "#F1E7E5",
      mainBackground: "#fafafa",
      secondaryBackground: "#F1E7E5",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#BC4639",
      headerColor: "#BC4639",
      testBackground: "#F1E7E5",
      testElements: "#D4A59A",
      testTypo: "#000",
    },
    {
      id: 5,
      nom: "orange clair",
      main: "#E85A4F",
      mainLight: "#E98084",
      mainLightest: "#EAE7DC",
      mainBackground: "#fafafa",
      secondaryBackground: "#EAE7DC",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#E85A4F",
      headerColor: "#E85A4F",
      testBackground: "#EAE7DC",
      testElements: "#E98084",
      testTypo: "#000",
    },
    {
      id: 6,
      nom: "jaune",
      main: "#E7B147",
      mainLight: "#F4CF86",
      mainLightest: "#F2EDE2",
      mainBackground: "#fafafa",
      secondaryBackground: "#F2EDE2",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#E7B147",
      headerColor: "#E7B147",
      testBackground: "#F2EDE2",
      testElements: "#F4CF86",
      testTypo: "#000",
    },
    {
      id: 7,
      nom: "gris foncé",
      main: "#5C5C5D",
      mainLight: "#979797",
      mainLightest: "#D3D1D1",
      mainBackground: "#fafafa",
      secondaryBackground: "#D3D1D1",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#5C5C5D",
      headerColor: "#5C5C5D",
      testBackground: "#D3D1D1",
      testElements: "#979797",
      testTypo: "#000",
    },
    {
      id: 8,
      nom: "vert",
      main: "#2B7A78",
      mainLight: "#3AAFA9",
      mainLightest: "#CBE4E3",
      mainBackground: "#fafafa",
      secondaryBackground: "#CBE4E3",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#2B7A78",
      headerColor: "#2B7A78",
      testBackground: "#CBE4E3",
      testElements: "#3AAFA9",
      testTypo: "#000",
    },
    {
      id: 9,
      nom: "gris clair",
      main: "#ABADAF",
      mainLight: "#C7C7C5",
      mainLightest: "#F5F5F5",
      mainBackground: "#fafafa",
      secondaryBackground: "#F5F5F5",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#ABADAF",
      headerColor: "#ABADAF",
      testBackground: "#F5F5F5",
      testElements: "#C7C7C5",
      testTypo: "#000",
    },
    {
      id: 10,
      nom: "custom",
      main: "#101a4f",
      mainLight: "#7081dd",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#101a4f",
      headerColor: "#101a4f",
      testBackground: "#e1e6ff",
      testElements: "#7081dd",
      testTypo: "#000",
    },
    {
      id: 11,
      nom: "bleu fonce - violet",
      main: "#7774F7",
      mainLight: "#4542FC",
      mainLightest: "#DDE7FB",
      mainBackground: "#fafafa",
      secondaryBackground: "#DDE7FB",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#7774F7",
      headerColor: "#7774F7",
      testBackground: "#DDE7FB",
      testElements: "#4542FC",
      testTypo: "#000",
    },
    {
      id: 12,
      nom: "bleu panelabs",
      main: "#288cf0",
      mainLight: "#0674cf",
      mainLightest: "#afcada",
      mainBackground: "#fafafa",
      secondaryBackground: "#DDE7FB",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#288cf0",
      headerColor: "#288cf0",
      testBackground: "#DDE7FB",
      testElements: "#0674cf",
      testTypo: "#000",
    },
    {
      id: 13,
      nom: "rose",
      main: "#F47DE1",
      mainLight: "#F83FAE",
      mainLightest: "#afcada",
      mainBackground: "#fafafa",
      secondaryBackground: "#EFECE9",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#F47DE1",
      headerColor: "#F47DE1",
      testBackground: "#EFECE9",
      testElements: "#F83FAE",
      testTypo: "#000",
    },
  ];

  /**
   * MIS
   */
  themesMIS = [
    {
      id: "MIS",
      nom: "Made in surveys",
      main: "#0394D5",
      mainLight: "#0394D5",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#0394D5",
      headerColor: "#0394D5",
      testBackground: "#cde4f0",
      testElements: "#0394D5",
      testTypo: "#000",
      footer: "© powered by MIS GROUP ",
    },
    {
      id: "MIS_ac3d17ea9002f40074d0257650bf1714",
      nom: "Made in surveys - externe",
      main: "#e30613",
      mainLight: "#e30613",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#e30613",
      headerColor: "#e30613",
      testBackground: "#e7e7e9",
      testElements: "#e30613",
      testTypo: "#000",
      footer: "© powered by MIS GROUP ",
    },
    {
      id: "MIS_63c38af0ed7203d42c4e03e1bc299a23",
      nom: "Made in surveys - RDC",
      main: "#EF8019",
      mainLight: "#EF8019",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#EF8019",
      headerColor: "#EF8019",
      testBackground: "#e7e7e9",
      testElements: "#EF8019",
      testTypo: "#000",
      footer:
        "Enquête réalisée avec le site Réunions de consommateurs | Par MIS Group - Copyright ",
    },

    //PaidFocusGroup
    {
      id: "MIS_033a16486027906f701ef3e2ec589220",
      nom: "Made in surveys - PFG",
      main: "#EF8019",
      mainLight: "#EF8019",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#EF8019",
      headerColor: "#EF8019",
      testBackground: "#cde4f0",
      testElements: "#EF8019",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site PaidFocusGroup | Par MIS Group - Copyright ",
    },

    {
      id: "MIS_b1da7aed9e7b0a453b3286877a1cafb9",
      nom: "Made in surveys - DCM",
      main: "#8c369b",
      mainLight: "#8c369b",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#8c369b",
      headerColor: "#8c369b",
      testBackground: "#cde4f0",
      testElements: "#8c369b",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Devenir Client Mystère | Par MIS Group - Copyright ",
    },

    //Mystery Day Out
    {
      id: "MIS_089e3a9e22c3dcbb709c871e75089b34",
      nom: "Made in surveys - Mistery Day Out",
      main: "#8c369b",
      mainLight: "#8c369b",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#8c369b",
      headerColor: "#8c369b",
      testBackground: "#cde4f0",
      testElements: "#8c369b",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Mystery Day Out | Par MIS Group - Copyright ",
    },
    {
      id: "MIS_13bcb526020e600eeac716af62878814",
      nom: "Made in surveys - TDP",
      main: "#e63054",
      mainLight: "#e63054",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#e63054",
      headerColor: "#e63054",
      testBackground: "#cde4f0",
      testElements: "#e63054",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Tester des Produits | Par MIS Group - Copyright ",
    },
    //PaidProductTesting
    {
      id: "MIS_925445fd82259ec8ff6ee75063589654",
      nom: "Made in surveys - PaidProductTesting",
      main: "#e63054",
      mainLight: "#e63054",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#e63054",
      headerColor: "#e63054",
      testBackground: "#cde4f0",
      testElements: "#e63054",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site PaidProductTesting | Par MIS Group - Copyright ",
    },

    /// TIP
    {
      id: "MIS_13bcb526020e600eeac716af62878814",
      nom: "Made in surveys - TIP",
      main: "#e63054",
      mainLight: "#e63054",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#e63054",
      headerColor: "#e63054",
      testBackground: "#cde4f0",
      testElements: "#e63054",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Testa il Prodotto | Par MIS Group - Copyright ",
    },

    /// TIP IT
    {
      id: "MIS_ce4ece375e186b168a9660b26dbcea68",
      nom: "Made in surveys - TIP",
      main: "#e63054",
      mainLight: "#e63054",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#e63054",
      headerColor: "#e63054",
      testBackground: "#cde4f0",
      testElements: "#e63054",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Testa il Prodotto | Par MIS Group - Copyright ",
    },

    //Free Cosmetics Testing
    {
      id: "MIS_575b4ed8910e8e82624b665a7069a75b",
      nom: "Made in surveys - FCT",
      main: "#E32354",
      mainLight: "#E32354",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#E32354",
      headerColor: "#E32354",
      testBackground: "#cde4f0",
      testElements: "#E32354",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Free Cosmetics Testing  | Par MIS Group - Copyright ",
    },

    // Try New Perfumes
    {
      id: "MIS_29c587e4aaebc7f78f880a129d2fa652",
      nom: "Made in surveys - Try New Perfumes",
      main: "#ff4e53",
      mainLight: "#ff4e53",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#ff4e53",
      headerColor: "#ff4e53",
      testBackground: "#cde4f0",
      testElements: "#ff4e53",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Try New Perfumes | Par MIS Group - Copyright ",
    },

    // PIPEUL
    {
      id: "MIS_2228c626cab4e7676784414650fd2982",
      nom: "Made in surveys - Pipeul",
      main: "#33AA16",
      mainLight: "#33AA16",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#33AA16",
      headerColor: "#33AA16",
      testBackground: "#cde4f0",
      testElements: "#33AA16",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Pipeul | Par MIS Group - Copyright ",
    },

    // Avis Panel
    {
      id: "MIS_a76935ead5f4e372d3f395e74ed86045",
      nom: "Made in surveys - AP",
      main: "#0DAFE1",
      mainLight: "#0DAFE1",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#0DAFE1",
      headerColor: "#0DAFE1",
      testBackground: "#cde4f0",
      testElements: "#0DAFE1",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Avis Panel | Par MIS Group - Copyright ",
    },

    // Panel Opinion
    {
      id: "MIS_f2921e3107955382f433c898e9e41ee8",
      nom: "Made in surveys - PO",
      main: "#f16800",
      mainLight: "#f16800",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#f16800",
      headerColor: "#f16800",
      testBackground: "#cde4f0",
      testElements: "#f16800",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Panel Opinion | Par MIS Group - Copyright ",
    },

    // Survey Friend
    {
      id: "MIS_c372e3604c8625f95b66f641aecdf492",
      nom: "Made in surveys - SF",
      main: "#F18F2D",
      mainLight: "#F18F2D",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#F18F2D",
      headerColor: "#F18F2D",
      testBackground: "#cde4f0",
      testElements: "#F18F2D",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Survey Friends | Par MIS Group - Copyright ",
    },

    //Panel Europa IT
    {
      id: "MIS_6b1efa3744cfca9127f7e54b2483c373",
      nom: "Made in surveys - PE IT",
      main: "#7EB8E6",
      mainLight: "#7EB8E6",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#7EB8E6",
      headerColor: "#7EB8E6",
      testBackground: "#cde4f0",
      testElements: "#7EB8E6",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site PanelEuropa.it | Par MIS Group - Copyright ",
    },

    //Panel Europa DE
    {
      id: "MIS_082466aca1b7ecb34eeb4eaeee2b5f89",
      nom: "Made in surveys - PE DE",
      main: "#008D36",
      mainLight: "#008D36",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#008D36",
      headerColor: "#008D36",
      testBackground: "#cde4f0",
      testElements: "#008D36",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site PanelEuropa.de | Par MIS Group - Copyright ",
    },

    //Panel Europa ES
    {
      id: "MIS_a50ed7858b0eb1f946554aadb2c77e58",
      nom: "Made in surveys - PE ES",
      main: "#DE9A0F",
      mainLight: "#DE9A0F",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#DE9A0F",
      headerColor: "#DE9A0F",
      testBackground: "#cde4f0",
      testElements: "#DE9A0F",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site PanelEuropa.es | Par MIS Group - Copyright ",
    },

    //Avis d'Artisans
    {
      id: "MIS_a0eed1dc01f115d5fa2c5618dbbcf0fc",
      nom: "Made in surveys - Avis d'Artisans",
      main: "#1376B1",
      mainLight: "#1376B1",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#1376B1",
      headerColor: "#1376B1",
      testBackground: "#cde4f0",
      testElements: "#1376B1",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Avis d'Artisans | Par MIS Group - Copyright ",
    },

    //Yoopinion
    {
      id: "MIS_9b7efd51551e724c673932b46aeda320",
      nom: "Made in surveys - Yoopinion",
      main: "#00AFE0 ",
      mainLight: "#00AFE0 ",
      mainLightest: "#e1e6ff",
      mainBackground: "#fafafa",
      secondaryBackground: "#e1e6ff",
      typo: "#000",
      questionColor: "#000",
      modaliteColor: "#000",
      suivantColor: "#00AFE0 ",
      headerColor: "#00AFE0 ",
      testBackground: "#cde4f0",
      testElements: "#00AFE0 ",
      testTypo: "#000",
      footer: "Enquête réalisée avec le site Yoopinion | Par MIS Group - Copyright ",
    },
  ];

  theme = null;
  isTypeMIS = false;
  footerMIS = "© powered by MIS GROUP ";

  //loaders
  themeLoaded = false;

  //couleur
  mainColor = "#0394d5";

  constructor() {
    makeAutoObservable(this);
  }

  setTheme(theme, theme_custom) {
    this.theme = theme;
    let theme_utilise = this.themePredefinis[0];

    // Theme MIS
    if (typeof theme === "string") {
      theme_utilise = this.themesMIS[0];

      this.themesMIS.forEach((themeMIS) => {
        if (themeMIS.id === theme) {
          this.isTypeMIS = true;
          this.footerMIS = themeMIS.footer;
          this.mainColor = themeMIS.main;
          theme_utilise = themeMIS;
        }
      });
    }
    // Theme custom
    else if (theme === 10 && theme_custom) {
      theme_utilise = theme_custom;
      theme_utilise.mainLight = theme_custom.secondaryElementColor;
      theme_utilise.mainLightest = theme_custom.secondaryElementColor;
      theme_utilise.mainBackground = theme_custom.mainBackColor;
      theme_utilise.secondaryBackground = theme_custom.secondaryBackColor;
      theme_utilise.typo = theme_custom.modaliteColor;
      theme_utilise.testBackground = theme_custom.testBackColor;
      theme_utilise.testElements = theme_custom.testElementsColor;
      theme_utilise.testTypo = theme_custom.testQuestionColor;
    }

    // Theme de bases (predefinis)
    else {
      this.themePredefinis.forEach((themePre) => {
        if (themePre.id === theme) {
          theme_utilise = themePre;
        }
      });
    }

    document.documentElement.style.setProperty("--main-color", theme_utilise.main);
    document.documentElement.style.setProperty("--main-color-light", theme_utilise.mainLight);
    document.documentElement.style.setProperty("--main-color-lightest", theme_utilise.mainLightest);
    document.documentElement.style.setProperty(
      "--main-color-background",
      theme_utilise.mainBackground
    );
    document.documentElement.style.setProperty(
      "--secondary-color-background",
      theme_utilise.secondaryBackground
    );

    document.documentElement.style.setProperty("--header-color-typo", theme_utilise.headerColor);
    document.documentElement.style.setProperty("--suivant-color-typo", theme_utilise.suivantColor);
    document.documentElement.style.setProperty(
      "--question-color-typo",
      theme_utilise.questionColor
    );
    document.documentElement.style.setProperty(
      "--modalite-color-typo",
      theme_utilise.modaliteColor
    );

    document.documentElement.style.setProperty("--main-color-typo", theme_utilise.typo);

    document.documentElement.style.setProperty(
      "--test-background-volet",
      theme_utilise.testBackground
    );
    document.documentElement.style.setProperty("--test-color", theme_utilise.testElements);
    document.documentElement.style.setProperty("--test-typo-color", theme_utilise.testTypo);
    document.documentElement.style.setProperty("--test-title-color", theme_utilise.testTitleColor);
  }
}

const store = new StyleStore();
export default store;
