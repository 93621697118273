//REACT
import React, { useEffect } from "react";

import questionStore from "../../store-mobx/questionStore";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";
import SvgEtoile from "../../svg/Etoile";
import SvgPouce from "../../svg/Pouce";
import SvgCoeur from "../../svg/Coeur";

import SvgSmiley1 from "../../svg/Smiley1";
import SvgSmiley2 from "../../svg/Smiley2";
import SvgSmiley3 from "../../svg/Smiley3";
import SvgSmiley4 from "../../svg/Smiley4";
import SvgSmiley5 from "../../svg/Smiley5";
import SvgSmiley6 from "../../svg/Smiley6";
import SvgSmiley7 from "../../svg/Smiley7";
import SvgSmiley8 from "../../svg/Smiley8";
import SvgSmiley9 from "../../svg/Smiley9";
import SvgSmiley10 from "../../svg/Smiley10";
import SvgSmiley11 from "../../svg/Smiley11";

const Satisfaction = observer(({ question, errors }) => {
  const { t } = useTranslation();

  let colors = [
    "#a60600",
    "#e81112",
    "#fd6a38",
    "#fd9338",
    "#ffc664",
    "#e6dea4",
    "#d3dd86",
    "#c7d954",
    "#96c354",
    "#67aa64",
    "#35933a",
  ];

  const smileys = [
    SvgSmiley1,
    SvgSmiley2,
    SvgSmiley3,
    SvgSmiley4,
    SvgSmiley5,
    SvgSmiley6,
    SvgSmiley7,
    SvgSmiley8,
    SvgSmiley9,
    SvgSmiley10,
    SvgSmiley11,
  ];

  useEffect(() => {
    scrollToTop();
  }, []);

  /**
   * Recupere l'element selectionné
   * @param {object} critere
   * @param {Number} note
   * @returns
   */
  const recupereSelection = (critere, note) => {
    let valeurChecked = -1;
    let checked = false;

    questionStore.reponse.forEach((rep) => {
      if (critere.id === rep.critere_id) {
        if (String(note) === String(rep.value)) {
          checked = true;
        }
        valeurChecked = rep.value;
      }
    });

    return {
      valeurChecked,
      checked,
    };
  };

  /**
   * Gestion de la note d'un critere
   * @param {object} critere
   * @param {number} value
   */
  const handleChange = (critere, value) => {
    var tempReponse = Object.assign([], questionStore.reponse);

    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);

    if (value === "non_concerne") {
      if (reponseIndex !== -1) {
        tempReponse[reponseIndex].value = value;
      } else {
        tempReponse.push({
          critere_id: critere.id,
          value: value,
        });
      }
    } else {
      if (reponseIndex !== -1) {
        tempReponse[reponseIndex].value = value;
      } else {
        tempReponse.push({ critere_id: critere.id, value: value });
      }
    }
    questionStore.reponse = tempReponse;
  };

  /**
   * Gestion du changement de texte dans le commentaire d'un critere
   * @param {object} critere
   * @param {number} value
   */
  const handleCommentaire = (critere, value) => {
    var tempReponse = Object.assign([], questionStore.reponse);

    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);
    if (reponseIndex !== -1) {
      tempReponse[reponseIndex].commentaire = value;
    } else {
      tempReponse.push({
        critere_id: critere.id,
        commentaire: value,
        value: "",
      });
    }
    questionStore.reponse = tempReponse;
  };

  /**
   * Fleche version mobile
   * @param {object} critere
   * @param {string} direction
   */
  const handleFleche = (critere, direction) => {
    var tempReponse = Object.assign([], questionStore.reponse);
    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);
    if (reponseIndex !== -1) {
      var newValue = tempReponse[reponseIndex].value;

      switch (direction) {
        case "gauche":
          if (newValue - 1 >= question.echelle.min) {
            newValue--;
          }
          break;
        case "droite":
          if (newValue + 1 <= question.echelle.max) {
            newValue++;
          }
          break;

        default:
          break;
      }

      tempReponse[reponseIndex].value = newValue;
    }
  };

  /**
   * Recupere les indices pour l'attribution des couleurs ou des smileys
   * @param {number} nbSmileys
   * @returns
   */
  const recupererIndicesCouleurs = (nbSmileys) => {
    switch (nbSmileys) {
      case 2:
        return [2, 10];
      case 3:
        return [2, 6, 10];
      case 4:
        return [2, 3, 9, 10];
      case 5:
        return [2, 3, 6, 9, 10];
      case 6:
        return [2, 3, 4, 8, 9, 10];
      case 7:
        return [2, 3, 4, 6, 8, 9, 10];
      case 8:
        return [2, 3, 4, 5, 7, 8, 9, 10];
      case 9:
        return [2, 3, 4, 5, 6, 7, 8, 9, 10];
      case 10:
        return [1, 2, 3, 4, 5, 7, 8, 9, 10, 11];
      case 11:
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      default:
        return [];
    }
  };

  return (
    <div className="satisfaction">
      {question.criteres.map((critere, indexCritere) => {
        let notation = [];
        for (let index = question.echelle.min; index <= question.echelle.max; index++) {
          notation.push(index);
        }

        let NCchecked = false;
        let notePresente = false;

        if (Array.isArray(questionStore.reponse) === true) {
          questionStore.reponse.forEach((rep) => {
            if (rep.critere_id === critere.id && rep.value === "non_concerne") {
              NCchecked = true;
            } else if (rep.critere_id === critere.id) {
              notePresente = true;
            }
          });
        }

        return (
          <div key={critere.id} className={`critere-container ${question.format}`}>
            <div className={errors[critere.id + "_global"] ? "label error" : "label"}>
              <Interweave content={critere.label} />
            </div>

            {question.options.afficher_etiquettes && (
              <div className="etiquettes">
                <div
                  className={
                    errors[critere.id + "_global"] || errors[critere.id + "_note"]
                      ? "gauche error"
                      : "gauche"
                  }
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="arrow" />
                  {question.etiquettes.gauche}
                </div>
                <div
                  className={
                    errors[critere.id + "_global"] || errors[critere.id + "_note"]
                      ? "droite error"
                      : "droite"
                  }
                >
                  {question.etiquettes.droite}{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                </div>
              </div>
            )}

            <div
              className={
                errors[critere.id + "_global"] || errors[critere.id + "_note"]
                  ? "notation error"
                  : "notation"
              }
            >
              {question.format === "smileys" &&
                notation.map((note, index) => {
                  let checked = false;
                  let valeurCritere = -1;
                  let smileysNotation = recupererIndicesCouleurs(notation.length);
                  let SmileyDynamique = smileys[smileysNotation[index] - 1];

                  questionStore.reponse.forEach((rep) => {
                    if (critere.id === rep.critere_id && rep.value !== "") {
                      if (String(note) === String(rep.value)) {
                        checked = true;
                      }
                      valeurCritere = rep.value;
                    }
                  });

                  return (
                    <label
                      key={index}
                      className={`${checked ? "note checked valide" : "note"} ${
                        valeurCritere >= 0 && valeurCritere - 1 !== index ? "gris" : ""
                      }`}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />
                      <SmileyDynamique />
                    </label>
                  );
                })}

              {question.format === "symboles" &&
                question.symbole === "etoile" &&
                notation.map((note, index) => {
                  const { valeurChecked, checked } = recupereSelection(critere, note);
                  const indexChecked = valeurChecked - 1; // symbole min = 1 donc en -1 pour l'indice

                  return (
                    <label
                      key={note}
                      className={`${checked ? "checked" : ""} ${
                        Number.isInteger(indexChecked) && indexChecked > -1 && index <= indexChecked
                          ? `note valide ${question.taille_symbole ?? "grand"}`
                          : `note ${question.taille_symbole ?? "grand"}`
                      }`}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />
                      <SvgEtoile />
                    </label>
                  );
                })}

              {question.format === "symboles" &&
                question.symbole === "pouce" &&
                notation.map((note, index) => {
                  const { valeurChecked, checked } = recupereSelection(critere, note);
                  const indexChecked = valeurChecked - 1; // symbole min = 1 donc en -1 pour l'indice

                  return (
                    <label
                      key={note}
                      className={`${checked ? "checked" : ""} ${
                        Number.isInteger(indexChecked) && indexChecked > -1 && index <= indexChecked
                          ? `note valide ${question.taille_symbole ?? "grand"}`
                          : `note ${question.taille_symbole ?? "grand"}`
                      }`}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />
                      <SvgPouce />
                    </label>
                  );
                })}

              {question.format === "symboles" &&
                question.symbole === "coeur" &&
                notation.map((note, index) => {
                  const { valeurChecked, checked } = recupereSelection(critere, note);
                  const indexChecked = valeurChecked - 1; // symbole min = 1 donc en -1 pour l'indice

                  return (
                    <label
                      key={note}
                      className={`${checked ? "checked" : ""} ${
                        Number.isInteger(indexChecked) && indexChecked > -1 && index <= indexChecked
                          ? `note valide ${question.taille_symbole ?? "grand"}`
                          : `note ${question.taille_symbole ?? "grand"}`
                      }`}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />
                      <SvgCoeur />
                    </label>
                  );
                })}

              {question.format === "degrade" &&
                notation.map((note, index) => {
                  let squaresNotation = recupererIndicesCouleurs(notation.length);
                  let valueChecked = -1;
                  let checked = false;
                  questionStore.reponse.forEach((rep) => {
                    if (critere.id === rep.critere_id && rep.value !== "") {
                      if (String(note) === String(rep.value)) {
                        checked = true;
                      }
                      valueChecked = rep.value;
                    }
                  });

                  return (
                    <label
                      key={note}
                      className={`${checked ? "note checked" : "note"} ${
                        question.options.masquer_couleurs ? "sans-couleur" : ""
                      }`}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />

                      <div
                        className="carre-block"
                        style={{
                          background:
                            !question.options.masquer_couleurs &&
                            (valueChecked === -1 || valueChecked >= index)
                              ? colors[squaresNotation[index] - 1]
                              : "#ccc",
                        }}
                      >
                        {!question.options.masquer_notes && note}
                      </div>
                    </label>
                  );
                })}

              {question.format === "notes" &&
                notation.map((note) => {
                  const { checked } = recupereSelection(critere, note);

                  return (
                    <label
                      key={note}
                      className={checked ? "note checked" : "note"}
                      onClick={() => handleChange(critere, note)}
                    >
                      <Field
                        type="radio"
                        name={critere.id + "_note"}
                        value={note}
                        checked={checked}
                        data-testid={"reponse_" + critere.value}
                      />
                      {note}
                    </label>
                  );
                })}
            </div>

            {isMobile && notePresente && (
              <>
                <div className="fleches">
                  <div className="gauche" onClick={() => handleFleche(critere, "gauche")}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>

                  {question.format === "symboles" && (
                    <div className="centre">
                      {question.echelle.min === "0"
                        ? parseInt(questionStore.reponse?.[indexCritere]?.value)
                        : questionStore.reponse?.[indexCritere]?.value}
                      /{question.echelle.max}
                    </div>
                  )}

                  <div className="droite" onClick={() => handleFleche(critere, "droite")}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </>
            )}

            {question.sous_echelle.ajouter_commentaire && (
              <div className="commentaire">
                <Field
                  type="text"
                  className={
                    errors[critere.id + "_commentaire"]
                      ? "commentaire-input error"
                      : "commentaire-input"
                  }
                  name={critere.id + "_commentaire"}
                  placeholder={
                    (question.sous_echelle.label_commentaire
                      ? question.sous_echelle.label_commentaire
                      : t("echelle.entrez_commentaire")) +
                    (question.sous_echelle.commentaire_facultatif
                      ? " (" + t("facultatif") + ")"
                      : "")
                  }
                  onChange={(e) => handleCommentaire(critere, e.target.value)}
                />
              </div>
            )}

            {question.sous_echelle.ajouter_non_concerne && (
              <div className="non-concerne">
                <label className={"note-nc"} key={"nc"}>
                  <Field
                    type="radio"
                    name={critere.id + "_note"}
                    value={"non_concerne"}
                    checked={NCchecked}
                    onClick={() => {
                      handleChange(critere, "non_concerne");
                    }}
                  />
                  {question.sous_echelle.label_non_concerne
                    ? question.sous_echelle.label_non_concerne
                    : t("echelle.non_concerne")}
                </label>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default Satisfaction;
