import * as React from "react";

function EsurveyBlanc(props) {
  return (
    <svg
      version="1.1"
      id="esurveyblanc"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 154 30"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          fill="#fff"
          d="M15.1,0.1C6.8,0.1,0.1,6.7,0.1,15s6.7,14.9,14.9,14.9C23.3,29.9,30,23.2,30,15S23.3,0.1,15.1,0.1z M9.5,21.7
		L9.5,21.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C10.6,21.2,10.1,21.7,9.5,21.7z M8.3,15
		c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1S8.3,15.6,8.3,15z M9.5,10.5L9.5,10.5c-0.6,0-1.1-0.5-1.1-1.1
		c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C10.6,10,10.1,10.5,9.5,10.5z M20.7,21.7h-6.9c-0.6,0-1.1-0.5-1.1-1.1
		c0-0.6,0.5-1.1,1.1-1.1h6.9c0.6,0,1.1,0.5,1.1,1.1C21.9,21.2,21.4,21.7,20.7,21.7z M12.7,15c0-0.6,0.5-1.1,1.1-1.1h3.1
		c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1h-3.1C13.2,16.1,12.7,15.6,12.7,15z M20.7,10.5h-6.9c-0.6,0-1.1-0.5-1.1-1.1
		c0-0.6,0.5-1.1,1.1-1.1h6.9c0.6,0,1.1,0.5,1.1,1.1C21.9,10,21.4,10.5,20.7,10.5z"
        />
        <g>
          <path
            fill="#fff"
            d="M45.4,7.1c-4.6,0-7.7,3.2-7.7,8c0,4.8,3.2,7.9,8.1,7.9c2.7,0,5-1,6.4-2.9l0,0l-1.8-1.7l0,0
			c-0.7,0.9-2,2-4.4,2c-3,0-4.9-1.7-5.3-4.6h11.6l0,0c0,0,0.1-0.6,0.1-1.5C52.5,10,49.7,7.1,45.4,7.1z M45.4,9.6
			c2.4,0,4,1.6,4.1,4.2h-8.6C41.3,11.2,43.1,9.6,45.4,9.6z"
          />
          <rect x="54.8" y="13.7" fill="#fff" width="5.8" height="2.2" />
          <path
            fill="#fff"
            d="M88.7,15.4c0,2.1-1.4,5.1-4.3,5.1c-2.2,0-3.6-1.4-3.6-3.7V7.3h-3v9.5c0,3.8,2.4,6.2,6.1,6.2
			c2.3,0,4.1-1.2,5.1-3.3c0,0.5,0.1,2.1,0.4,3.2l0,0l2.2-0.1V7.3h-3V15.4z"
          />
          <path
            fill="#fff"
            d="M101.3,7.1c-2.7,0-3.8,2.3-4.2,3.7h-0.1c0-0.8-0.1-2.5-0.4-3.6l0,0l-2.1,0.1v15.5h3v-8.1
			c0-3.1,1.8-4.7,3.7-4.7c0.6,0,1,0.1,1.4,0.4l0,0l0.7-3l0,0C102.7,7.2,102,7.1,101.3,7.1z"
          />
          <path
            fill="#fff"
            d="M113.9,18.1c-0.3,0.7-0.4,1-0.5,1.7h0c-0.1-0.6-0.2-0.9-0.5-1.7l-4.3-10.8h-3.3l6.4,15.5h3.2l6.3-15.5h-3
			L113.9,18.1z"
          />
          <path
            fill="#fff"
            d="M129.8,7.1c-4.6,0-7.7,3.2-7.7,8c0,4.8,3.2,7.9,8.1,7.9c2.7,0,5-1,6.4-2.9l0,0l-1.8-1.7l0,0
			c-0.7,0.9-2,2-4.4,2c-3,0-4.9-1.7-5.3-4.6h11.6l0,0c0,0,0.1-0.6,0.1-1.5C136.9,10,134.1,7.1,129.8,7.1z M129.8,9.6
			c2.4,0,4,1.6,4.1,4.2h-8.6C125.7,11.2,127.4,9.6,129.8,9.6z"
          />
          <path
            fill="#fff"
            d="M66.7,10.1c0.2-0.2,0.7-0.4,1.7-0.5c0.1,0,0.2,0,0.2,0c1.7,0,3.1,0.6,3.6,0.9c0.5,0.3,1,0.6,1.4,1l0,0l1.8-2
			l0,0c-0.5-0.5-1.1-0.8-1.7-1.2c-1.5-0.8-3.2-1.2-4.9-1.2c-1.1,0-2.2,0.2-3.1,0.6c-0.4,0.2-0.8,0.4-1.1,0.6
			c-0.2,0.1-1.8,1.4-1.9,3.4c0,1,0.4,1.7,0.5,1.9c1,1.7,3.3,2.3,5.3,2.7c2,0.5,3.6,0.9,3.6,2.2c0,1.2-1.2,2-3.4,2
			c-2.2,0-4.1-1.1-4.9-1.9l0,0l-1.8,2l0,0c0.9,0.7,2.9,2.3,6.7,2.3c2.3,0,4.2-0.6,5.4-1.7c0.7-0.7,1.1-1.6,1.1-2.7
			c-0.1-3.3-3-3.9-5.6-4.5c-2-0.4-3.8-0.8-3.8-2.4c0-0.1,0-0.5,0.2-0.8C66.1,10.6,66.3,10.3,66.7,10.1z"
          />
          <path
            fill="#fff"
            d="M150.8,7.3L146,19.6l-4.8-12.3h-3.3l6.6,15.9c-0.9,2.2-1.7,4.5-2.6,6.7h2.9c3-7.5,6-15.1,9.1-22.6H150.8z"
          />
        </g>
      </g>
    </svg>
  );
}

export default EsurveyBlanc;
