//MOBX ET REACT
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "mobx-react";

import Navigator from "./Navigator";
import "./i18n";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config";
import ReactGA from "react-ga";
import styleStore from "./store-mobx/styleStore";
import questionStore from "./store-mobx/questionStore";
import questionnaireStore from "./store-mobx/questionnaireStore";

import { configure } from "mobx";

// socket
import { io } from "socket.io-client";
import { SocketProvider } from "./sockets/Socket";

const socket = io(process.env.REACT_APP_URL_WEBSOCKET, {
  autoConnect: false,
});

const trackingId = "G-S3Q1CNJETT"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  // any data that is relevant to the user session
  // that you would like to track with google analytics
});

// ReactDOM.render(
//   <Provider questionStore={questionStore}>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Navigator />
//     </ThemeProvider>
//   </Provider>,
//   document.getElementById("root")
// );

configure({
  enforceActions: "never",
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
  disableErrorBoundaries: false,
});

const stores = {
  styleStore: styleStore,
  questionStore: questionStore,
  questionnaireStore: questionnaireStore,
};

class App extends React.Component {
  onUnload = (e) => {
    console.log("unload");
    var clef = window.localStorage.getItem("clef_partage");
    var params_externe = window.localStorage.getItem("params_externe");
    var type = window.sessionStorage.getItem("type");
    localStorage.clear();
    window.localStorage.setItem("clef_partage", clef);
    window.localStorage.setItem("params_externe", params_externe);
    if (type) {
      window.sessionStorage.setItem("type", type);
    }
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  render() {
    return (
      <Provider {...stores}>
        <SocketProvider socket={socket}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navigator />
          </ThemeProvider>
        </SocketProvider>
      </Provider>
    );
  }
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
