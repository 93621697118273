//REACT
import React, { Component } from "react";

//MATERIAL
import { Checkbox, RadioGroup, FormControlLabel } from "@mui/material";
import questionStore from "../../store-mobx/questionStore";
import styleStore from "../../store-mobx/styleStore";
import { Suspense } from "react";
import { withTranslation } from "react-i18next";
import { scrollToTop } from "./HelpersQuestion";
import { isMobile } from "react-device-detect";
import { observer } from "mobx-react";
import { Interweave } from "interweave";

@observer
class HOCChoixMultiple extends Component {
  constructor(props) {
    super(props);

    this.myRefs = React.createRef([]);

    this.state = {
      selected: [], //modalites cochees
      autres: [], //precisions
      modalites: this.props.question.modalites,
      options: this.props.question.options,
      //itemAucun: this.props.question.options.aucun,
    };
  }
  componentDidMount() {
    scrollToTop();
  }

  handleChange(event, modalite) {
    var newSelected = this.state.selected;
    var newAutres = this.state.autres;
    var index = "";
    var indexPrecisionToDelete = -1;
    var aucunExists = false;

    this.state.modalites.forEach((moda) => {
      if (moda.aucun === true) {
        aucunExists = true;
      }
    });

    //SI AUCUN
    if (aucunExists) {
      if (event.target.checked && modalite.aucun === true) {
        newSelected = [];
        newSelected.push(event.target.value);
        this.setState({ autres: [] });
      } else if (event.target.checked) {
        if (this.state.options && this.state.options.max) {
          if (newSelected.length < this.state.options.max) {
            this.state.modalites.forEach((moda) => {
              if (moda.aucun === true) {
                index = newSelected.indexOf(moda.id);
                if (index > -1) {
                  newSelected.splice(index, 1);
                }
              }
            });
            newSelected.push(event.target.value);
          }
        } else {
          this.state.modalites.forEach((moda) => {
            if (moda.aucun === true) {
              index = newSelected.indexOf(moda.id);
              if (index > -1) {
                newSelected.splice(index, 1);
              }
            }
          });
          newSelected.push(event.target.value);
        }
      } else {
        index = newSelected.indexOf(event.target.value);

        //Si la réponse existe déjà on supprime
        if (index > -1) {
          newSelected.splice(index, 1);
        }

        indexPrecisionToDelete = -1;
        newAutres.forEach((precision, indexPrecision) => {
          if (precision.modalite_id === event.target.value) {
            indexPrecisionToDelete = indexPrecision;
          }
        });
        if (indexPrecisionToDelete !== -1) {
          newAutres.splice(indexPrecisionToDelete, 1);
        }
      }
    }
    //SINON
    else {
      if (event.target.checked) {
        if (this.state.options && this.state.options.max) {
          if (newSelected.length < this.state.options.max) {
            newSelected.push(event.target.value);
          }
        } else {
          newSelected.push(event.target.value);
        }
      } else {
        index = newSelected.indexOf(event.target.value);
        if (index > -1) {
          newSelected.splice(index, 1);
        }

        indexPrecisionToDelete = -1;
        newAutres.forEach((precision, indexPrecision) => {
          if (precision.modalite_id === event.target.value) {
            indexPrecisionToDelete = indexPrecision;
          }
        });
        if (indexPrecisionToDelete !== -1) {
          newAutres.splice(indexPrecisionToDelete, 1);
        }
      }
    }

    this.setState({ selected: newSelected, autres: newAutres });
    questionStore.reponse = newSelected;
    //this.props.setFieldValue(this.props.name, this.state.selected);
  }

  handleChangePrecision(event, modalite) {
    var newSelected = this.state.selected;
    var newAutres = this.state.autres;
    var index;

    var aucunValue = null;
    this.state.modalites.forEach((moda) => {
      if (moda.aucun === true) {
        aucunValue = moda.id;
      }
    });

    //SI ON A DU TEXTE
    if (event.target.value !== "") {
      var exists = false;
      newAutres.forEach((autre) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
        }
      });
      if (exists === false) {
        //JE PASSE BIEN ICI
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      } else {
        index = newAutres.indexOf(modalite.id);
        newAutres.splice(index, 1);
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      }
      if (newSelected.includes(modalite.id) === false) {
        if (!this.state.options.max) {
          newSelected.push(modalite.id);
          newSelected = newSelected.filter((selectedModa) => selectedModa !== aucunValue);
        } else if (newSelected.length < this.state.options.max) {
          newSelected.push(modalite.id);
          newSelected = newSelected.filter((selectedModa) => selectedModa !== aucunValue);
        }
      }
    }
    //SI ON A pas DE TEXTE
    else {
      newAutres.forEach((autre, index) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
          newAutres.splice(index, 1);
        }
      });

      index = newSelected.indexOf(modalite.id);
      if (newSelected.includes(modalite.id)) {
        newSelected.splice(index, 1);
      }
    }

    //SI LA PRECISION EST SUR UNE MODALITE AUCUN
    if (modalite.aucun === true) {
      newSelected = [];
      newSelected.push(modalite.id);

      newAutres = [];
      newAutres.push({
        modalite_id: modalite.id,
        precision: event.target.value,
      });
    }

    this.setState({ selected: newSelected, autres: newAutres });
    // this.props.setFieldValue("reponse_autre", newAutres);
    // this.props.setFieldValue("reponse", newSelected);

    questionStore.reponse = newSelected;
    questionStore.reponse_autre = newAutres;
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;

    //On définit le nombre de colonnes
    var nb_colonnes = 1;
    var blocModalites;
    if (
      this.props.question.options &&
      this.props.question.options.nb_colonnes &&
      this.props.question.options.nb_colonnes !== ""
    ) {
      nb_colonnes = this.props.question.options.nb_colonnes;
    }

    //Cas ou l'affichage est classique
    if (nb_colonnes === 1 || isMobile) {
      if (this.props.question.modalites.length > 0) {
        blocModalites = this.props.question.modalites.map((modalite, index) => {
          //if (index === 0) {
          var valuePrecision = "";
          this.state.autres.forEach((autre) => {
            if (autre.modalite_id === modalite.id) {
              valuePrecision = autre.precision;
            }
          });
          var label = modalite.label;
          if (modalite.precision) {
            if (modalite.label.substr(-1, 1) === ":") {
            } else {
              label = label + " : ";
            }
          }
          return (
            <div key={modalite.value} className="modalite-container">
              <FormControlLabel
                className="modalite"
                name={this.props.name}
                value={modalite.id}
                control={
                  // Modifier pour passer par une checkbox maison
                  <Checkbox
                    color="secondary"
                    onChange={(event) => this.handleChange(event, modalite)}
                    checked={this.state.selected.indexOf(modalite.id) > -1 ? true : false}
                  />
                }
                color={styleStore.isTypeMIS ? "mis" : "primary"}
                label={
                  <div className="precisez-container">
                    <div
                      className={
                        this.props.errors &&
                        this.props.errors[modalite.id] &&
                        this.props.errors[modalite.id]["precisez"]
                          ? "label error"
                          : "label"
                      }
                    >
                      <Interweave content={label} />
                    </div>
                    {modalite.precision ? (
                      <input
                        label="précisez"
                        variant="outlined"
                        type="text"
                        className="precisez colored"
                        placeholder={t("entrez_precision")}
                        name="precision"
                        value={valuePrecision}
                        onChange={(event) => {
                          if (
                            questionStore.question.options.max &&
                            questionStore.reponse.length >= questionStore.question.options.max &&
                            !questionStore.reponse.includes(modalite.id)
                          ) {
                          } else {
                            this.handleChangePrecision(event, modalite);
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                }
              />
            </div>
          );
          //}
        });
      } else {
        blocModalites = (
          <p className="message-moda-vide">
            {t("non_concerne_question")}
            <br />
            {t("passer_question_suivante")}
          </p>
        );
      }
    } else {
      //Cas où l'on va avoir plusieurs colonnes
      var nbModalites = this.props.question.modalites.length;
      var division = nbModalites / nb_colonnes;
      var modalitesParColonnes = [];
      var reste = nbModalites % nb_colonnes;

      for (let index = 1; index <= nb_colonnes; index++) {
        //on arrondi en dessous
        modalitesParColonnes[index] = Math.floor(division);
        //on ajoute une modalité à la colonne si le reste est positif
        if (reste > 0) {
          modalitesParColonnes[index] = modalitesParColonnes[index] + 1;
          reste--;
        }
      }
      modalitesParColonnes = modalitesParColonnes.filter(function (el) {
        return el != null;
      });

      //Compteur incrémentés pour afficher les bons items dans les bonnes colonnes
      var compteurModalitesMin = 0;
      var compteurModalitesMax = 0;

      //taille automatique maximale des colonnes
      var widthCol = 100 / nb_colonnes;

      blocModalites = (
        <>
          {modalitesParColonnes.map((nb_moda, indexCol) => {
            //déplacement du compteur max
            compteurModalitesMax = compteurModalitesMax + nb_moda;
            const moda = (
              <div
                className="colonne-modalites"
                key={indexCol}
                style={{ maxWidth: `${widthCol}%`, paddingRight: "30px" }}
              >
                {this.props.question.modalites.length > 0 ? (
                  <>
                    {this.props.question.modalites.map((modalite, indexModa) => {
                      if (
                        indexModa + 1 > compteurModalitesMin &&
                        indexModa + 1 <= compteurModalitesMax
                      ) {
                        var valuePrecision = "";
                        this.state.autres.forEach((autre) => {
                          if (autre.modalite_id === modalite.id) {
                            valuePrecision = autre.precision;
                          }
                        });
                        var label = modalite.label;
                        if (modalite.precision) {
                          if (modalite.label.substr(-1, 1) === ":") {
                          } else {
                            label = label + " : ";
                          }
                        }
                        return (
                          <div key={modalite.value} className="modalite-container">
                            <FormControlLabel
                              className="modalite"
                              name={this.props.name}
                              value={modalite.id}
                              control={
                                <Checkbox
                                  color="secondary"
                                  onChange={(event) => this.handleChange(event, modalite)}
                                  checked={
                                    this.state.selected.indexOf(modalite.id) > -1 ? true : false
                                  }
                                />
                              }
                              color={styleStore.isTypeMIS ? "mis" : "primary"}
                              label={
                                <div className="precisez-container">
                                  <div
                                    className={
                                      this.props.errors &&
                                      this.props.errors[modalite.id] &&
                                      this.props.errors[modalite.id]["precisez"]
                                        ? "label error"
                                        : "label"
                                    }
                                  >
                                    <Interweave content={label} />
                                  </div>
                                  {modalite.precision ? (
                                    <input
                                      label="précisez"
                                      variant="outlined"
                                      type="text"
                                      className="precisez colored"
                                      placeholder={t("entrez_precision")}
                                      name="precision"
                                      value={valuePrecision}
                                      onChange={(event) => {
                                        if (
                                          questionStore.question.options.max &&
                                          questionStore.reponse.length >=
                                            questionStore.question.options.max &&
                                          !questionStore.reponse.includes(modalite.id)
                                        ) {
                                        } else {
                                          this.handleChangePrecision(event, modalite);
                                        }
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              }
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                ) : (
                  <p className="message-moda-vide">
                    {t("non_concerne_question")}
                    <br />
                    {t("passer_question_suivante")}
                  </p>
                )}
              </div>
            );
            //déplacement du compteur min
            compteurModalitesMin = compteurModalitesMin + nb_moda;
            return moda;
          })}
        </>
      );
    }

    return (
      <>
        <RadioGroup
          className={
            nb_colonnes > 1 && !isMobile ? "conteneur-modalites row" : "conteneur-modalites"
          }
        >
          {blocModalites}
        </RadioGroup>
      </>
    );
  }

  render() {
    return <div className="choix-multiple">{this.afficheQuestion()}</div>;
  }
}

const Mult = withTranslation()(HOCChoixMultiple);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function ChoixMultiple(props) {
  return (
    <Suspense fallback="loading">
      <Mult {...props} />
    </Suspense>
  );
}
