import * as React from "react";

function SvgSmiley1(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.408 124.408">
      <defs>
        <filter
          id="Ellipse_32"
          x="0"
          y="0"
          width="124.408"
          height="124.408"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Composant_131_3" data-name="Composant 131 – 3" transform="translate(5.18 3.59)">
        <g id="Groupe_1882" data-name="Groupe 1882">
          <rect
            id="Rectangle_424"
            data-name="Rectangle 424"
            width="117"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1881" data-name="Groupe 1881" transform="translate(3.82 2.41)">
            <g id="Groupe_1880" data-name="Groupe 1880">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_32)">
                <ellipse
                  id="Ellipse_32-2"
                  data-name="Ellipse 32"
                  cx="53.204"
                  cy="53.204"
                  rx="53.204"
                  ry="53.204"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1883" data-name="Groupe 1883" transform="translate(8.402 6.992)">
          <circle
            id="Ellipse_33"
            data-name="Ellipse 33"
            cx="48.622"
            cy="48.622"
            r="48.622"
            fill="#a60600"
          />
        </g>
        <g id="Groupe_1886" data-name="Groupe 1886" transform="translate(41.239 38.921)">
          <g id="Groupe_1884" data-name="Groupe 1884">
            <path
              id="Tracé_1856"
              data-name="Tracé 1856"
              d="M92.365,100.11h0A4.153,4.153,0,0,1,88.2,95.944V91.665A4.153,4.153,0,0,1,92.365,87.5h0a4.153,4.153,0,0,1,4.165,4.165v4.317A4.2,4.2,0,0,1,92.365,100.11Z"
              transform="translate(-88.2 -87.5)"
            />
          </g>
          <g id="Groupe_1885" data-name="Groupe 1885" transform="translate(22.606)">
            <path
              id="Tracé_1857"
              data-name="Tracé 1857"
              d="M152.065,100.11h0a4.153,4.153,0,0,1-4.165-4.165V91.665a4.153,4.153,0,0,1,4.165-4.165h0a4.153,4.153,0,0,1,4.165,4.165v4.317A4.2,4.2,0,0,1,152.065,100.11Z"
              transform="translate(-147.9 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1888" data-name="Groupe 1888" transform="translate(42.126 61.69)">
          <g id="Groupe_1887" data-name="Groupe 1887">
            <path
              id="Tracé_1858"
              data-name="Tracé 1858"
              d="M118,151.155a1.74,1.74,0,0,1-1.628-1.212,11.474,11.474,0,0,0-5.642-7.005,11.733,11.733,0,0,0-16.888,6.892,1.7,1.7,0,0,1-3.257-.985,15.175,15.175,0,0,1,29.081.151,1.714,1.714,0,0,1-1.174,2.121A2.33,2.33,0,0,1,118,151.155Z"
              transform="translate(-90.511 -138.085)"
            />
          </g>
        </g>
        <g id="Groupe_1889" data-name="Groupe 1889" transform="translate(62.707 34.89)">
          <path
            id="Tracé_1859"
            data-name="Tracé 1859"
            d="M140.975,85.663a1.9,1.9,0,0,1-.189-3.787,44.46,44.46,0,0,0,5.68-.909,37.825,37.825,0,0,0,5.263-1.477,1.881,1.881,0,1,1,1.325,3.522,41.646,41.646,0,0,1-5.794,1.628,52.483,52.483,0,0,1-6.1.947A.245.245,0,0,1,140.975,85.663Z"
            transform="translate(-139.07 -79.363)"
          />
        </g>
        <g id="Groupe_1890" data-name="Groupe 1890" transform="translate(35.397 34.974)">
          <path
            id="Tracé_1860"
            data-name="Tracé 1860"
            d="M91.668,85.787h-.189c-2.083-.227-4.127-.53-6.1-.947a41.649,41.649,0,0,1-5.794-1.628,1.881,1.881,0,0,1,1.325-3.522,37.825,37.825,0,0,0,5.263,1.477c1.818.379,3.787.682,5.68.909a1.858,1.858,0,0,1-.189,3.711Z"
            transform="translate(-78.363 -79.563)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley1;
