//REACT
import React, { Fragment, useEffect } from "react";
import { observer } from "mobx-react";
import questionStore from "../store-mobx/questionStore";
import questionnaireStore from "../store-mobx/questionnaireStore";
import { isMobile } from "react-device-detect";

import SvgEsurveyBlanc from "../svg/EsurveyBlanc";
import styleStore from "../store-mobx/styleStore";
import ProgressBar from "./ProgressBar";
import ProgressBarMISMobile from "./ProgressBarMISMobile";
import MISGroupBlanc from "../svg/MISGroupBlanc";

/**
 *
 * NAVBAR du module de réponse
 *
 * Peut être soit en mode :
 *
 * "test" -> On affiche le bouton recommencer + possibilité d'ouvrir le bandeau
 *
 * "repondant" -> On masque tous les boutons excepté le logo
 *
 */

const Navbar = observer(() => {
  useEffect(() => {
    if (isMobile) {
      questionnaireStore.bandeauIsOpen = false;
    }
  }, []); // le [] permet d'entrer dans le useEffect qu'au initial Render

  const afficheLogoThemeCustom = () => {
    const { theme_custom } = questionnaireStore.questionnaire.options;

    if (!theme_custom?.logo) {
      if (theme_custom?.logoEsurvey) {
        return (
          <SvgEsurveyBlanc
            width="100%"
            height="100%"
            style={{ maxWidth: "180px", maHeight: "30px" }}
          />
        );
        // return <img src="/e-survey-questionnaire.png" alt="made in surveys" />;
        // return (
        //   <SvgEsurvey
        //     width="100%"
        //     height="100%"
        //     style={{ maxWidth: "180px", maHeight: "30px" }}
        //   />
        // );
      } else {
        return;
      }
    } else {
      return (
        <img
          className="logo-header"
          src={`${process.env.REACT_APP_URL_WEB}/questionnaires/${questionnaireStore.questionnaire.id}/logo/${questionnaireStore.questionnaire.options.theme_custom.logo}`}
          alt="logo"
        />
      );
    }
  };

  //Est mis à jour à chaque chargement de question -> safe
  const source = questionnaireStore.repondant.source;

  var classNavbar = "navbar ";
  if (source === "test") {
    classNavbar = classNavbar + "test ";
  } else {
    classNavbar = classNavbar + "repondant ";
  }

  if (
    questionnaireStore.questionnaire.options &&
    questionnaireStore.questionnaire.options.theme &&
    questionnaireStore.questionnaire.options.theme === "MIS"
  ) {
    classNavbar = classNavbar + "MIS ";
  }
  if (styleStore.themeLoaded) {
    return (
      <Fragment>
        <header className={classNavbar}>
          {source === "test" &&
          (!questionnaireStore.questionnaire.options.hasOwnProperty("afficher_volet_test") ||
            questionnaireStore.questionnaire.options.afficher_volet_test) ? (
            <div
              className={
                questionnaireStore.bandeauIsOpen ? "hamburger-menu open" : "hamburger-menu"
              }
              onClick={() => {
                questionnaireStore.bandeauIsOpen = !questionnaireStore.bandeauIsOpen;
              }}
            >
              <span className="hamburger-line top-line close-hidden"></span>
              <span className="hamburger-line middle-line close-up"></span>
              <span className="hamburger-line middle-line close-down"></span>
              <span className="hamburger-line bottom-line close-hidden"></span>
            </div>
          ) : (
            ""
          )}

          <div className="logo">
            {questionnaireStore.questionnaire.options &&
            questionnaireStore.questionnaire.options.theme &&
            questionnaireStore.questionnaire.options.theme === "MIS" ? (
              isMobile ? (
                <img src="/logo-mis-questionnaire.png" alt="made in surveys" />
              ) : (
                <MISGroupBlanc />
              )
            ) : questionnaireStore.questionnaire.options.theme === 10 ? (
              afficheLogoThemeCustom()
            ) : (
              <>
                <img src="/e-survey-questionnaire.png" alt="made in surveys" />
                {/* <SvgEsurvey width="100%" height="100%" /> */}
              </>
            )}
          </div>

          {questionnaireStore.questionnaire.options &&
          questionnaireStore.questionnaire.options.theme &&
          questionnaireStore.questionnaire.options.theme === "MIS" &&
          questionnaireStore.questionnaire.nom &&
          !isMobile ? (
            <div className="titre_mis">{questionnaireStore.questionnaire.nom}</div>
          ) : (
            ""
          )}

          {/* {source === "test" ? (
            <div
              className="vider-reponses"
              onClick={() => {
                questionnaireStore.isClearing = true;
                questionStore.viderReponses(questionnaireStore.token_repondant).then((retour) => {
                  questionnaireStore.isClearing = false;
                });
              }}
            ></div>
          ) : (
            ""
          )} */}

          {questionnaireStore.questionnaire.options.afficher_titre &&
          questionnaireStore.questionnaire.options &&
          questionnaireStore.questionnaire.options.theme &&
          questionnaireStore.questionnaire.options.theme !== "MIS" ? (
            <h1
              className="questionnaire-name"
              title={
                questionnaireStore.questionnaire.options.titre_custom
                  ? questionnaireStore.questionnaire.options.titre_custom
                  : questionnaireStore.questionnaire.nom
              }
            >
              {questionnaireStore.questionnaire.options.titre_custom
                ? questionnaireStore.questionnaire.options.titre_custom
                : questionnaireStore.questionnaire.nom}
            </h1>
          ) : (
            ""
          )}

          {questionnaireStore.questionnaire.options.afficher_progression &&
          questionnaireStore.questionnaire.options &&
          questionnaireStore.questionnaire.options.theme &&
          questionnaireStore.questionnaire.options.theme !== "MIS" ? (
            <ProgressBar progression={questionStore.progression} className="bar-container" />
          ) : (
            <div></div>
          )}

          {questionnaireStore.questionnaire.options.afficher_progression &&
          questionnaireStore.questionnaire.options &&
          questionnaireStore.questionnaire.options.theme &&
          questionnaireStore.questionnaire.options.theme === "MIS" &&
          isMobile ? (
            <ProgressBarMISMobile progression={questionStore.progression} />
          ) : (
            ""
          )}
        </header>
      </Fragment>
    );
  } else {
    return <></>;
  }
});

export default Navbar;
