//REACT
import i18next from "i18next";
import { Interweave } from "interweave";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import questionnaireStore from "../store-mobx/questionnaireStore";

const DisplayFullscreenError = observer((props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState("");
  const [heure, setHeure] = useState("");

  console.log(i18next.language);

  const sites = {
    //FR
    1: { lien: "https://www.pipeul.com/index.php", nom: "Pipeul" },
    3: {
      lien: "https://www.reunionsdeconsommateurs.com/",
      nom: "Réunions de consommateurs",
    },
    4: {
      lien: "https://www.devenirclientmystere.com/",
      nom: "Devenir Client Mystère",
    },
    5: {
      lien: "https://www.testerdesproduits.fr/",
      nom: "Tester des produits",
    },
    9: { lien: "https://www.avispanel.com/", nom: "AvisPanel" },
    40: {
      lien: "https://www.avisdartisans.com/accueil",
      nom: "Avis d'artisans",
    },
    //UK
    11: {
      lien: "https://www.surveyfriends.co.uk/surveyfriends/",
      nom: "Surveyfriends",
    },
    13: { lien: "https://www.paidfocusgroup.co.uk/", nom: "PaidFocusGroup" },
    15: {
      lien: "https://www.paidproducttesting.co.uk/",
      nom: "PaidProductTesting",
    },
    19: { lien: "https://www.panelopinion.co.uk/", nom: "PanelOpinion" },
    41: { lien: "https://www.yoopinion.com/", nom: "Yoopinion" },
    //panel europa
    29: { lien: "https://www.paneleuropa.de/", nom: "Paneleuropa.de" },
    39: { lien: "https://www.paneleuropa.es/", nom: "Paneleuropa.es" },
    42: { lien: "https://www.paneleuropa.eu/", nom: "Paneleuropa.eu" },
    43: { lien: "https://www.paneleuropa.it/", nom: "Paneleuropa.it" },
    45: { lien: "https://www.paneleuropa.nl/", nom: "Paneleuropa.nl" },
    46: { lien: "https://www.paneleuropa.be/", nom: "Paneleuropa.be" },
    //inter
    6: {
      lien: "https://www.trynewperfumes.com/fr/home",
      nom: "Try New Perfumes",
    },
    47: {
      lien: "https://www.free-cosmetic-testing.com/",
      nom: "Free Cosmetic Testing",
    },

    //IT
    55: {
      lien: "https://www.testailprodotto.it/",
      nom: "Testa il prodotto",
    },
  };

  const errorCorresp = {
    questionnaire_non_disponible: t("questionnaire_non_disponible"),
    questionnaire_ouvert_prochainement: t("questionnaire_ouvert_prochainement", {
      date: date,
      heure: heure,
    }),
    date_fin_depassee: t("date_fin_depassee", { date: date, heure: heure }),
    questionnaire_ferme: t("validation.questionnaire_ferme"),
    erreur_authentification: t("erreur_authentification"),
  };

  useEffect(() => {
    if (props.erreur.date_debut) {
      let tempDate = props.erreur.date_debut.split(" ")[0];
      let tempHeure = props.erreur.date_debut.split(" ")[1].substr(0, 5);

      setDate(tempDate);
      setHeure(tempHeure);
    } else if (props.erreur.date_fin) {
      let tempDate = props.erreur.date_fin.split(" ")[0];
      let tempHeure = props.erreur.date_fin.split(" ")[1].substr(0, 5);

      setDate(tempDate);
      setHeure(tempHeure);
    }
  }, []);

  console.log(toJS(props), toJS(questionnaireStore.repondant));

  return (
    <div style={{ width: "100%", height: "90vh" }} className="fullscreen-error">
      <h1>{t("oups")}</h1>
      <h2>
        <Interweave content={errorCorresp[props.erreur.code_erreur]} />
      </h2>

      {props.erreur.provenance &&
      sites[props.erreur.provenance] &&
      props.erreur.provenance < 100 ? (
        <h3>
          <a href={sites[props.erreur.provenance]["lien"]}>
            {t("retour")}
            {sites[props.erreur.provenance]["nom"]}
          </a>
        </h3>
      ) : props.erreur.provenance && props.erreur.provenance === "externe" ? (
        <h3>
          <a href={"https://www.madeinstudios.com/"}>{t("retour")} Made In Studios </a>
          {}
        </h3>
      ) : (
        " "
      )}
    </div>
  );
});

export default DisplayFullscreenError;
