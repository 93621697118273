import { motion } from "framer-motion";
import { Interweave } from "interweave";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import questionStore from "../../store-mobx/questionStore";
import styleStore from "../../store-mobx/styleStore";
import { scrollToTop } from "./HelpersQuestion";

const MatricielleUniqueMobile = observer(({ lignes, colonnes }) => {
  const { t } = useTranslation();

  const [currentLigne, setCurrentLigne] = useState(0);
  const [reponses, setReponses] = useState([]);
  const [direction, setDirection] = useState(0);
  const [nbClickSuivant, setNbClickSuivant] = useState(0);

  const choix = useRef(null);
  const containerChoix = useRef(null);
  const nbLignes = lignes.length - 1;

  //Mets à jour la taille du container choix par référence
  useLayoutEffect(() => {
    containerChoix.current.style.height = choix.current.offsetHeight + "px";
    scrollToTop();
  }, [currentLigne]);

  //Cache ou montre le bouton suite
  useEffect(() => {
    //On compare à length-1 car on ne peut pas cliquer sur la dernière ligne
    if (nbClickSuivant >= nbLignes) {
      questionStore.submitHidden = false;
    } else {
      questionStore.submitHidden = true;
    }

    //Remet le bouton submit en place
    return () => {
      // questionStore.submitHidden = false;
    };
  });

  const handleSuivant = (event) => {
    console.log(toJS(reponses));

    if (questionStore.question.options.reponse_obligatoire === false) {
      if (currentLigne + 1 <= nbLignes) {
        setCurrentLigne(currentLigne + 1);
        setDirection(1);
        setNbClickSuivant(nbClickSuivant + 1);
      }
    } else {
      if (
        reponses.filter((rep) => {
          return rep.ligne_id === lignes[currentLigne].id && rep.colonnes_id !== "";
        }).length > 0
      ) {
        if (currentLigne + 1 <= nbLignes) {
          setCurrentLigne(currentLigne + 1);
          setDirection(1);
          setNbClickSuivant(nbClickSuivant + 1);
        }
      }
    }
  };

  //pas utilisé pour l'instant
  // const handlePrecedent = (event) => {
  //   if (currentLigne > 0) {
  //     setCurrentLigne(currentLigne - 1);
  //     setDirection(-1);
  //   }
  // };

  const handleClick = (ligne, colonne) => {
    var newReponses = [...reponses];

    var reponseClick = { ligne_id: ligne.id, colonne_id: colonne.id };
    var indexObjet = 0;
    var included = false;

    //REGARDE SI LA REPONSE EST DEJA PRESENTE
    newReponses.forEach((rep, index) => {
      if (rep.ligne_id === ligne.id) {
        included = true;
        indexObjet = index;
      }
    });

    //AJOUT OU SUPPRESSION DU TABLEAU
    if (included) {
      newReponses.splice(indexObjet, 1);
      newReponses.push(reponseClick);
    } else {
      newReponses.push(reponseClick);
    }

    //MAJ
    setReponses(newReponses);
    //handleSuivant();
    questionStore.reponse = newReponses;

    console.log(toJS(newReponses));
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction === 0 ? 0 : direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };
  // const swipeConfidenceThreshold = 10000;
  // const swipePower = (offset, velocity) => {
  //   return Math.abs(offset) * velocity;
  // };

  return (
    <div className="matricielle-mobile">
      <div className="container-choix" ref={containerChoix}>
        <motion.div
          ref={choix}
          key={currentLigne}
          className="choix "
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 200 },
            opacity: { duration: 0.1 },
            delay: 0,
          }}
          // drag="x"
          // dragConstraints={{ left: 0, right: 0 }}
          // dragElastic={1}
          // onDragEnd={(e, { offset, velocity }) => {
          //   const swipe = swipePower(offset.x, velocity.x);

          //   if (swipe < -swipeConfidenceThreshold) {
          //     handleSuivant();
          //   } else if (swipe > swipeConfidenceThreshold) {
          //     handlePrecedent();
          //   }
          // }}
        >
          <div className="current-ligne">
            <Interweave content={lignes[currentLigne].label} />
          </div>

          <div>
            {colonnes.map((colonne, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClick(lignes[currentLigne], colonne)}
                  className="colonne-container"
                >
                  <input
                    type="radio"
                    name={"reponse_" + lignes[currentLigne].id}
                    className="colonne-input"
                    onChange={() => ""}
                    checked={
                      reponses.filter(
                        (rep) =>
                          rep.ligne_id === lignes[currentLigne].id && rep.colonne_id === colonne.id
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                  <span className="colonne-text">
                    <Interweave content={colonne.label} />
                  </span>
                </div>
              );
            })}
          </div>
        </motion.div>
      </div>

      <div className="button-container">
        {/* <button type="button" onClick={handlePrecedent}>
          {"< Item précédent"}
        </button>
        <button type="button" onClick={handleSuivant}>
          {"Item suivant >"}
        </button> */}

        {currentLigne + 1 <= nbLignes ? (
          <button
            className={`button-primary button-next ${styleStore.isTypeMIS ? "mis" : ""}`}
            type="button"
            onClick={(e) => {
              handleSuivant(e);
            }}
            disabled={
              questionStore.question.options.reponse_obligatoire === true &&
              reponses.filter((rep) => {
                return rep.ligne_id === lignes[currentLigne].id && rep.colonnes_id !== "";
              }).length === 0
            }
          >
            {t("matricielle.item_suivant")}
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="ronds-container">
        {lignes.map((ligne, indexLigne) => {
          var etat = "";
          var ligneComparaison = currentLigne + 1;

          //check pour voir si rond déjà dans réponses
          var repPresente = false;
          reponses.forEach((rep) => {
            if (rep.ligne_id === ligne.id) {
              repPresente = true;
            }
          });

          if (parseInt(indexLigne + 1) < ligneComparaison) {
            etat = "filled";
          } else if (parseInt(indexLigne + 1) === ligneComparaison) {
            etat = "current";
          } else if (parseInt(indexLigne + 1) > ligneComparaison) {
            if (repPresente) {
              etat = "disabled-present";
            } else {
              etat = "disabled";
            }
          }
          return (
            <motion.div
              key={ligne.value}
              className={"rond-ligne " + etat}
              onClick={() => {
                if (etat !== "disabled") {
                  if (indexLigne < currentLigne) {
                    setDirection(-1);
                  } else {
                    setDirection(1);
                  }
                  setCurrentLigne(indexLigne);
                }
              }}
              layoutTransition
            ></motion.div>
          );
        })}
      </div>
    </div>
  );
});

export default MatricielleUniqueMobile;
