import * as React from "react";

function SvgSmiley8(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.927 122.927">
      <defs>
        <filter
          id="Ellipse_46"
          x="0"
          y="0"
          width="121.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-08" transform="translate(4.695 3.175)">
        <g id="Groupe_1945" data-name="Groupe 1945">
          <rect
            id="Rectangle_431"
            data-name="Rectangle 431"
            width="115"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1944" data-name="Groupe 1944" transform="translate(4.305 2.825)">
            <g id="Groupe_1943" data-name="Groupe 1943">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_46)">
                <ellipse
                  id="Ellipse_46-2"
                  data-name="Ellipse 46"
                  cx="51.964"
                  cy="52.464"
                  rx="51.964"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1946" data-name="Groupe 1946" transform="translate(9.676 8.197)">
          <ellipse
            id="Ellipse_47"
            data-name="Ellipse 47"
            cx="46.592"
            cy="47.092"
            rx="46.592"
            ry="47.092"
            fill="#c7d954"
          />
        </g>
        <g id="Groupe_1949" data-name="Groupe 1949" transform="translate(38.185 38.094)">
          <g id="Groupe_1947" data-name="Groupe 1947">
            <path
              id="Tracé_1879"
              data-name="Tracé 1879"
              d="M92.683,102.284h0A4.869,4.869,0,0,1,87.8,97.4V92.383A4.869,4.869,0,0,1,92.683,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,92.683,102.284Z"
              transform="translate(-87.8 -87.5)"
            />
          </g>
          <g id="Groupe_1948" data-name="Groupe 1948" transform="translate(26.504)">
            <path
              id="Tracé_1880"
              data-name="Tracé 1880"
              d="M152.383,102.284h0A4.869,4.869,0,0,1,147.5,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.884,4.883v5.061A4.86,4.86,0,0,1,152.383,102.284Z"
              transform="translate(-147.5 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1951" data-name="Groupe 1951" transform="translate(55.229 69.262)">
          <g id="Groupe_1950" data-name="Groupe 1950">
            <path
              id="Tracé_1881"
              data-name="Tracé 1881"
              d="M128.522,168.443a1.964,1.964,0,0,1-1.953-1.731,2,2,0,0,1,1.687-2.264,21.426,21.426,0,0,0,11.854-5.549,2.009,2.009,0,1,1,2.8,2.886,25.49,25.49,0,0,1-14.073,6.659A.725.725,0,0,0,128.522,168.443Z"
              transform="translate(-126.544 -158.337)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley8;
