//REACT
import React, { Component, Fragment } from "react";
import questionStore from "../../store-mobx/questionStore";
import { isMobile } from "react-device-detect";
import MatricielleMultipleMobile from "./MatricielleMultipleMobile";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

class MatricielleMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reponses: [],
      currentHover: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleClick(ligne, colonne) {
    var newReponses = this.state.reponses;

    var reponseClick = { ligne_id: ligne.id, colonnes_ids: [colonne.id] };
    var included = false;
    var isAucun = false;
    var aucunValue = [];

    this.props.colonnes.forEach((co, index) => {
      if (co.aucun) {
        isAucun = true;
        aucunValue.push(co.id);
      }
    });

    var ligneReponse = {};
    //REGARDE SI LA REPONSE EST DEJA PRESENTE
    newReponses.forEach((rep, index) => {
      if (rep.ligne_id === ligne.id) {
        included = true;
        ligneReponse = rep;
      }
    });

    //AJOUT OU SUPPRESSION DU TABLEAU ET GESTION DES VALEURS COCHEES
    if (included) {
      var index = ligneReponse.colonnes_ids.indexOf(colonne.id);
      if (index > -1) {
        ligneReponse.colonnes_ids.splice(index, 1);
      } else {
        if (
          questionStore.question.options.max &&
          ligneReponse.colonnes_ids.length < questionStore.question.options.max
        ) {
          ligneReponse.colonnes_ids.push(colonne.id);
        } else if (!questionStore.question.options.max) {
          ligneReponse.colonnes_ids.push(colonne.id);
        }
      }
    } else {
      newReponses.push(reponseClick);
    }

    //SI ON A UNE COLONNE EN AUCUN
    if (isAucun) {
      if (colonne.aucun === true) {
        newReponses.forEach((rep, index) => {
          if (rep.ligne_id === ligne.id) {
            rep.colonnes_ids = [colonne.id];
          }
        });
      } else {
        newReponses.forEach((rep, index) => {
          if (rep.ligne_id === ligne.id) {
            var indexAucun = "";
            aucunValue.forEach((aucun) => {
              indexAucun = rep.colonnes_ids.indexOf(aucun);
              if (indexAucun > -1) {
                rep.colonnes_ids.splice(indexAucun, 1);
              }
            });
          }
        });
      }
    }

    newReponses = newReponses.filter((rep) => rep.colonnes_ids.length > 0);

    //MAJ
    this.setState({
      reponses: newReponses,
    });
    questionStore.reponse = newReponses;
    //this.props.setFieldValue(this.props.name, newReponses);
  }

  //check si la ligne fait partie des réponses
  verifLigne = (ligne) => {
    var nbrep = 0;
    this.state.reponses.forEach((rep) => {
      if (ligne.id === rep.ligne_id && rep.colonnes_ids.length > 0) {
        nbrep++;
      }
    });
    if (nbrep > 0) {
      return true;
    } else {
      return false;
    }
  };

  handleHover = (ligneValue, colonneValue, state) => {
    if (state === "show") {
      this.setState({
        currentHover: { ligneValue: ligneValue, colonneValue: colonneValue },
      });
    } else {
      this.setState({
        currentHover: {},
      });
    }
  };

  errorLigne = () => {};

  //Affichage de la question
  afficheQuestion() {
    const { lignes, colonnes } = this.props.question;
    return (
      <Fragment>
        {isMobile ? (
          <MatricielleMultipleMobile
            lignes={lignes}
            colonnes={colonnes}
            errors={this.props.errors}
          />
        ) : (
          <div className="table-container">
            <table className="matricielle multiple">
              <thead>
                <tr>
                  <th className="vide"></th>
                  {colonnes.map((col, index) => {
                    return (
                      <th
                        key={index}
                        className={
                          this.state.currentHover.colonneValue &&
                          index + 1 === this.state.currentHover.colonneValue
                            ? "hover-light"
                            : ""
                        }
                      >
                        <Interweave content={col.label} />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {lignes.map((ligne, indexLigne) => {
                  var classLigne = "ligne-label ";

                  if (
                    (Object.values(this.props.errors).length > 0 &&
                      this.verifLigne(ligne) === false) ||
                    (this.props.errors && this.props.errors[ligne.id])
                  ) {
                    classLigne = "ligne-label error";
                  }
                  return (
                    <tr key={indexLigne}>
                      <td
                        className={
                          this.state.currentHover.ligneValue &&
                          indexLigne + 1 === this.state.currentHover.ligneValue
                            ? classLigne + " hover-light"
                            : classLigne
                        }
                      >
                        <Interweave content={ligne.label} />
                      </td>
                      {colonnes.map((col, indexCol) => {
                        return (
                          <td
                            key={indexCol}
                            className={
                              (this.state.currentHover.ligneValue &&
                                indexLigne + 1 === this.state.currentHover.ligneValue &&
                                this.state.currentHover.colonneValue &&
                                indexCol + 1 <= this.state.currentHover.colonneValue) ||
                              (this.state.currentHover.colonneValue &&
                                indexCol + 1 === this.state.currentHover.colonneValue &&
                                this.state.currentHover.ligneValue &&
                                indexLigne + 1 <= this.state.currentHover.ligneValue)
                                ? "choix-reponse hover-light"
                                : "choix-reponse"
                            }
                            onPointerEnter={() =>
                              this.handleHover(indexLigne + 1, indexCol + 1, "show")
                            }
                            onPointerLeave={() =>
                              this.handleHover(indexLigne + 1, indexCol + 1, "hide")
                            }
                            onClick={() => this.handleClick(ligne, col)}
                          >
                            <input
                              type="checkbox"
                              name={"reponse_" + ligne.id}
                              data-testid={"reponse_" + ligne.id}
                              onChange={() => ""}
                              checked={
                                this.state.reponses.filter(
                                  (reponse) =>
                                    reponse.ligne_id === ligne.id &&
                                    reponse.colonnes_ids.filter((val) => val === col.id).length > 0
                                ).length > 0
                                  ? true
                                  : false
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

export default MatricielleMultiple;
