import CssBaseline from "@mui/material/CssBaseline";
import { observer } from "mobx-react";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/NavBar";
import Error404 from "./containers/Error404";
import ReponseQuestionnaire from "./containers/ReponseQuestionnaire";
import SwitchQuestionnaire from "./containers/SwitchQuestionnaire";
import history from "./history";
import ReactGA from "react-ga";
import questionnaireStore from "./store-mobx/questionnaireStore";
import QuestionnaireTermine from "./containers/QuestionnaireTermine";
import styleStore from "./store-mobx/styleStore";
import QuestionnaireFerme from "./containers/QuestionnaireFerme";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Navigator = observer(() => {
  const { t } = useTranslation();

  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  if (styleStore.isTypeMIS) {
    var fav = document.getElementById("favicon");
    fav.href = "../../faviconMIS.ico";
  }

  var globalClass = "";
  if (questionnaireStore.questionnaire.options && questionnaireStore.questionnaire.options.theme) {
    globalClass = "theme" + questionnaireStore.questionnaire.options.theme;
    document.body.className = globalClass;
  }

  return (
    <BrowserRouter>
      {styleStore.isTypeMIS && (
        <>
          <link rel="stylesheet" type="text/css" href={"/mis.css"} />
          <Helmet>
            <meta charSet="utf-8" />
            <title>{t("title_mis")}</title>
          </Helmet>
        </>
      )}

      <CssBaseline />
      <Navbar />
      <Routes>
        {/*utilisation de component au lieu de render pour les params */}

        <Route
          exact
          //route pour les répondants externes
          path="/switch/:type/:clef/params"
          Component={SwitchQuestionnaire}
        />
        <Route
          exact
          //route pour les répondants classiques et tests + possibilité aperçu question
          path="/switch/:type/:clef/:idquestion?"
          Component={SwitchQuestionnaire}
        />

        <Route
          //route pour répondre au questionnaire directement
          path="/questionnaires/:id/:token?"
          Component={ReponseQuestionnaire}
        />

        <Route path="/questionnaire-ferme" Component={QuestionnaireFerme} />
        <Route path="/questionnaire-termine" Component={QuestionnaireTermine} />
        <Route path="" Component={Error404} />
      </Routes>
    </BrowserRouter>
  );
});

export default Navigator;
