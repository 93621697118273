import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import questionStore from "../../store-mobx/questionStore";
import Radio from "./Radio";

const Civilite = (props) => {
  const { t } = useTranslation();
  const [valueChecked, setValueChecked] = useState();

  const handleChange = (value) => {
    const reponseDonnee = { id: props.champ.id, valeur: value };
    setValueChecked(value);
    var indexReponsePresente = questionStore.reponse["champs"].findIndex(
      (x) => x.id === reponseDonnee.id
    );
    if (questionStore.reponse["champs"] && indexReponsePresente !== -1) {
      questionStore.reponse["champs"][indexReponsePresente] = reponseDonnee;
    } else {
      questionStore.reponse["champs"].push(reponseDonnee);
    }
  };

  return (
    <div role="group" aria-labelledby="my-radio-group" className={props.className + " civilite"}>
      <div className="radios">
        <div className="item">
          <Radio onClick={() => handleChange("1")} clef={"1"} isChecked={valueChecked === "1"} />
          <label onClick={() => handleChange("1")}>{t("inputs_label.homme")} </label>
        </div>
        <div className="item">
          <Radio onClick={() => handleChange("2")} clef={"2"} isChecked={valueChecked === "2"} />
          <label onClick={() => handleChange("2")}>{t("inputs_label.femme")}</label>
        </div>
        <div className="item">
          <Radio onClick={() => handleChange("3")} clef={"3"} isChecked={valueChecked === "3"} />

          <label onClick={() => handleChange("3")}>{t("inputs_label.autre")}</label>
        </div>
      </div>
      <div className="errors">
        {props.errorsDisplay.map((error, index) => {
          return (
            <div key={index} className="error-message">
              {error}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Civilite;
