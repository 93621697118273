import React, { Fragment, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import questionnaireStore from "../store-mobx/questionnaireStore";
import { observer } from "mobx-react";
import questionStore from "../store-mobx/questionStore";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { getNumeroQuestion } from "./questions/HelpersQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faSync } from "@fortawesome/free-solid-svg-icons";
import { Interweave } from "interweave";

/**
 *
 * Component qui affiche la liste des questions
 *
 * Liste des questions rafraichie à chaque rechargement
 *
 *
 */
const Bandeau = observer((props) => {
  const { t } = useTranslation();

  var titleTest = t("bandeau.liste_questions");

  if (
    questionnaireStore.questionnaire.options?.theme === 10 &&
    questionnaireStore.questionnaire.options?.theme_custom?.titleTest
  ) {
    titleTest = questionnaireStore.questionnaire.options.theme_custom.titleTest;
  }

  //HOOKS
  const [isLoaded, setIsLoaded] = useState(questionnaireStore.bandeauIsLoaded);
  const [copied, setIsCopied] = useState(false);

  const handleCopy = () => {
    //Nouvelle méthode
    const cb = navigator.clipboard;
    var clef = window.localStorage.getItem("clef_partage");
    const newLink = process.env.REACT_APP_URL_MODULE_REPONSE + "switch/test/" + clef;
    cb.writeText(newLink);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, [3000]);
  };

  //Agit comme un componentDidMount
  useEffect(() => {
    if (!questionnaireStore.listeSections) {
      questionnaireStore.listeQuestion().then((retour) => {
        questionnaireStore.listeSections = retour.sections;
        setIsLoaded(true);
        questionnaireStore.bandeauIsLoaded = true;
      });
    } else {
      if (questionnaireStore.bandeauIsOpen) {
        var questElement = document.getElementById(questionStore.question.id);
        if (questElement) {
          // var questionTop = document.getElementById(questionStore.question.id).offsetTop;
          setTimeout(() => {
            // document
            //   .getElementsByClassName("bandeau")[0]
            //   .scrollTo({ top: questionTop, behavior: "smooth" });

            questElement.scrollIntoView({
              behavior: "smooth",
              block: "center", //can take start, end
            });
          }, 100);
        }
      }
    }
  }, []);

  //FRAMER MOTION
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const afficherIntro = (section) => {
    if (section.type === "intro" && questionnaireStore?.questionnaire?.options?.masquer_intro) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <AnimatePresence>
        {isLoaded && (
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            className="bandeau"
          >
            {questionnaireStore.repondant.source === "test" && (
              <div className="actions-container">
                <button
                  className="button-vider"
                  onClick={() => {
                    questionnaireStore.isClearing = true;
                    questionStore
                      .viderReponses(questionnaireStore.token_repondant)
                      .then((retour) => {
                        questionnaireStore.isClearing = false;
                      });
                  }}
                >
                  {t("recommencer")}
                  <FontAwesomeIcon
                    icon={faSync}
                    className={
                      questionnaireStore.isClearing
                        ? "icon-recommencer fa-spin"
                        : "icon-recommencer"
                    }
                  />
                </button>

                <button
                  className="button-share"
                  onClick={() => {
                    handleCopy();
                  }}
                >
                  {copied ? (
                    <>
                      {t("copie")}
                      <FontAwesomeIcon icon={faCheck} className={"icon-check"} />
                    </>
                  ) : (
                    <>
                      {" "}
                      {t("copier_lien_test")}
                      <FontAwesomeIcon icon={faCopy} className={"icon-partage"} />
                    </>
                  )}
                </button>
              </div>
            )}

            <h1>{titleTest}</h1>

            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="listeSections"
            >
              {/* On a ici 3 boucles pour afficher Intro puis Questions puis Conclusion */}

              {questionnaireStore.listeSections.map((section) => {
                if (afficherIntro(section)) {
                  return (
                    <motion.div key={section.id} variants={item} className="section-container">
                      <motion.div variants={item} className="section">
                        <span className="nom">{section.nom.toUpperCase()}</span>
                      </motion.div>
                      {section.questions.map((question) => {
                        return (
                          <motion.div
                            key={question.id}
                            id={question.id}
                            variants={item}
                            className={
                              questionStore.question.id === question.id
                                ? "question active"
                                : "question"
                            }
                            onClick={() => {
                              questionStore.getQuestionBandeau(question.id, props.tokenRepondant);
                              if (isMobile) {
                                questionnaireStore.bandeauIsOpen = false;
                              }
                            }}
                          >
                            <span className="numero_question">
                              {getNumeroQuestion(question, t)}
                            </span>

                            {question.intitule ? (
                              <>
                                {" - "}
                                <Interweave content={question.intitule?.slice(0, 190)} />
                                {question.intitule?.length > 190 && "..."}
                              </>
                            ) : (
                              ""
                            )}
                          </motion.div>
                        );
                      })}
                    </motion.div>
                  );
                } else {
                  return null;
                }
              })}
            </motion.div>
          </motion.div>
        )}
        {!isLoaded && (
          <motion.div
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            className="bandeau"
          >
            <h1>{titleTest}</h1>
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="listeSections"
            >
              <div className="loader">
                <CircularProgress />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
});

export default Bandeau;
