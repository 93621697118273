import { motion } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import questionStore from "../store-mobx/questionStore";

const ProgressBar = observer((props) => {
  const arrondi = (x) => {
    return Number.parseFloat(x).toFixed(0);
  };

  return (
    <motion.div className={props.className}>
      <motion.div
        className="bar"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, width: `${questionStore.progression}%` }}
        exit={{ opacity: 0 }}
      >
        <motion.div className="percentage" layout>
          {questionStore.progression >= 5 ? arrondi(questionStore.progression) + "%" : ""}
        </motion.div>
      </motion.div>
    </motion.div>
  );
});

export default ProgressBar;
