//REACT
import React, { Fragment, useEffect, useState } from "react";

import questionStore from "../../store-mobx/questionStore";
import questionnaireStore from "../../store-mobx/questionnaireStore";
import { observer } from "mobx-react";
import { Interweave } from "interweave";
import md5 from "md5";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getNomSite, scrollToTop } from "./HelpersQuestion";
import { transformMedia } from "./HelpersMedia";
import { useSocket } from "../../sockets/Socket";

const ConclusionBon = observer((props) => {
  const { t } = useTranslation();

  const socket = useSocket();

  const [instanteRedirectrion, setInstanteRedirection] = useState(false);

  /**
   * A gerer cas liens_retour n'<exi></exi>ste pas
   */

  // ConclusionBon -> valides

  useEffect(() => {
    scrollToTop();
    eventSockets();

    let liens_retour = questionnaireStore.questionnaire.options.liens_retour;
    const params_externe = JSON.parse(localStorage.getItem("params_externe"));

    // TYPE MIS 2 - 3
    if (
      questionnaireStore.repondant.source === "MIS" &&
      (questionnaireStore.questionnaire.type_etude_mis === "2" ||
        questionnaireStore.questionnaire.type_etude_mis === "3")
    ) {
      window.location =
        "https://surveys.madeinsurveys.com/return_screener_dys.php?q=" +
        questionnaireStore.questionnaire.reference +
        "&t=" +
        questionnaireStore.repondant.token +
        "&type=" +
        md5("MIM59LILLE" + questionnaireStore.questionnaire.type_etude_mis);
    }

    // TYPE 10 MIS
    else if (
      questionnaireStore.repondant.source === "MIS" &&
      (questionnaireStore.questionnaire.type_etude_mis.includes("10") ||
        questionnaireStore.questionnaire.type_etude_mis === "creatests" ||
        questionnaireStore.questionnaire.type_etude_mis === "panelabs")
    ) {
      setTimeout(() => {
        window.location =
          "https://surveys.madeinsurveys.com/return_dys.php?q=" +
          questionnaireStore.questionnaire.reference +
          "&t=" +
          questionnaireStore.repondant.token;
      }, 3000);
    }

    // Redirection vers lien de retour Panel de répondants
    else if (params_externe?.p === "MIS") {
      setInstanteRedirection(true);
      window.location = `https://surveys.madeinsurveys.com/${questionnaireStore.questionnaire.numero_questionnaire}/return.php?mpcode=${params_externe.mpcode}`;
    }

    // parametres url -> redirection liens de partage
    else if (liens_retour) {
      let infosRedirection = liens_retour["lien_partage"]["valides"];
      if (infosRedirection.redirection) {
        let lien_texte = infosRedirection.lien_url;
        const queryString = require("query-string");
        const parametres = queryString.parseUrl(lien_texte);
        if (params_externe?.mpcode) {
          parametres.query.mpcode = params_externe.mpcode;
        }

        if (infosRedirection.temps_redirection === 0) {
          setInstanteRedirection(true);
        }

        let url = queryString.stringifyUrl({ url: lien_texte, query: parametres.query });

        setTimeout(() => {
          console.log("redirection ");
          console.timeEnd("red");
          window.location = url;
        }, infosRedirection.temps_redirection * 1000);
      }
    }
  }, []);

  const eventSockets = () => {
    if (questionnaireStore.repondant?.source !== "test") {
      socket.emit("repondant_conclusion", {
        questionnaire_id: questionnaireStore.questionnaire.id,
        token_repondant: questionnaireStore.token_repondant,
        profil_valide: true,
      });
    }
  };

  //Affichage de la question
  const afficheQuestion = () => {
    if (questionStore.redirectToMIS === true || instanteRedirectrion) {
      return (
        <div
          className="ck-content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div className="ck-content">
          <Interweave content={props.question.editor} transform={transformMedia} />
          {questionnaireStore.repondant.source === "MIS" &&
          (questionnaireStore.questionnaire.type_etude_mis.includes("10") ||
            questionnaireStore.questionnaire.type_etude_mis === "creatests" ||
            questionnaireStore.questionnaire.type_etude_mis === "panelabs") &&
          getNomSite(questionnaireStore.repondant.infos_paneliste.provenance) !== "" ? (
            <>
              <br />
              <br />
              <a
                className="lien_retour"
                href={
                  "https://surveys.madeinsurveys.com/return_dys.php?q=" +
                  questionnaireStore.questionnaire.reference +
                  "&t=" +
                  questionnaireStore.repondant.token
                }
              >
                {t("retour_site") +
                  getNomSite(questionnaireStore.repondant.infos_paneliste.provenance)}
              </a>
            </>
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  return <Fragment>{afficheQuestion()}</Fragment>;
});

export default ConclusionBon;
