//REACT
import React, { useEffect, useRef, useState } from "react";

//MATERIAL UI
import questionStore from "../../store-mobx/questionStore";
import { useTranslation } from "react-i18next";
import { scrollToTop } from "./HelpersQuestion";

const CodePostal = (props) => {
  // La selectiona active de suggestion
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  // Les suggestions qui matchent la valeur entrée
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // Savoir si l'on affiche la liste de suggestions ou pas
  const [showSuggestions, setShowSuggestions] = useState(false);
  // Ce que l'utilisateur a entré
  const [userInput, setUserInput] = useState("");

  const refCheckbox = useRef();
  const refInput = useRef();

  const { t } = useTranslation();

  const configPays = {
    fr: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    en: {
      regex: /^[A-Z 0-9]{0,8}$/, //^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$
      nb_caracteres: 5,
    },
    it: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    be: { regex: /^[0-9]{0,4}$/, nb_caracteres: 4 },
    es: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    ch: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    de: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    ca: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
    us: { regex: /^[0-9]{0,5}$/, nb_caracteres: 5 },
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const checkRegex = (value) => {
    return configPays[props.pays].regex.test(value);
  };

  const onChange = (e) => {
    e.persist();
    let input = e.target.value;

    if (checkRegex(input)) {
      setUserInput(input);
      if (e.target.value.length >= configPays[props.pays].nb_caracteres) {
        questionStore.getCodePostaux(props.pays, input).then((res) => {
          var sugggestionsFiltrees = [];
          if (res.length > 0) {
            // Filter our suggestions that don't contain the user's input
            sugggestionsFiltrees = res.filter((suggestion) => suggestion.cp === input);
          }

          setActiveSuggestion(0);
          setFilteredSuggestions(sugggestionsFiltrees);
          setShowSuggestions(true);
        });
      }
    }
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const onClick = (suggestion) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion.cp + " - " + suggestion.ville);

    refCheckbox.current.checked = false;

    questionStore.reponse = suggestion;
  };

  const onClickCheckbox = (e) => {
    questionStore.reponse = {
      code_postal: userInput,
      ville_non_trouvee: true,
    };
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length > 0) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              className = "suggestion-active";
            }

            return (
              <li className={className} key={suggestion.code} onClick={(e) => onClick(suggestion)}>
                {suggestion.ville}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{t("code_postal.aucune_ville")}</em>
        </div>
      );
    }
  }

  return (
    <div className="code_postal">
      <input
        id="outlined-textarea"
        label={t("entrez_reponse")}
        placeholder={t("reponse")}
        autoComplete="off"
        className="colored"
        value={userInput}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type="text"
        ref={refInput}
      />
      {suggestionsListComponent}

      <div className="cp_non_trouve">
        <label onClick={(e) => onClickCheckbox(e)}>
          <input type="checkbox" name="cp_non_trouve" ref={refCheckbox} />

          {t("code_postal.checkbox_aucune_ville")}
        </label>
      </div>
    </div>
  );
};

export default CodePostal;
