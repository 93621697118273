import { motion } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import questionStore from "../store-mobx/questionStore";

const ProgressBarMIS = observer((props) => {
  const arrondi = (x) => {
    return Number.parseFloat(x).toFixed(0);
  };
  if (questionStore.progression > 0) {
    return (
      <div className="progress-mis">
        <motion.div className={"progress-container"}>
          <motion.div className="bar-background">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, width: `${questionStore.progression}%` }}
              exit={{ opacity: 0 }}
              className="bar"
            ></motion.div>
          </motion.div>
        </motion.div>
        <motion.div className="percentage" layout>
          {questionStore.progression >= 5 ? arrondi(questionStore.progression) + "%" : ""}
        </motion.div>
      </div>
    );
  } else {
    return <></>;
  }
});

export default ProgressBarMIS;
