import { motion } from "framer-motion";
import { observer } from "mobx-react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import questionStore from "../../store-mobx/questionStore";
import styleStore from "../../store-mobx/styleStore";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

const MatricielleMultipleMobile = observer(({ lignes, colonnes, errors }) => {
  const { t } = useTranslation();
  const [currentLigne, setCurrentLigne] = useState(0);
  const [reponses, setReponses] = useState([]);
  const [direction, setDirection] = useState(0);
  const [nbClickSuivant, setNbClickSuivant] = useState(0);

  const choix = useRef(null);
  const containerChoix = useRef(null);
  const nbLignes = lignes.length - 1;

  //pour la taille du container qui change
  useLayoutEffect(() => {
    containerChoix.current.style.height = choix.current.offsetHeight + "px";
    scrollToTop();
  }, [currentLigne]);

  useEffect(() => {
    //On compare à length-1 car on ne peut pas cliquer sur la dernière ligne
    if (nbClickSuivant >= nbLignes) {
      questionStore.submitHidden = false;
    } else {
      questionStore.submitHidden = true;
    }

    //Remet le bouton submit en place
    return () => {
      // questionStore.submitHidden = false;
    };
  });

  const handleSuivant = (event) => {
    if (questionStore.question.options.reponse_obligatoire === false) {
      if (currentLigne + 1 <= nbLignes) {
        setCurrentLigne(currentLigne + 1);
        setDirection(1);
        setNbClickSuivant(nbClickSuivant + 1);
      }
    } else {
      if (
        reponses.filter((rep) => {
          return rep.ligne_id === lignes[currentLigne].id && rep.colonnes_ids.length > 0;
        }).length > 0
      ) {
        if (currentLigne + 1 <= nbLignes) {
          setCurrentLigne(currentLigne + 1);
          setDirection(1);
          setNbClickSuivant(nbClickSuivant + 1);
        }
      }
    }
  };

  // const handlePrecedent = (event) => {
  //   if (currentLigne > 0) {
  //     setCurrentLigne(currentLigne - 1);
  //     setDirection(-1);
  //   }
  // };

  const handleClick = (ligne, colonne) => {
    var newReponses = [...reponses];

    var reponseClick = { ligne_id: ligne.id, colonnes_ids: [colonne.id] };
    var included = false;
    var isAucun = false;
    var aucunValue = [];

    colonnes.forEach((co, index) => {
      if (co.aucun) {
        isAucun = true;
        aucunValue.push(co.id);
      }
    });

    var ligneReponse = {};
    //REGARDE SI LA REPONSE EST DEJA PRESENTE
    newReponses.forEach((rep, index) => {
      if (rep.ligne_id === ligne.id) {
        included = true;
        ligneReponse = rep;
      }
    });

    //AJOUT OU SUPPRESSION DU TABLEAU ET GESTION DES VALEURS COCHEES
    if (included) {
      var index = ligneReponse.colonnes_ids.indexOf(colonne.id);
      if (index > -1) {
        ligneReponse.colonnes_ids.splice(index, 1);
      } else {
        if (
          questionStore.question.options.max &&
          ligneReponse.colonnes_ids.length < questionStore.question.options.max
        ) {
          ligneReponse.colonnes_ids.push(colonne.id);
        } else if (!questionStore.question.options.max) {
          ligneReponse.colonnes_ids.push(colonne.id);
        }
      }
    } else {
      newReponses.push(reponseClick);
    }

    //SI ON A UNE COLONNE EN AUCUN
    if (isAucun) {
      if (colonne.aucun === true) {
        newReponses.forEach((rep, index) => {
          if (rep.ligne_id === ligne.id) {
            rep.colonnes_ids = [colonne.id];
          }
        });
      } else {
        newReponses.forEach((rep, index) => {
          if (rep.ligne_id === ligne.id) {
            var indexAucun = "";
            aucunValue.forEach((aucun) => {
              indexAucun = rep.colonnes_ids.indexOf(aucun);
              if (indexAucun > -1) {
                rep.colonnes_ids.splice(indexAucun, 1);
              }
            });
          }
        });
      }
    }

    //MAJ
    setReponses(newReponses);

    questionStore.reponse = newReponses;
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction === 0 ? 0 : direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  // const swipeConfidenceThreshold = 10000;
  // const swipePower = (offset, velocity) => {
  //   return Math.abs(offset) * velocity;
  // };

  return (
    <motion.div className="matricielle-mobile">
      {/* {"layoutTransition"} */}
      <div className="container-choix" ref={containerChoix}>
        <motion.div
          ref={choix}
          key={currentLigne}
          className="choix"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 200 },
            opacity: { duration: 0.1 },
            delay: 0,
          }}
          // drag="x"
          // dragConstraints={{ left: 0, right: 0 }}
          // dragElastic={1}
          // onDragEnd={(e, { offset, velocity }) => {
          //   const swipe = swipePower(offset.x, velocity.x);

          //   if (swipe < -swipeConfidenceThreshold) {
          //     handleSuivant();
          //   } else if (swipe > swipeConfidenceThreshold) {
          //     handlePrecedent();
          //   }
          // }}
        >
          <div
            className={
              errors && errors[lignes[currentLigne].id] ? "current-ligne error" : "current-ligne"
            }
          >
            <Interweave content={lignes[currentLigne].label} />
          </div>

          {colonnes.map((colonne, index) => {
            return (
              <div
                key={index}
                onClick={() => handleClick(lignes[currentLigne], colonne)}
                className="colonne-container"
              >
                <input
                  type="checkbox"
                  name={"reponse_" + lignes[currentLigne].id}
                  className="colonne-input"
                  onChange={() => ""}
                  checked={
                    reponses.filter(
                      (rep) =>
                        rep.ligne_id === lignes[currentLigne].id &&
                        rep.colonnes_ids.filter((val) => val === colonne.id).length > 0
                    ).length > 0
                      ? true
                      : false
                  }
                />
                <span className="colonne-text">
                  <Interweave content={colonne.label} />
                </span>
              </div>
            );
          })}
        </motion.div>
      </div>

      <div className="button-container">
        {/* {currentLigne > 0 ? (
          <button type="button" onClick={handlePrecedent}>
            {"< Item précédent"}
          </button>
        ) : (
          ""
        )} */}

        {currentLigne + 1 <= nbLignes ? (
          <button
            type="button"
            className={`button-primary button-next ${styleStore.isTypeMIS ? "mis" : ""}`}
            onClick={(e) => {
              handleSuivant(e);
            }}
            disabled={
              questionStore.question.options.reponse_obligatoire === true &&
              reponses.filter((rep) => {
                return rep.ligne_id === lignes[currentLigne].id && rep.colonnes_ids.length > 0;
              }).length === 0
            }
          >
            {t("matricielle.item_suivant")}
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="ronds-container">
        {lignes.map((ligne, indexLigne) => {
          var etat = "";
          var ligneComparaison = currentLigne + 1;

          //check pour voir si rond déjà dans réponses
          var repPresente = false;
          reponses.forEach((rep) => {
            if (rep.ligne_id === ligne.id) {
              repPresente = true;
            }
          });

          if (parseInt(indexLigne + 1) < ligneComparaison) {
            etat = "filled";
          } else if (parseInt(indexLigne + 1) === ligneComparaison) {
            etat = "current";
          } else if (parseInt(indexLigne + 1) > ligneComparaison) {
            if (repPresente) {
              etat = "disabled-present";
            } else {
              etat = "disabled";
            }
          }
          return (
            <motion.div
              key={ligne.value}
              className={"rond-ligne " + etat}
              onClick={() => {
                if (etat !== "disabled") {
                  if (indexLigne < currentLigne) {
                    setDirection(-1);
                  } else {
                    setDirection(1);
                  }
                  setCurrentLigne(indexLigne);
                }
              }}
              layoutTransition
            ></motion.div>
          );
        })}
      </div>
    </motion.div>
  );
});

export default MatricielleMultipleMobile;
