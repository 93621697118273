//REACT
import React, { Component } from "react";

//MATERIAL UI
import questionStore from "../../store-mobx/questionStore";
import { withTranslation } from "react-i18next";
import { Suspense } from "react";
import { scrollToTop } from "./HelpersQuestion";

class HOCTexteLibre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(value) {
    this.setState({ value: value });

    questionStore.reponse = value;
    //this.props.setFieldValue(this.props.name, value);
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;
    return (
      <div>
        <textarea
          id="outlined-textarea"
          label={t("entrez_reponse")}
          placeholder={
            this.props.question.options.reponse_obligatoire === false
              ? t("entrez_reponse") + " (" + t("facultative") + ") "
              : t("entrez_reponse")
          }
          className="texte colored"
          onChange={(event) => this.handleChange(event.target.value)}
          rows={5}
          cols={5}
        ></textarea>
        <br />
        <br />
      </div>
    );
  }

  render() {
    return <div className="texteLibre">{this.afficheQuestion()}</div>;
  }
}

const Txt = withTranslation()(HOCTexteLibre);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function TexteLibre(props) {
  return (
    <Suspense fallback="loading">
      <Txt {...props} />
    </Suspense>
  );
}
