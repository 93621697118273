//REACT
import React from "react";
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", height: "90vh" }} className="error-404">
      <h1>{t("404.title")}</h1>
      <h2>{t("404.text")}</h2>
    </div>
  );
};

export default Error404;
