//REACT
import React, { useEffect } from "react";

import questionStore from "../../store-mobx/questionStore";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

const Echelle = observer(({ question, errors }) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTop();
  }, []);

  //Gestion de la note d'un critere
  const handleChange = (critere, value) => {
    var tempReponse = Object.assign([], questionStore.reponse);

    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);

    if (value === "non_concerne") {
      if (reponseIndex !== -1) {
        tempReponse[reponseIndex].value = value;
      } else {
        tempReponse.push({
          critere_id: critere.id,
          value: value,
        });
      }
    } else {
      if (reponseIndex !== -1) {
        tempReponse[reponseIndex].value = value;
      } else {
        tempReponse.push({ critere_id: critere.id, value: value });
      }
    }
    questionStore.reponse = tempReponse;
  };

  //Gestion du changement de texte dans le commentaire d'un critere
  const handleCommentaire = (critere, value) => {
    var tempReponse = Object.assign([], questionStore.reponse);

    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);
    if (reponseIndex !== -1) {
      tempReponse[reponseIndex].commentaire = value;
    } else {
      tempReponse.push({
        critere_id: critere.id,
        commentaire: value,
        value: "",
      });
    }
    questionStore.reponse = tempReponse;
  };

  const handleFleche = (critere, direction) => {
    var tempReponse = Object.assign([], questionStore.reponse);
    //Si on a du texte
    let reponseIndex = tempReponse.findIndex((rep) => rep.critere_id === critere.id);
    if (reponseIndex !== -1) {
      var newValue = tempReponse[reponseIndex].value;

      switch (direction) {
        case "gauche":
          if (newValue - 1 >= question.echelle.min) {
            newValue--;
          }
          break;
        case "droite":
          if (newValue + 1 <= question.echelle.max) {
            newValue++;
          }
          break;

        default:
          break;
      }

      tempReponse[reponseIndex].value = newValue;
    }
  };

  return (
    <div className="echelle">
      {question.criteres.map((critere) => {
        let notation = [];
        for (let index = question.echelle.min; index <= question.echelle.max; index++) {
          notation.push(index);
        }

        let NCchecked = false;
        let notePresente = false;

        if (Array.isArray(questionStore.reponse) === true) {
          questionStore.reponse.forEach((rep) => {
            if (rep.critere_id === critere.id && rep.value === "non_concerne") {
              NCchecked = true;
            } else if (rep.critere_id === critere.id) {
              notePresente = true;
            }
          });
        }

        return (
          <div key={critere.id} className="critere-container">
            <div className={errors[critere.id + "_global"] ? "label error" : "label"}>
              <Interweave content={critere.label} />
            </div>

            {(question.options.afficher_etiquettes === undefined ||
              question.options.afficher_etiquettes === true) && (
              <div className="etiquettes">
                <div
                  className={
                    errors[critere.id + "_global"] || errors[critere.id + "_note"]
                      ? "gauche error"
                      : "gauche"
                  }
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="arrow" />
                  {question.etiquettes.gauche}
                </div>
                <div
                  className={
                    errors[critere.id + "_global"] || errors[critere.id + "_note"]
                      ? "droite error"
                      : "droite"
                  }
                >
                  {question.etiquettes.droite}{" "}
                  <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                </div>
              </div>
            )}
            <div
              className={
                errors[critere.id + "_global"] || errors[critere.id + "_note"]
                  ? "notation error"
                  : "notation"
              }
            >
              {notation.map((note) => {
                let checked = false;

                //TODO : enlever la condition qui corrige le pb de reponse
                if (Array.isArray(questionStore.reponse) === true) {
                  questionStore.reponse.forEach((rep) => {
                    if (rep.critere_id === critere.id && String(rep.value) === String(note)) {
                      checked = true;
                    }
                  });
                }
                return (
                  <label
                    key={note}
                    className={checked ? "note checked" : "note"}
                    onClick={() => {
                      handleChange(critere, note);
                    }}
                  >
                    <Field
                      type="radio"
                      name={critere.id + "_note"}
                      value={note}
                      checked={checked}
                      data-testid={"reponse_" + critere.value}
                    />
                    {question.options.notes_masquees ? "" : note}
                  </label>
                );
              })}
            </div>

            {isMobile && notePresente && (
              <div className="fleches">
                <div className="gauche" onClick={() => handleFleche(critere, "gauche")}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className="droite" onClick={() => handleFleche(critere, "droite")}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            )}

            {question.sous_echelle.ajouter_commentaire && (
              <div className="commentaire">
                <Field
                  type="text"
                  className={
                    errors[critere.id + "_commentaire"]
                      ? "commentaire-input error"
                      : "commentaire-input"
                  }
                  name={critere.id + "_commentaire"}
                  placeholder={
                    (question.sous_echelle.label_commentaire
                      ? question.sous_echelle.label_commentaire
                      : t("echelle.entrez_commentaire")) +
                    (question.sous_echelle.commentaire_facultatif
                      ? " (" + t("facultatif") + ")"
                      : "")
                  }
                  onChange={(e) => handleCommentaire(critere, e.target.value)}
                />
              </div>
            )}

            {question.sous_echelle.ajouter_non_concerne && (
              <div className="non-concerne">
                <label className={"note-nc"} key={"nc"}>
                  <Field
                    type="radio"
                    name={critere.id + "_note"}
                    value={"non_concerne"}
                    checked={NCchecked}
                    onClick={() => {
                      handleChange(critere, "non_concerne");
                    }}
                    // onChange={(e) =>
                    //   "fake onChange car sinon la value est modifiée et la validation passe"
                    // }
                  />
                  {question.sous_echelle.label_non_concerne
                    ? question.sous_echelle.label_non_concerne
                    : t("echelle.non_concerne")}
                </label>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default Echelle;
