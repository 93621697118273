import * as React from "react";

function SvgSmiley2(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.927 122.927">
      <defs>
        <filter
          id="Ellipse_34"
          x="0"
          y="0"
          width="121.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-02" transform="translate(4.691 3.175)">
        <g id="Groupe_1893" data-name="Groupe 1893">
          <rect
            id="Rectangle_425"
            data-name="Rectangle 425"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1892" data-name="Groupe 1892" transform="translate(4.309 2.825)">
            <g id="Groupe_1891" data-name="Groupe 1891">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_34)">
                <ellipse
                  id="Ellipse_34-2"
                  data-name="Ellipse 34"
                  cx="51.964"
                  cy="52.464"
                  rx="51.964"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1894" data-name="Groupe 1894" transform="translate(9.681 8.197)">
          <ellipse
            id="Ellipse_35"
            data-name="Ellipse 35"
            cx="46.592"
            cy="47.092"
            rx="46.592"
            ry="47.092"
            fill="#e81112"
          />
        </g>
        <g id="Groupe_1897" data-name="Groupe 1897" transform="translate(38.158 38.094)">
          <g id="Groupe_1895" data-name="Groupe 1895">
            <path
              id="Tracé_1861"
              data-name="Tracé 1861"
              d="M92.683,102.284h0A4.869,4.869,0,0,1,87.8,97.4V92.383A4.869,4.869,0,0,1,92.683,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,92.683,102.284Z"
              transform="translate(-87.8 -87.5)"
            />
          </g>
          <g id="Groupe_1896" data-name="Groupe 1896" transform="translate(26.504)">
            <path
              id="Tracé_1862"
              data-name="Tracé 1862"
              d="M152.383,102.284h0A4.869,4.869,0,0,1,147.5,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.884,4.883v5.061A4.86,4.86,0,0,1,152.383,102.284Z"
              transform="translate(-147.5 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1899" data-name="Groupe 1899" transform="translate(39.226 64.634)">
          <g id="Groupe_1898" data-name="Groupe 1898">
            <path
              id="Tracé_1863"
              data-name="Tracé 1863"
              d="M122.437,163.208a2.039,2.039,0,0,1-1.909-1.421,13.453,13.453,0,0,0-6.615-8.213,13.756,13.756,0,0,0-19.8,8.08,1.994,1.994,0,0,1-3.818-1.154,17.792,17.792,0,0,1,34.1.178,2.009,2.009,0,0,1-1.376,2.486C122.792,163.164,122.615,163.208,122.437,163.208Z"
              transform="translate(-90.211 -147.885)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley2;
