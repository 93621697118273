import * as React from "react";

function MISGroupBlanc(props) {
  return (
    <svg
      id="prefix__Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 106.4 16.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st0{fill:#fff}"}</style>
      <title>{"Fichier 1"}</title>
      <path
        className="prefix__st0"
        d="M65.4 3.4c1.3-.1 2.5.4 3.5 1.2L68 5.9c-.7-.6-1.6-.9-2.5-.9-1.7-.1-3.1 1.1-3.2 2.8v.4c-.1 1.7 1.2 3.2 2.9 3.3h.2c.9 0 1.7-.4 2.3-1V9.4h-1.4V8h3v4.9h-1.5v-.4-.4c-.7.7-1.7 1-2.7 1-2.5 0-4.6-2.1-4.6-4.6v-.2c-.1-2.6 2-4.7 4.6-4.8.1-.1.2-.1.3-.1zM71.1 3.6h3c.6-.1 1.3 0 1.9.3 1 .5 1.6 1.5 1.5 2.5.1 1.1-.5 2.1-1.5 2.5V9c.1.1.2.3.3.5l1.9 3.5h-1.9l-1.8-3.5h-1.6V13h-1.7V3.6zM74.3 8c.7.1 1.4-.5 1.5-1.2v-.2c0-.9-.4-1.4-1.7-1.4h-1.3V8h1.5zM83.8 3.4c2.6-.1 4.8 2 4.8 4.6v.2c0 2.7-2.2 4.8-4.8 4.8-2.7 0-4.8-2.2-4.8-4.8 0-2.6 2.1-4.7 4.7-4.8h.1zm0 8.1c1.7 0 3.1-1.5 3.1-3.2v-.1c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1-.1 1.7 1.2 3.2 3 3.3h.1zM90.2 3.6h1.7v6c.1 1.1 1.1 2 2.2 1.9 1-.1 1.8-.9 1.9-1.9v-6h1.7v6c-.1 2.1-1.9 3.7-3.9 3.6-1.9-.1-3.5-1.6-3.6-3.6v-6zM99.9 3.6h3.6c1.6-.1 2.9 1.1 3 2.7v.3c.1 1.6-1.1 3-2.7 3.1H101.6v3.2h-1.7V3.6zm3.2 4.6c.8.1 1.5-.6 1.6-1.4v-.2c.1-.8-.5-1.5-1.3-1.6H101.5v3.2h1.6zM20.8 16.2L17.8 5l-4.5 10.4c-.1.3-.3.6-.6.8-.2.2-.5.3-.9.3-.7 0-1.2-.4-1.4-1.1L5.8 5l-3 11.2H0L4.1 1.1C4.2.5 4.8 0 5.5 0c.3 0 .6.1.8.2.3.2.5.5.6.8l5 11.4L16.8 1c.2-.6.7-1 1.4-1s1.3.5 1.4 1.1l4.1 15.1h-2.9zM26.8 16.3V.2h2.8v16.1h-2.8zM33 16.3v-2.8h12c.6 0 1.3-.2 1.7-.6.4-.4.6-.9.6-1.5s-.2-1.2-.6-1.6c-.5-.4-1.1-.6-1.7-.5h-7.3c-.7 0-1.4-.1-2-.3-.6-.2-1.1-.5-1.6-.9-.4-.4-.8-.9-1-1.4-.2-.6-.3-1.2-.3-1.8 0-.6.1-1.2.3-1.8.2-.5.6-1 1-1.4.4-.4 1-.7 1.5-.9.7-.2 1.4-.4 2.1-.3h11.5V3H37.7c-.6 0-1.1.2-1.5.5-.4.4-.6.9-.5 1.5 0 .5.2 1.1.5 1.4.4.4.9.5 1.5.5H45c1.3-.1 2.7.3 3.7 1.1.9.9 1.4 2.2 1.3 3.5 0 .6-.1 1.3-.3 1.9-.2.6-.5 1.1-.9 1.5-.4.4-1 .8-1.6 1-.7.2-1.4.4-2.2.4H33z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        d="M55.9.1v16.3"
      />
    </svg>
  );
}

export default MISGroupBlanc;
