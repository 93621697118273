//REACT
import React, { Component, Fragment } from "react";

//MATERIAL
import { toJS } from "mobx";
import questionStore from "../../store-mobx/questionStore";
import { AnimatePresence, motion } from "framer-motion";
import { withTranslation } from "react-i18next";
import { Suspense } from "react";
import { sanitizeHtml, scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

class HOCClassement extends Component {
  constructor(props) {
    super(props);

    var modalites = [];
    var countModalites = 0;
    this.props.question.modalites.forEach((modalite) => {
      if (!modalite.ranking) {
        modalite.ranking = "";
      } else if (modalite.ranking !== "") {
        countModalites++;
      }
      modalites.push(modalite);
    });

    this.state = {
      modalites: modalites,
      currentRank: 1,
      countModalites: countModalites,
      autres: [],
      options: this.props.question.options,
      errors: this.props.errors,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  /**
   * Mets à jour les valeurs des autres modalites
   * @param {*} ranking
   */
  updateRanks(ranking) {
    var newModalites = this.state.modalites.map((moda, index) => {
      if (ranking < moda.ranking) {
        moda.ranking--;
      }
      return moda;
    });
    return newModalites;
  }

  /**
   * Mets à zéro les modalites
   * @param {*} ranking
   */
  resetRanking() {
    var newModalites = this.state.modalites.map((moda, index) => {
      moda.ranking = "";
      return moda;
    });

    this.setState({
      modalites: newModalites,
      currentRank: 1,
      countModalites: 0,
    });
    return newModalites;
  }

  handleChange(event, modalite) {
    //DUPLICATIONS
    var newCurrentRank = this.state.currentRank;
    var newModalites = this.state.modalites;
    var newCountModalites = this.state.countModalites;
    var newAutres = this.state.autres;

    //TRAITEMENT

    //clic sur aucun pas checked
    if (
      (modalite.aucun === true && event.target.checked === true) ||
      (modalite.aucun === true && event.target.name === "precision")
    ) {
      //on coche la moda aucun et on decoche les autres
      newModalites.forEach((moda) => {
        if (
          (moda.aucun && event.target.value === moda.value) ||
          (moda.aucun && event.target.dataset.value === moda.value)
        ) {
          moda.ranking = "1";
          moda.checked = true;
        } else {
          moda.ranking = "";
          moda.checked = false;
        }
      });
      newAutres = [];
      newCurrentRank = 1;
      newCountModalites = 0;
      questionStore.aucun = true;
    }
    //clic sur aucun  déjà checked
    else if (modalite.aucun === true && event.target.checked === false) {
      newModalites.forEach((moda) => {
        moda.ranking = "";
        moda.checked = false;
      });
      questionStore.aucun = false;
    }
    //modalite déjà checked
    else if (modalite.ranking !== "") {
      var temp_rank = modalite.ranking;

      newModalites.forEach((moda) => {
        if (moda.value === modalite.value) {
          moda.ranking = "";
        }
      });

      var indexPrecisionToDelete = -1;
      newAutres.forEach((precision, indexPrecision) => {
        if (precision.modalite_id === modalite.id) {
          indexPrecisionToDelete = indexPrecision;
        }
      });

      if (indexPrecisionToDelete !== -1) {
        newAutres.splice(indexPrecisionToDelete, 1);
      }

      newCurrentRank = newCurrentRank - 1;
      newCountModalites--;
      newModalites = this.updateRanks(temp_rank);
    }
    //modalite pas checked
    else {
      if (this.state.options.max) {
        if (this.state.countModalites < this.state.options.max) {
          newModalites.forEach((moda) => {
            if (moda.value === modalite.value) {
              moda.ranking = newCurrentRank;
            }
            if (moda.aucun === true) {
              moda.checked = false;
              moda.ranking = "";
            }
          });
          newCurrentRank = newCurrentRank + 1;
          newCountModalites++;
        }
      } else {
        newModalites.forEach((moda) => {
          if (moda.value === modalite.value) {
            moda.ranking = newCurrentRank;
          }
          if (moda.aucun === true) {
            moda.checked = false;
            moda.ranking = "";
          }
        });
        newCurrentRank = newCurrentRank + 1;
        newCountModalites++;
      }
    }

    //MAJS
    //this.props.setFieldValue(this.props.name, newModalites);
    questionStore.reponse = newModalites;
    this.setState({
      modalites: newModalites,
      currentRank: newCurrentRank,
      countModalites: newCountModalites,
      autres: newAutres,
    });
  }

  handleChangePrecision(event, modalite) {
    var newModalites = this.state.modalites;
    var newAutres = this.state.autres;
    var newCurrentRank = this.state.currentRank;
    var newCountModalites = this.state.countModalites;

    console.log(event.target.value, toJS(modalite));

    //si precision remplie
    if (event.target.value !== "") {
      var exists = false;
      newAutres.forEach((autre) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
        }
      });
      if (modalite.ranking === "") {
        modalite.ranking = newCurrentRank;
        newCurrentRank++;
        newCountModalites++;
        if (modalite.aucun) {
          //on coche la moda aucun et on decoche les autres
          newModalites.forEach((moda) => {
            if (
              (moda.aucun && modalite.value === moda.value) ||
              (moda.aucun && event.target.dataset.value === moda.value)
            ) {
              moda.ranking = "1";
              moda.checked = true;
            } else {
              moda.ranking = "";
              moda.checked = false;
            }
          });
          newAutres = [];
          newCurrentRank = 1;
          newCountModalites = 0;
          questionStore.aucun = true;
        }
      }
      if (exists === false) {
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      } else {
        var index = newAutres.indexOf(modalite.value);
        newAutres.splice(index, 1);
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      }
    }
    //si precision vide
    else {
      newAutres.forEach((autre, index) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
          newAutres.splice(index, 1);
        }
      });
      if (modalite.ranking !== "") {
        modalite.ranking = "";
        newCurrentRank--;
        newCountModalites--;
        if (modalite.aucun) {
          modalite.checked = false;
        }
      }
    }

    console.log(toJS(newModalites), toJS(newAutres));

    this.setState({
      modalites: newModalites,
      autres: newAutres,
      currentRank: newCurrentRank,
      countModalites: newCountModalites,
    });

    questionStore.reponse = newModalites;
    questionStore.reponse_autre = newAutres;
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;
    var modalites = this.state.modalites;
    const blocModalites = modalites.map((modalite) => {
      var valuePrecision = "";
      this.state.autres.forEach((autre) => {
        if (autre.modalite_id === modalite.id) {
          valuePrecision = autre.precision;
        }
      });
      var label = sanitizeHtml(modalite.label);
      if (modalite.precision) {
        label = label + " : ";
      }
      if (!modalite.aucun) {
        return (
          <div
            key={modalite.value}
            className={"item-ranking"}
            data-testid={"item-ranking"}
            data-checked={modalite.checked === true ? true : false}
            onClick={(event) => this.handleChange(event, modalite)}
          >
            {modalite.ranking ? (
              <div className={"ranking "} data-testid={"puce-ranking"}>
                {modalite.ranking}
              </div>
            ) : (
              ""
            )}

            <div
              className={
                this.props.errors &&
                this.props.errors[modalite.id] &&
                this.props.errors[modalite.id]["precisez"]
                  ? "label error"
                  : "label"
              }
            >
              <Interweave content={label} />
            </div>

            {modalite.precision ? (
              <>
                <input
                  label="précisez"
                  variant="outlined"
                  type="text"
                  className="colored"
                  data-value={modalite.value}
                  placeholder={t("entrez_precision")}
                  name="precision"
                  value={valuePrecision}
                  onChange={(event) => {
                    var nbRanked = 0;
                    var modaliteRanked = false;
                    questionStore.reponse.forEach((rep) => {
                      if (rep.ranking !== "") {
                        nbRanked++;
                      }
                      if (rep.id === modalite.id && rep.ranking !== "") {
                        modaliteRanked = true;
                      }
                    });

                    if (
                      questionStore.question.options.max &&
                      nbRanked >= questionStore.question.options.max &&
                      modaliteRanked === false
                    ) {
                    } else {
                      this.handleChangePrecision(event, modalite);
                    }
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return (
          <label
            key={modalite.value}
            className={"item-ranking"}
            data-checked={modalite.checked === true ? true : false}
          >
            <input
              className="aucun"
              id={"aucun" + modalite.value}
              type="checkbox"
              value={modalite.value}
              checked={modalite.checked === true ? true : false}
              onClick={(event) => {
                this.handleChange(event, modalite);
              }}
            />
            <div
              className={
                this.props.errors &&
                this.props.errors[modalite.id] &&
                this.props.errors[modalite.id]["precisez"]
                  ? "label error"
                  : "label"
              }
            >
              <Interweave content={modalite.label} />
            </div>

            {modalite.precision && (
              <>
                <input
                  label="précisez"
                  variant="outlined"
                  type="text"
                  className="colored"
                  data-value={modalite.value}
                  placeholder={t("entrez_precision")}
                  name="precision"
                  value={valuePrecision}
                  onChange={(event) => {
                    var nbRanked = 0;
                    var modaliteRanked = false;
                    questionStore.reponse.forEach((rep) => {
                      if (rep.ranking !== "") {
                        nbRanked++;
                      }
                      if (rep.id === modalite.id && rep.ranking !== "") {
                        modaliteRanked = true;
                      }
                    });

                    if (
                      questionStore.question.options.max &&
                      nbRanked >= questionStore.question.options.max &&
                      modaliteRanked === false
                    ) {
                    } else {
                      this.handleChangePrecision(event, modalite);
                    }
                  }}
                />
              </>
            )}
          </label>
        );
      }
    });

    return (
      <Fragment>
        <AnimatePresence>
          {this.state.countModalites >= 2 && (
            <motion.button
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
              transition={{
                type: "spring",
                stiffness: 100,
                default: { duration: 0.2 },
              }}
              type="button"
              data-testid={"button-reset-ranking"}
              className="button-reset-ranking"
              onClick={() => this.resetRanking()}
            >
              {/* Vider les réponses */}
              {t("reset_ranking")}
            </motion.button>
          )}
        </AnimatePresence>

        {blocModalites}
      </Fragment>
    );
  }

  render() {
    return (
      <div className="classement conteneur-modalites">
        {this.state.errors && this.state.errors.min ? <div>{this.state.errors.min}</div> : ""}
        {this.afficheQuestion()}
      </div>
    );
  }
}

const Class = withTranslation()(HOCClassement);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function Classement(props) {
  return (
    <Suspense fallback="loading">
      <Class {...props} />
    </Suspense>
  );
}
