import decode from "jwt-decode";

export default class QuestionService {
  // Initializing important variables
  constructor() {
    this.domain = process.env.REACT_APP_URL_API; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
  }

  getQuestion = (idQuestionnaire, sectionId, idQuestion) => {
    return this.fetch(
      `${this.domain}/questionnaires/${idQuestionnaire}/sections/${sectionId}/questions/${idQuestion}`,
      {
        method: "GET",
      }
    ).then((res) => {
      return res;
    });
  };

  getQuestionBandeau = (numQuestionnaire, numQuestion, tokenRepondant) => {
    const url =
      this.domain +
      "/token_repondant/" +
      tokenRepondant +
      "/questionnaires/" +
      numQuestionnaire +
      "/questions/" +
      numQuestion +
      "/afficher";
    return this.fetch(url, {
      method: "POST",
    }).then((res) => {
      return res;
    });
  };

  getCodePostaux = (pays, codePostal) => {
    const url =
      this.domain + "/code_postaux/" + pays + "/recuperer/" + codePostal;
    return this.fetch(url, {
      method: "GET",
    }).then((res) => {
      return res;
    });
  };

  repondreQuestion = (dataToSend) => {
    const url =
      process.env.REACT_APP_URL_API + "/questionnaires/questions/repondre";
    return this.fetch(url, {
      method: "PUT",
      body: dataToSend,
    });
  };

  //TODO check si c'est encore utilisé, rien dans le BO
  recupererPremiereQuestion = (idQuestionnaire) => {
    const url =
      process.env.REACT_APP_URL_API + "/repondants/recupere_premiere_question";
    let data = { questionnaire_id: idQuestionnaire };
    return this.fetch(url, {
      method: "POST",
      body: data,
    });
  };

  viderReponses = (idQuestionnaire, tokenRepondant) => {
    const url = process.env.REACT_APP_URL_API + "/repondants/vider_reponses";
    let dataStringified = {
      questionnaire_id: idQuestionnaire,
      token_repondant: tokenRepondant,
    };
    return this.fetch(url, {
      method: "DELETE",
      body: dataStringified,
    });
  };

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken("token");
    }

    //on ajoute le type pour le bucket ciblé + le stringify
    var newOptions = { ...options };
    if (options.method !== "GET") {
      newOptions = {
        ...options,
        body: JSON.stringify({
          ...options.body,
          type: window.sessionStorage.getItem("type"),
        }),
      };
    }

    return fetch(url, {
      headers,
      ...newOptions,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken("token"); // GEtting token from localStorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  getToken(name) {
    // Retrieves the user token from localStorage
    return localStorage.getItem(name);
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
