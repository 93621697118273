import * as React from "react";

function SvgCoeur(props) {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 424.3 359.2"
      fill="#ccc"
      {...props}
    >
      <g id="QgnZsn_00000004546389518279223110000018256617820545845682_">
        <g>
          <path
            d="M424.3,112c0.4,31.7-8.1,59-23.5,84.4c-12.2,20-27.8,37.1-44.6,53.2c-25.3,24.2-52.1,46.6-80.4,67.2
			c-18.9,13.7-38.4,26.7-57.5,40.2c-4.3,3-7.9,2.8-12.3,0c-33.4-21.8-65.7-45.1-96.5-70.5c-21.8-18-42.8-36.8-61.8-57.8
			c-16.1-17.9-29.4-37.6-38-60.2C3.5,151.9,0,134.9,0,117.3c0-9.4,0.1-18.8,1.9-28.1C5.5,71.1,13,55,24.7,40.7
			C37,25.5,52.4,14.4,70.6,7.4c15.3-5.9,31.2-8,47.6-7.1c11.4,0.6,22.7,2.5,33.6,5.9c15.7,4.8,29.9,12.5,42.4,23.2
			c5.5,4.7,10.8,9.6,16.1,14.6c1.5,1.4,2.4,1.4,3.9,0c8.7-8.6,17.8-16.8,28.1-23.5c15.2-10,31.9-15.8,49.8-18.6
			c12.4-1.9,24.8-2.5,37.1-0.6c37.4,5.8,65.2,25.4,83,58.8C421.1,76.7,424.9,94.8,424.3,112z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCoeur;
