//REACT
import React, { useEffect, useRef, useState } from "react";

//SERVICE & STORE
import questionStore from "../store-mobx/questionStore";

//QUESTIONS
import ChoixMultiple from "./questions/ChoixMultiple";
import ChoixUnique from "./questions/ChoixUnique";
import Classement from "./questions/Classement";
import CodePostal from "./questions/CodePostal";
import ImageChoixMultiple from "./questions/ImageChoixMultiple";
import ImageChoixUnique from "./questions/ImageChoixUnique";
import Intro from "./questions/Intro";
import MatricielleMultiple from "./questions/MatricielleMultiple";
import MatricielleUnique from "./questions/MatricielleUnique";
import Numerique from "./questions/Numerique";
import TexteLibre from "./questions/TexteLibre";
import Transition from "./questions/Transition";
import Coordonnees from "./questions/Coordonnees";
import ChampsTextes from "./questions/ChampsTextes";
import Echelle from "./questions/Echelle";
import Satisfaction from "./questions/Satisfaction";

//MATERIAL UI
import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import { Formik } from "formik";
import questionnaireStore from "../store-mobx/questionnaireStore";
import ConclusionBon from "./questions/ConclusionBon";
import ConclusionMauvais from "./questions/ConclusionMauvais";
import { motion, AnimatePresence } from "framer-motion";
import { Interweave } from "interweave";
import ButtonNext from "./ButtonNext";
import { getNumeroQuestionContenu } from "./questions/HelpersQuestion";
import styleStore from "../store-mobx/styleStore";
import ProgressBarMIS from "./ProgressBarMIS";
import md5 from "md5";
import { useTranslation } from "react-i18next";
import { addZoomToImages, transformMedia } from "./questions/HelpersMedia";

const Question = observer((props) => {
  const { t } = useTranslation();

  const indicationRef = useRef();

  const [messageAttente, setAttenteMessage] = useState(false);
  const [timer, setTimer] = useState(null);
  const [dateDebut, setDateDebut] = useState(Date.now());

  useEffect(() => {
    setDateDebut(Date.now());
    setAttenteMessage(false);
    setTimer((prev) => clearTimeout(prev));
  }, [questionStore.question.id]);

  useEffect(() => {
    addZoomToImages(indicationRef);
  }, [questionStore.question.indication]);

  const getTempsAttente = (question) => {
    if (question.type_question === "Transition") {
      return question.temps_attente ?? 0;
    } else {
      return question.options?.temps_attente ?? 0;
    }
  };

  const type_etude_mis = questionnaireStore.questionnaire.type_etude_mis;
  const ref = questionnaireStore.questionnaire.reference;

  //Infos de la question pour affichage dynamique
  let question = questionStore.question;
  var submitHidden = questionStore.submitHidden;
  let listeQuestions = {
    ChoixMultiple: ChoixMultiple,
    ChoixUnique: ChoixUnique,
    ChampsTextes: ChampsTextes,
    Classement: Classement,
    CodePostal: CodePostal,
    ConclusionBon: ConclusionBon,
    ConclusionMauvais: ConclusionMauvais,
    Coordonnees: Coordonnees,
    Echelle: Echelle,
    Satisfaction: Satisfaction,
    ImageChoixMultiple: ImageChoixMultiple,
    ImageChoixUnique: ImageChoixUnique,
    Intro: Intro,
    MatricielleMultiple: MatricielleMultiple,
    MatricielleUnique: MatricielleUnique,
    Numerique: Numerique,
    TexteLibre: TexteLibre,
    Transition: Transition,
  };

  let Question = listeQuestions[question.type_question];

  var annee = new Date().getFullYear();

  const is_type_mis = styleStore.isTypeMIS;
  const tempsAttente = getTempsAttente(question);

  const renderValidationErrors = (errors) => {
    var errorsToRender = [];

    if (question.type_question === "Coordonnees") {
    } else {
      for (const [key, value] of Object.entries(errors)) {
        if (typeof value === "string") {
          //check parce que l'on peut avoir des objets parfois
          errorsToRender.push(
            <motion.div
              key={key}
              animate={{
                x: [0, 10, -10, 10, -10, 10, 0],
              }}
              className="bloc-erreur"
            >
              <Interweave content={value} />
            </motion.div>
          );
          break; //permet de rendre une seule erreur à la fois
        }
      }
    }

    return errorsToRender;
  };

  // chargemet de question suivante trop long => afficher message
  const afficherMessageAttente = () => {
    setTimer(
      setTimeout(() => {
        setAttenteMessage(true);
      }, 2000)
    );
  };

  return (
    <>
      {questionnaireStore.repondant.source === "test" && (
        <div className="header-test">
          <p className="warning">{t("test.attention")}</p>
          <p className="explication">{t("test.explication")}</p>
        </div>
      )}
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          reponse: questionStore.reponse,
          reponse_autre: [],
          aucun: false,
        }}
        validate={(values) => {
          if (question.type_question !== "Intro") {
            return import("../validations/" + question.type_question).then((questionValidation) => {
              let errorTemp = {};
              errorTemp = questionValidation.validation(question, values, t);

              if (Object.keys(errorTemp).length > 0) {
                var questElement = document.getElementById("form-id");
                if (questElement) {
                  questElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start", //can take start, end
                  });
                }
              }
              return errorTemp;
            });
          }
        }}
        onSubmit={(values, { setSubmitting }) => {
          let chrono = (Date.now() - dateDebut) / 1000;
          afficherMessageAttente();
          questionStore
            .repondreQuestion(
              {
                reponse: questionStore.reponse,
                reponse_autre: questionStore.reponse_autre,
                aucun: questionStore.aucun,
              },
              questionStore.questionnaire_id,
              questionStore.section_id,
              questionStore.question.id,
              props.tokenRepondant,
              chrono
            )
            .then((retour) => {
              if (retour.erreur === false) {
                questionStore.section_id = retour.question.section_id;
                questionStore.errors = [];
              } else {
                var questElement = document.getElementById("form-id");
                if (questElement) {
                  questElement.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }

              //values.reponse = [];
              if (questionStore.erreurServeur.length > 0) {
                // document
                //   .getElementsByClassName("contenu")[0]
                //   .scrollTo({ top: 0, behavior: "smooth" });
                // setSubmitting(false);
                //questionStore.reponse = [];
                //questionStore.reponse_autre = [];
                setSubmitting(false);
              } else {
                //FIX pour afficher les erreurs sur la question coordonnées
                if (
                  retour.question.type_question === "Coordonnees" ||
                  (retour.question.type_question === "Transition" &&
                    retour.question.type_consentement)
                ) {
                  //questionStore.reponse = { champs: [], consentements: [] };
                } else {
                  questionStore.reponse = [];
                }
                questionStore.reponse_autre = [];
                setSubmitting(false);
              }
            });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} id="form-id" className="form">
            {questionStore.questionBandeauIsLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <AnimatePresence>
                <motion.div
                  key="conteneur"
                  // layoutTransition //voir pour retirer l'effet
                  className="conteneur-question"
                  id="conteneur-question"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {is_type_mis ? (
                    <div className="conteneur-logo-mis">
                      {/* {questionnaireStore.repondant.source === "test" ? ( */}
                      <img
                        className="logo-mis"
                        // src={
                        //   "https://cloud.madeinsurveys.com/esurvey/getLogo.php?ref=" +
                        //   md5(
                        //     "MIM59LILLE" +
                        //       questionnaireStore.questionnaire.reference
                        //   )
                        // }
                        src={
                          questionnaireStore.questionnaire &&
                          type_etude_mis &&
                          (type_etude_mis === "10_quali_fr" || type_etude_mis === "10_quali_uk")
                            ? "https://cloud.madeinsurveys.com/esurvey/getLogo.php?ref=" +
                              md5("MIM59LILLE" + ref) +
                              "&s=" +
                              md5(
                                "MIM59LILLE" +
                                  questionnaireStore.repondant.infos_paneliste.provenance
                              )
                            : "https://cloud.madeinsurveys.com/esurvey/getLogo.php?ref=" +
                              md5("MIM59LILLE" + ref)
                        }
                        alt="logo"
                      />
                      {/* ) : (
                        <SVGMIS className="logo-mis" />
                      )} */}

                      {questionnaireStore.questionnaire.options &&
                      questionnaireStore.questionnaire.options.afficher_progression ? (
                        <ProgressBarMIS />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {question.type_question !== "Intro" &&
                  question.type_question !== "ConclusionBon" &&
                  question.type_question !== "ConclusionMauvais" &&
                  question.type_question !== "Transition" ? (
                    <div className="infos-question" ref={indicationRef}>
                      {question.indication && question.options.affichage_indication === true ? (
                        <span className="ck-content">
                          <Interweave content={question.indication} transform={transformMedia} />
                        </span>
                      ) : (
                        ""
                      )}

                      <div
                        className={`first-line taille-${
                          questionnaireStore.questionnaire.options?.taille_intitule ?? "20"
                        }`}
                      >
                        {[true, undefined, null].includes(
                          questionnaireStore.questionnaire.options?.afficher_numero_question
                        ) && (
                          <h2 className="numero-question">
                            {getNumeroQuestionContenu(question, t)}
                          </h2>
                        )}
                        <div className="intitule">
                          <Interweave
                            attributes={{ className: "ck-content" }}
                            content={question.intitule}
                          />
                        </div>
                      </div>
                      {question.precision ? (
                        <div className="precision">
                          <Interweave content={question.precision} />
                        </div>
                      ) : (
                        ""
                      )}

                      {renderValidationErrors(errors)}
                      {questionStore.erreurServeur
                        ? questionStore.erreurServeur.map((erreur, index) => {
                            return (
                              <motion.div
                                key={index}
                                animate={{
                                  x: [0, 10, -10, 10, -10, 10, 0],
                                }}
                                className="bloc-erreur"
                              >
                                <Interweave content={t("validation." + erreur)} />
                              </motion.div>
                            );
                          })
                        : ""}
                    </div>
                  ) : (
                    ""
                  )}

                  <Question
                    key={question.id}
                    question={question}
                    values={values}
                    selected={questionStore.reponse}
                    onChange={handleChange}
                    name={"reponse"}
                    reponse={values.reponse}
                    errors={errors}
                    {...question}
                  />
                  {question.type_question !== "ConclusionBon" &&
                  question.type_question !== "ConclusionMauvais" &&
                  is_type_mis &&
                  submitHidden === false ? (
                    <>
                      <ButtonNext
                        isSubmitting={isSubmitting}
                        className="button-next mis"
                        texte={t("suite")}
                        t={t}
                        tempsAttente={tempsAttente}
                        loupe={questionStore.question.possede_redirection_aleatoire}
                      />

                      {questionStore.question.possede_redirection_aleatoire && isSubmitting && (
                        <Interweave
                          attributes={{ className: "message-attente" }}
                          content={t("message_attente_aleatoire")}
                        />
                      )}

                      {messageAttente && !questionStore.question.possede_redirection_aleatoire && (
                        <Interweave
                          attributes={{ className: "message-attente" }}
                          content={t("message_attente")}
                        />
                      )}

                      {/* {this.state.messageAttente && (
                          <Interweave
                            attributes={{ className: "message-attente" }}
                            content={
                              questionStore.question.possede_redirection_aleatoire
                                ? t("message_attente_aleatoire")
                                : t("message_attente")
                            }
                          />
                        )} */}
                    </>
                  ) : (
                    ""
                  )}
                </motion.div>
              </AnimatePresence>
            )}
            {is_type_mis ? (
              <div className="footer-mis">
                {styleStore.footerMIS} {annee}
              </div>
            ) : (
              ""
            )}
            {question.type_question !== "ConclusionBon" &&
            question.type_question !== "ConclusionMauvais" &&
            is_type_mis === false &&
            submitHidden === false ? (
              <>
                <ButtonNext
                  isSubmitting={isSubmitting}
                  className="button-next"
                  t={t}
                  tempsAttente={tempsAttente}
                  loupe={questionStore.question.possede_redirection_aleatoire}
                />
                {questionStore.question.possede_redirection_aleatoire && isSubmitting && (
                  <Interweave
                    attributes={{ className: "message-attente" }}
                    content={t("message_attente_aleatoire")}
                  />
                )}
                {messageAttente && !questionStore.question.possede_redirection_aleatoire && (
                  <Interweave
                    attributes={{ className: "message-attente" }}
                    content={t("message_attente")}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </form>
        )}
      </Formik>
    </>
  );
});

export default Question;
