import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Interweave } from "interweave";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import questionnaireStore from "../store-mobx/questionnaireStore";

const MySwal = withReactContent(Swal);

const FooterPopup = () => {
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="sub-title">{t("footer.popup.sous_titre")}</div>
      <div className="texte1">{t("footer.popup.texte1")}</div>
      <div className="texte2">{t("footer.popup.texte2")}</div>
      <Formik
        initialValues={{ commentaire: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.commentaire) {
            errors.commentaire = t("footer.popup.obligatoire");
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          questionnaireStore.signalerAbus(values.commentaire).then((retour) => {
            setSubmitting(false);
            setSubmitted(true);
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              as="textarea"
              rows="5"
              name="commentaire"
              className="commentaire"
              placeholder={t("footer.popup.commentaire")}
            />
            <ErrorMessage name="commentaire" className="message-erreur" component="div" />

            <div className="buttons-container">
              {isSubmitting ? (
                <CircularProgress />
              ) : submitted ? (
                <div className="validation">
                  <FontAwesomeIcon icon={faCheck} className="check" />
                  {t("footer.popup.validation")}
                </div>
              ) : (
                <button type="submit" className="button-save" disabled={isSubmitting}>
                  {t("footer.popup.valider")}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  const showSwal = () => {
    MySwal.fire({
      title: <div className="title-popup">{t("footer.signaler_abus")}</div>,
      customClass: "popup-signaler-abus",
      showConfirmButton: false,
      showCloseButton: true,
      focusConfirm: true,
      width: "60%",
      html: <FooterPopup />,
      confirmButtonText: t("edition.popup.confirm"),
      cancelButtonText: t("edition.popup.cancel"),
    });
  };

  return (
    <footer className="footer">
      <div className="texte">
        <Interweave content={t("footer.texte", { url: "https://www.e-survey.io/" })} />
      </div>
      <div className="liens">
        <a
          className="lien"
          href={t("footer.lien_politique_confidentialite")}
          alt="politique de confidentialité"
          target={"__blank"}
        >
          {t("footer.politique_confidentialite")}
        </a>
        {" - "}
        <div
          className="lien"
          onClick={() => {
            showSwal();
            //fire swal
          }}
        >
          {t("footer.signaler_abus")}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
