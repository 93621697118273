import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

/**
 * Extrait un id de video pour l'utilsier comme lien iframe
 * (Vimeo et Youtube)
 * @param {String} url
 * @returns {String} videoId
 */
export const extractVideoIdFromUrl = (url) => {
  let videoId = null;

  // VIMEO
  if (url.includes("https://vimeo.com/")) {
    videoId = url.replace("https://vimeo.com/", "");
  }

  // Youtube
  else {
    // Recherchez différentes structures d'URL YouTube pour extraire l'ID de la vidéo
    const patterns = [
      /(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/,
      /(?:\/embed\/|\/v\/|\/vi\/|\/u\/\w\/|\/embed\/|\/v\/|\/s\/\d+\/|\/s\/\w+\/)([^?&]+)/,
      /(?:\/watch\?v=|\/\d+\/|\/v\/|\/embed\/|\/u\/\w+\/|\/embed\/|\/v\/|\/s\/\d+\/|\/s\/\w+\/)([^?&]+)/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        videoId = match[1];
        break;
      }
    }
  }
  return videoId;
};

/**
 * Transforme le oembed de CKEditor en iframe avec lien fonctionnel.
 * Fonctionne pour Interweave
 * @param {Object} node
 * @returns IFrame
 */
export const transformMedia = (node) => {
  if (node.tagName === "OEMBED" && node.getAttribute("url")) {
    const url = node.getAttribute("url");
    const videoId = extractVideoIdFromUrl(url);

    if (videoId) {
      if (url.includes("https://youtu.be") || url.includes("https://www.youtube.com")) {
        return (
          <div className="youtube-embed">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube Video"
              allowFullScreen={true}
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; full"
            />
          </div>
        );
      } else if (url.includes("vimeo")) {
        return (
          <div className="vimeo-embed">
            <iframe
              src={`https://player.vimeo.com/video/${videoId}`}
              title="Viemo Video"
              allowFullScreen={true}
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
            />
          </div>
        );
      }
    } else if (url.includes("google.com/maps")) {
      return (
        <div className="google-maps-embed">
          <iframe
            title="Google Maps"
            src={url}
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      );
    } else {
      <div className="embed">
        <iframe
          src={url}
          title="Media embed"
          allowFullScreen={true}
          width="560"
          height="315"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
        />
      </div>;
    }
  }
};

export const addZoomToImages = (containerRef) => {
  if (containerRef.current) {
    const images = containerRef.current.querySelectorAll("img");
    images.forEach((img) => {
      img.classList.add("zoom");

      const zoomElement = document.createElement("div");
      zoomElement.className = "zoom-image-container";
      zoomElement.onclick = () => {
        zoomImage(img.src, img.alt);
      };

      const icon = document.createElement("i");
      icon.className = "zoom-icon";
      ReactDOM.render(<FontAwesomeIcon icon={faSearchPlus} />, icon);
      zoomElement.appendChild(icon);

      let parent = img.parentNode;
      parent.appendChild(zoomElement);
    });
  }
};

export const zoomImage = (url, alt) => {
  return MySwal.fire({
    imageUrl: url,
    imageAlt: alt ?? "Image",
    showCloseButton: true,
    showConfirmButton: false,
    customClass: "popup-image",
  });
};
