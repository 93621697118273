import { observer } from "mobx-react";
import React, { useEffect, createContext, useContext } from "react";
import questionnaireStore from "../store-mobx/questionnaireStore";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = observer(({ socket, children }) => {
  useEffect(() => {
    const { source } = questionnaireStore.repondant;
    if (source && source !== "test") {
      socket.connect();
    }

    return () => {
      socket.disconnect();
    };
  }, [socket, questionnaireStore.repondant.source]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
});
