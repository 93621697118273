//REACT
import React, { Component } from "react";

import questionStore from "../../store-mobx/questionStore";
import { withTranslation } from "react-i18next";
import { Suspense } from "react";
import { scrollToTop } from "./HelpersQuestion";

class HOCNumerique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      options: this.props.question.options,
      errors: questionStore.errors,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(value) {
    this.setState({ value: value });

    questionStore.reponse = value;
    //this.props.setFieldValue(this.props.name, value);
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;

    var customDecimales = "";
    if (this.state.options.decimales && this.state.options.decimales > 0) {
      customDecimales = ".";
      for (let index = 1; index < this.state.options.decimales; index++) {
        customDecimales = customDecimales + "0";
      }
      customDecimales = customDecimales + "1";
    }

    var dynamicWidth = 120;
    if (this.state.options.decimales) {
      dynamicWidth += this.state.options.decimales * 10;
    }

    if (this.state.options.max) {
      dynamicWidth += this.state.options.max.length * 10;
    }

    dynamicWidth += "px";

    return (
      <div className="numerique">
        <input
          id="outlined-textarea"
          label={t("entrez_reponse")}
          placeholder={t("reponse")}
          className="colored input-numerique"
          onChange={(event) => this.handleChange(event.target.value)}
          type="number"
          data-testid="numerique"
          step={customDecimales}
          min={this.state.options.min ?? ""}
          max={this.state.options.max ?? ""}
          style={{ width: dynamicWidth }}
        />
        {this.state.options.libelle ? (
          <div className="libelle" data-testid="libelle">
            {this.state.options.libelle}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    return this.afficheQuestion();
  }
}

const Num = withTranslation()(HOCNumerique);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function Numerique(props) {
  return (
    <Suspense fallback="loading">
      <Num {...props} />
    </Suspense>
  );
}
