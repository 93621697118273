import * as React from "react";

function SvgSmiley11(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.927 122.927">
      <defs>
        <filter
          id="Ellipse_52"
          x="0"
          y="0"
          width="122.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-11" transform="translate(4.175 3.175)">
        <g id="Groupe_1971" data-name="Groupe 1971">
          <rect
            id="Rectangle_434"
            data-name="Rectangle 434"
            width="117"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1970" data-name="Groupe 1970" transform="translate(4.825 2.825)">
            <g id="Groupe_1969" data-name="Groupe 1969">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_52)">
                <ellipse
                  id="Ellipse_52-2"
                  data-name="Ellipse 52"
                  cx="52.464"
                  cy="52.464"
                  rx="52.464"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1972" data-name="Groupe 1972" transform="translate(10.196 8.197)">
          <ellipse
            id="Ellipse_53"
            data-name="Ellipse 53"
            cx="47.092"
            cy="47.092"
            rx="47.092"
            ry="47.092"
            fill="#35933a"
          />
        </g>
        <g id="Groupe_1975" data-name="Groupe 1975" transform="translate(39.189 38.094)">
          <g id="Groupe_1973" data-name="Groupe 1973">
            <path
              id="Tracé_1888"
              data-name="Tracé 1888"
              d="M92.683,102.284h0A4.869,4.869,0,0,1,87.8,97.4V92.383A4.869,4.869,0,0,1,92.683,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.889,4.889,0,0,1,92.683,102.284Z"
              transform="translate(-87.8 -87.5)"
            />
          </g>
          <g id="Groupe_1974" data-name="Groupe 1974" transform="translate(26.504)">
            <path
              id="Tracé_1889"
              data-name="Tracé 1889"
              d="M152.383,102.284h0A4.869,4.869,0,0,1,147.5,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.884,4.883v5.061A4.889,4.889,0,0,1,152.383,102.284Z"
              transform="translate(-147.5 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1976" data-name="Groupe 1976" transform="translate(44.023 62.996)">
          <path
            id="Tracé_1890"
            data-name="Tracé 1890"
            d="M113.794,162.358H110.82a12.107,12.107,0,0,1-12.12-12.12V145.4a1.194,1.194,0,0,1,1.2-1.2H124.8a1.194,1.194,0,0,1,1.2,1.2v4.839A12.241,12.241,0,0,1,113.794,162.358Z"
            transform="translate(-98.7 -144.2)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley11;
