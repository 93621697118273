import { createTheme } from "@mui/material/styles";
import styleStore from "./store-mobx/styleStore";

export const DEBUG_BAR = false;

/*********************************************************************************
 *                                  COULEURS
 ********************************************************************************/

export const colors = {
  primary: "#101a4f",
  mis: "#0394d5",
  light: "#7081dd",
  lightest: "#e1e6ff",
  background: "#F5F7FF",
  secondary: "#5e5e5e",
  secondaryLighter: "#bfbfbf",
  white: "#ffffff",
  black: "#000a3f",
};

export const theme = createTheme({
  palette: {
    text: {
      primary: {
        main: colors.primary,
        light: "red",
      },
    },
    primary: {
      main: colors.primary,
      light: "red",
    },
    mis: {
      main: "#0394d5",
      light: "#cde4f0",
    },
    primaryLighter: {
      main: colors.primary,
    },
    secondary: {
      main: styleStore.mainColor,
    },
  },

  components: {
    MuiRadio: {
      root: {
        "&.Mui-checked": {
          color: "red",
        },
      },
      colorPrimary: {
        main: "red",
      },
      primary: {
        main: "red",
      },
    },
  },

  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
});
