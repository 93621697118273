import * as React from "react";

function SvgSmiley9(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.927 122.927">
      <defs>
        <filter
          id="Ellipse_48"
          x="0"
          y="0"
          width="122.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-09" transform="translate(5.064 3.175)">
        <g id="Groupe_1954" data-name="Groupe 1954">
          <rect
            id="Rectangle_432"
            data-name="Rectangle 432"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1953" data-name="Groupe 1953" transform="translate(3.936 2.825)">
            <g id="Groupe_1952" data-name="Groupe 1952">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_48)">
                <ellipse
                  id="Ellipse_48-2"
                  data-name="Ellipse 48"
                  cx="52.464"
                  cy="52.464"
                  rx="52.464"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1955" data-name="Groupe 1955" transform="translate(9.308 8.197)">
          <ellipse
            id="Ellipse_49"
            data-name="Ellipse 49"
            cx="47.092"
            cy="47.092"
            rx="47.092"
            ry="47.092"
            fill="#96c354"
          />
        </g>
        <g id="Groupe_1958" data-name="Groupe 1958" transform="translate(38.306 38.094)">
          <g id="Groupe_1956" data-name="Groupe 1956">
            <path
              id="Tracé_1882"
              data-name="Tracé 1882"
              d="M92.383,102.284h0A4.869,4.869,0,0,1,87.5,97.4V92.383A4.869,4.869,0,0,1,92.383,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,92.383,102.284Z"
              transform="translate(-87.5 -87.5)"
            />
          </g>
          <g id="Groupe_1957" data-name="Groupe 1957" transform="translate(26.504)">
            <path
              id="Tracé_1883"
              data-name="Tracé 1883"
              d="M152.083,102.284h0A4.869,4.869,0,0,1,147.2,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,152.083,102.284Z"
              transform="translate(-147.2 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1959" data-name="Groupe 1959" transform="translate(39.456 68.267)">
          <path
            id="Tracé_1884"
            data-name="Tracé 1884"
            d="M107.059,167.059c-.622,0-1.287-.044-1.953-.089a23.074,23.074,0,0,1-14.34-6.792,2.229,2.229,0,0,1,3.152-3.152,19.041,19.041,0,0,0,11.632,5.505c8.391.755,14.162-4.661,15.228-5.771a2.229,2.229,0,1,1,3.2,3.108C122.642,161.11,116.338,167.059,107.059,167.059Z"
            transform="translate(-90.1 -156.087)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley9;
