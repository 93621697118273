//REACT
import React, { useState } from "react";
import questionStore from "../../store-mobx/questionStore";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Telephone = (props) => {
  const [phone, setPhone] = useState();

  const handleChange = (phone) => {
    const reponseDonnee = { id: props.champ.id, valeur: phone };

    var indexReponsePresente = questionStore.reponse["champs"].findIndex(
      (x) => x.id === reponseDonnee.id
    );
    if (questionStore.reponse["champs"] && indexReponsePresente !== -1) {
      questionStore.reponse["champs"][indexReponsePresente] = reponseDonnee;
    } else {
      questionStore.reponse["champs"].push(reponseDonnee);
    }
    setPhone(phone);
  };

  //nom de classe dynamique pour afficher le champs en erreur etc
  var className = "input-phone";
  // if (props.champ.taille) {
  //   className = className + " " + props.champ.taille;
  // }
  if (props.errorsDisplay.length > 0) {
    className = className + " error";
  }

  return (
    <div className={props.className}>
      <PhoneInput
        enableSearch={true}
        value={phone}
        country={
          !window.localStorage.i18nextLng
            ? "gb"
            : window.localStorage.i18nextLng === "en"
            ? "gb"
            : window.localStorage.i18nextLng
        }
        inputClass={className}
        onChange={(phone) => handleChange(phone)}
      />
      {props.errorsDisplay.map((error, index) => {
        return (
          <div key={index} className="error-message">
            {error}
          </div>
        );
      })}
    </div>
  );
};

export default Telephone;
