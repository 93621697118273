//REACT
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Radio = (props) => {
  const spring = {
    type: "spring",
    duration: 0.2,
    bounce: 0.25,
  };
  return (
    <motion.div
      whileTap={{ scale: 0.8 }}
      className={props.isChecked ? "cercle-ext checked" : "cercle-ext"}
      onClick={(e) => props.onClick()}
    >
      <AnimatePresence>
        {props.isChecked && (
          <motion.div
            className="cercle-int"
            key={props.clef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: [0.5, 0.7, 1, 1] }}
            exit={{ opacity: 0, scale: [1, 1, 0.7, 0.1] }}
            transition={spring}
          ></motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Radio;
