import questionnaireStore from "../../store-mobx/questionnaireStore";
import { isMobile } from "react-device-detect";

/**
 *
 * Fonction qui retourne le numero d'une question en fonction de l'option numerotation_auto du questionnaire
 *
 * @param {array} question
 */
export const getNumeroQuestion = (question, t) => {
  var types = ["Intro", "ConclusionBon", "ConclusionMauvais"];

  //SI NUMEROTATION AUTO
  if (
    questionnaireStore.questionnaire &&
    questionnaireStore.questionnaire.options &&
    questionnaireStore.questionnaire.options.numerotation_automatique === true
  ) {
    if (types.includes(question.type_question)) {
      return t("numerotation." + question.type_question.toLowerCase());
    } else {
      if (question.type_question === "Transition") {
        return (
          t("numerotation." + question.type_question.toLowerCase()) + "" + question.numero_auto
        );
      } else {
        return "Q" + question.numero_auto;
      }
    }
  }
  //SI NUMEROTATION PERSO
  else if (
    questionnaireStore.questionnaire &&
    questionnaireStore.questionnaire.options &&
    questionnaireStore.questionnaire.options.numerotation_automatique === false
  ) {
    if (types.includes(question.type_question)) {
      return t("numerotation." + question.type_question.toLowerCase());
    } else if (
      question.numero_question &&
      question.numero_question !== "" &&
      question.numero_question !== "Qundefined" && //TO FIX
      question.numero_question !== undefined
    ) {
      //on appelle automatiquement la trad liée
      return question.numero_question.toUpperCase();
    } else {
      return "Q" + question.numero_auto;
    }
  }
  //SI AUCUNE DES DEUX
  else {
    return "?";
  }
};

/**
 *
 * Fonction qui retourne le numero d'une question pour le contenu
 *
 * @param {array} question
 */
export const getNumeroQuestionContenu = (question, t) => {
  if (
    questionnaireStore.questionnaire.options &&
    questionnaireStore.questionnaire.options.numerotation_automatique === true
  ) {
    if (question.numero_question_genere) {
      return "Q" + question.numero_question_genere;
    } else {
      const numero_question = questionnaireStore.repondant.numero_question;
      return "Q" + numero_question;
    }
  } else {
    if (question.numero_question !== "") {
      return question.numero_question;
    } else {
      const numero_question = questionnaireStore.repondant.numero_question;
      return "Q" + numero_question;
    }
  }
};

/**
 * Retourne le nom du site vers lequel redirige une étude MIS
 *
 * @param {int} provenance
 * @param {object} t
 */
export const getNomSite = (provenance, t) => {
  var nom = "";

  var listeSite = [
    { provenance: "1", nom: "Pipeul" },
    { provenance: "2", nom: "Creatests" },
    { provenance: "3", nom: "Réunions de consommateurs" },
    { provenance: "4", nom: "Devenir Client Mystère" },
    { provenance: "5", nom: "Tester des produits" },
    { provenance: "6", nom: "Try New Perfumes" },
    { provenance: "8", nom: "Opinions du Web" },
    { provenance: "9", nom: "AvisPanel" },
    { provenance: "11", nom: "Surveyfriends" },
    { provenance: "12", nom: "Marketest" },
    { provenance: "13", nom: "PaidFocusGroup" },
    { provenance: "14", nom: "Mystery Day Out" },
    { provenance: "15", nom: "PaidProductTesting" },
    { provenance: "19", nom: "PanelOpinion" },
    { provenance: "25", nom: "Derproduktester" },
    { provenance: "26", nom: "Parfumtest-aktion" },
    { provenance: "29", nom: "Paneleuropa.de" },
    { provenance: "31", nom: "Tuopinionvale" },
    { provenance: "32", nom: "Testdemercado" },
    { provenance: "38", nom: "trynewperfumes.cn" },
    { provenance: "39", nom: "Paneleuropa.es" },
    { provenance: "40", nom: "Avis d'Artisans" },
    { provenance: "41", nom: "Yoopinion" },
    { provenance: "42", nom: "Paneleuropa.eu" },
    { provenance: "43", nom: "Paneleuropa.it" },
    { provenance: "44", nom: "Paneleuropa.dk" },
    { provenance: "45", nom: "Paneleuropa.nl" },
    { provenance: "46", nom: "Paneleuropa.be" },
    { provenance: "47", nom: "Free-Cosmetic-Testing" },
    { provenance: "48", nom: "Sondages Minute" },
    { provenance: "55", nom: "Testa il Prodotto" },
  ];

  var infos = listeSite.find((site) => site.provenance === provenance.toString());

  if (infos) {
    nom = infos.nom;
  }

  return nom;
};

/**
 *
 * Scroll vers la question cliquée dans le contenu de droite
 *
 */
export const scrollToTop = () => {
  if (isMobile) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    var id = "contenu";
    var element = document.getElementById(id);
    element.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

/**
 * Retire les balises HTML
 * @param {string} tt
 * @returns {string}
 */
export const sanitizeHtml = (tt) => {
  if (tt) {
    var regex = /(&nbsp;|<([^>]+)>)/gi;
    let body = tt.toString();
    return body.replace(regex, "");
  }
  return "";
};
