import { toJS, makeAutoObservable } from "mobx";
import QuestionnaireService from "../services/QuestionnaireService";
import QuestionService from "../services/QuestionService";
import questionnaireStore from "./questionnaireStore";
import styleStore from "./styleStore";
import history from "../history.js";
import i18n from "../i18n";

class QuestionStore {
  constructor() {
    makeAutoObservable(this);
    this.QuestionnaireService = new QuestionnaireService();
    this.QuestionService = new QuestionService();
  }

  /**
   * OBSERVABLES
   */

  //data
  question = null;
  questionCiblee = null; //id de la question à prévisualiser
  questionnaire_id = null;
  section_id = null;
  errors = [];
  erreurServeur = [];
  reponse = [];
  reponse_autre = [];
  aucun = false;
  progression = "0";

  submitHidden = false;
  redirectToMIS = false;

  //loaders
  questionIsLoading = false;
  questionBandeauIsLoading = false;
  pageIsLoading = false;

  /**
   * ACTIONS
   */

  fetchQuestion(idQuestionnaire, token_repondant) {
    this.pageIsLoading = true;
    this.QuestionnaireService.getQuestion(
      idQuestionnaire,
      this.questionCiblee,
      token_repondant
    ).then((resultat) => {
      if (resultat.erreur === true) {
        //gestion des erreurs communes
        if (resultat.code_erreur === "utilisateur_introuvable") {
          //history.push("/questionnaire-termine");
          history.push("/error");
        } else if (resultat.code_erreur === "questionnaire_ferme") {
          // history.push("/questionnaire-ferme");
          questionnaireStore.erreurs.push(resultat);
          // history.push("/error");
        } else {
          questionnaireStore.erreurs.push(resultat);
        }
      } else if (resultat.question) {
        if (resultat.question.type_question === "Coordonnees") {
          //Initialisation spéciale pour gérer tous les champs
          this.reponse = { champs: [], consentements: [] };
        } else if (
          resultat.question.type_question === "Transition" &&
          resultat.question.type_consentement
        ) {
          this.reponse = { consentements: [] };
        }

        //on recup les infos du repondant
        if (resultat.repondant) {
          questionnaireStore.repondant = resultat.repondant;
        } else if (resultat.erreur === true && resultat.provenance) {
          //cas pour les retours en erreur où on a pas le répondant
          questionnaireStore.repondant = { infos_paneliste: {} };
          questionnaireStore.repondant.infos_paneliste.provenance = resultat.provenance;
        }

        if (resultat.questionnaire && resultat.questionnaire.langue) {
          i18n.changeLanguage(resultat.questionnaire.langue);
          document.documentElement.lang = resultat.questionnaire.langue;
        }

        //progression et erreurs
        this.progression = resultat.progressbar;

        questionnaireStore.erreurs = [];

        //recup des datas
        this.question = resultat.question;
        this.section_id = resultat.question.section_id;
        questionnaireStore.questionnaire = resultat.questionnaire;

        // style
        let theme =
          resultat.repondant?.infos_paneliste?.theme ?? resultat.questionnaire.options.theme;
        styleStore.setTheme(theme, resultat.questionnaire.options.theme_custom);
        styleStore.themeLoaded = true;
      }

      //dans tous les cas on repasse le loader à false
      this.pageIsLoading = false;
    });
  }

  repondreQuestion(
    reponse = [],
    idQuestionnaire = null,
    idSection = null,
    idQuestion = null,
    token_rep,
    chrono
  ) {
    let data = {
      questionnaire_id: idQuestionnaire,
      section_id: idSection,
      question_id: idQuestion,
      reponse: reponse.reponse,
      reponse_autre: reponse.reponse_autre,
      token_repondant: token_rep,
      url: window.location.href,
      chrono,
    };

    if (this.question.modalites?.length === 0) {
      data.cheatValidation = true;
    }

    return this.QuestionService.repondreQuestion(data).then((resultat) => {
      if (resultat.erreur === false) {
        if (resultat.question && resultat.question.type_question === "Coordonnees") {
          //Initialisation spéciale pour gérer tous les champs
          this.reponse = { champs: [], consentements: [] };
        } else if (
          resultat.question?.type_question === "Transition" &&
          resultat.question.type_consentement
        ) {
          this.reponse = { consentements: [] };
        } else {
          this.reponse = [];
        }

        if (resultat.repondant) {
          questionnaireStore.repondant = toJS(resultat.repondant);
        }

        this.question = resultat.question;
        this.section_id = resultat.question.section_id;
        this.erreurServeur = [];

        this.progression = resultat.progressbar;

        //CHECK POUR VIRER LA CONCLUSION POUR LES SCREENERS
        if (
          questionnaireStore.questionnaire.type_etude_mis &&
          (questionnaireStore.questionnaire.type_etude_mis === "2" ||
            questionnaireStore.questionnaire.type_etude_mis === "3") &&
          (resultat.question.type_question === "ConclusionBon" ||
            resultat.question.type_question === "ConclusionMauvais")
        ) {
          this.redirectToMIS = true;
        }
      } else {
        this.erreurServeur = [];
        this.errors = resultat.code_erreur;
        this.erreurServeur.push(resultat.code_erreur);
      }
      return Promise.resolve(resultat);
    });
  }

  viderReponses(tokenRepondant) {
    return this.QuestionService.viderReponses(this.questionnaire_id, tokenRepondant).then(
      (result) => {
        questionnaireStore.erreurs = [];
        this.question = result.question;
        this.section_id = result.question.section_id;
        questionnaireStore.questionnaire = result.questionnaire;
        this.progression = result.progressbar;
      }
    );
  }

  getQuestionBandeau(idQuestion, tokenRepondant) {
    this.questionBandeauIsLoading = true;
    return this.QuestionService.getQuestionBandeau(
      this.questionnaire_id,
      idQuestion,
      tokenRepondant
    ).then((resultat) => {
      if (resultat.question) {
        this.erreurServeur = [];
        this.questionBandeauIsLoading = false;
        if (resultat.question && resultat.question.type_question === "Coordonnees") {
          //Initialisation spéciale pour gérer tous les champs
          this.reponse = { champs: [], consentements: [] };
        } else if (
          resultat.question?.type_question === "Transition" &&
          resultat.question.type_consentement
        ) {
          this.reponse = { consentements: [] };
        } else {
          this.reponse = [];
        }
        this.question = resultat.question;
        this.section_id = resultat.question.section_id;
        this.progression = resultat.progressbar;
      }
      this.questionBandeauIsLoading = false;

      return Promise.resolve(resultat);
    });
  }

  getCodePostaux(pays, codePostal) {
    return this.QuestionService.getCodePostaux(pays, codePostal).then((resultat) => {
      if (resultat.erreur === false) {
        return resultat.code_postal;
      } else {
        return [];
      }
    });
  }

  /**
   * COMPUTED DATA
   */

  checkIfAucun(modaValue) {
    var isAucun = false;
    this.question.modalites.forEach((moda) => {
      if (moda.value === modaValue) {
        isAucun = true;
      }
    });
    return isAucun;
  }
}

const store = new QuestionStore();
export default store;
