//REACT
import React, { Component, Fragment } from "react";

//MATERIAL UI
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { observer } from "mobx-react";
import questionStore from "../../store-mobx/questionStore";
import { withTranslation } from "react-i18next";
import { Suspense } from "react";
import { scrollToTop } from "./HelpersQuestion";
import { isMobile } from "react-device-detect";
import { Interweave } from "interweave";

@observer
class HOCChoixUnique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "",
      autres: [],
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(selected) {
    this.setState({ selected: selected, autres: [] });
    questionStore.reponse = selected;
    //this.props.setFieldValue(this.props.name, selected);
  }

  handleChangePrecision(event, modalite) {
    var newSelected = this.state.selected;
    var newAutres = this.state.autres;

    if (event.target.value !== "") {
      newAutres = [];
      newAutres.push({
        modalite_id: modalite.id,
        precision: event.target.value,
      });

      if (newSelected !== modalite.id) {
        newSelected = modalite.id;
      }
    } else {
      newAutres = [];

      if (newSelected === modalite.id) {
        newSelected = "";
      }
    }

    this.setState({ selected: newSelected, autres: newAutres });
    questionStore.reponse = newSelected;
    questionStore.reponse_autre = newAutres;
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;

    var blocModalites;

    //On définit le nombre de colonnes
    var nb_colonnes = 1;
    if (
      this.props.question.options &&
      this.props.question.options.nb_colonnes &&
      this.props.question.options.nb_colonnes !== ""
    ) {
      nb_colonnes = this.props.question.options.nb_colonnes;
    }

    //Cas ou l'affichaeg est classique
    if (nb_colonnes === 1 || isMobile) {
      if (this.props.question.modalites.length > 0) {
        blocModalites = this.props.question.modalites.map((modalite) => {
          var valuePrecision = "";
          this.state.autres.forEach((autre) => {
            if (autre.modalite_id === modalite.id) {
              valuePrecision = autre.precision;
            }
          });
          var label = modalite.label;
          if (modalite.precision) {
            if (modalite.label.substr(-1, 1) === ":") {
            } else {
              label = label + " : ";
            }
          }
          return (
            <div key={modalite.value} className="modalite-container">
              <FormControlLabel
                value={modalite.id}
                className="modalite"
                control={<Radio color="secondary" />}
                label={
                  <div className="precisez-container">
                    <div
                      className={
                        this.props.errors &&
                        this.props.errors[modalite.id] &&
                        this.props.errors[modalite.id]["precisez"]
                          ? "label error"
                          : "label"
                      }
                    >
                      <Interweave content={label} />
                    </div>
                    {modalite.precision ? (
                      <input
                        label="précisez"
                        variant="outlined"
                        type="text"
                        className=" precisez colored"
                        placeholder={t("entrez_precision")}
                        name="precision"
                        value={valuePrecision}
                        onChange={(event) => this.handleChangePrecision(event, modalite)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                }
                onChange={(event) => this.handleChange(event.target.value)}
                checked={
                  modalite.id === this.state.selected && this.state.selected !== "" ? true : false
                }
              />
            </div>
          );
        });
      } else {
        blocModalites = (
          <p className="message-moda-vide">
            {t("non_concerne_question")}
            <br />
            {t("passer_question_suivante")}
          </p>
        );
      }
    } else {
      //Cas où l'on va avoir plusieurs colonnes
      var nbModalites = this.props.question.modalites.length;
      var division = nbModalites / nb_colonnes;
      var reste = nbModalites % nb_colonnes;
      var modalitesParColonnes = [];

      for (let index = 1; index <= nb_colonnes; index++) {
        //on arrondi en dessous
        modalitesParColonnes[index] = Math.floor(division);
        //on ajoute une modalité à la colonne si le reste est positif
        if (reste > 0) {
          modalitesParColonnes[index] = modalitesParColonnes[index] + 1;
          reste--;
        }
      }
      modalitesParColonnes = modalitesParColonnes.filter(function (el) {
        return el != null;
      });

      //Compteur incrémentés pour afficher les bons items dans les bonnes colonnes
      var compteurModalitesMin = 0;
      var compteurModalitesMax = 0;

      //taille automatique maximale des colonnes
      var widthCol = 100 / nb_colonnes;

      blocModalites = (
        <>
          {modalitesParColonnes.map((nb_moda, indexCol) => {
            //déplacement du compteur max
            compteurModalitesMax = compteurModalitesMax + nb_moda;
            const moda = (
              <div
                className="colonne-modalites"
                key={indexCol}
                style={{ maxWidth: `${widthCol}%`, paddingRight: "30px" }}
              >
                {this.props.question.modalites.length > 0 ? (
                  <>
                    {this.props.question.modalites.map((modalite, indexModa) => {
                      if (
                        indexModa + 1 > compteurModalitesMin &&
                        indexModa + 1 <= compteurModalitesMax
                      ) {
                        var valuePrecision = "";
                        this.state.autres.forEach((autre) => {
                          if (autre.modalite_id === modalite.id) {
                            valuePrecision = autre.precision;
                          }
                        });
                        var label = modalite.label;
                        if (modalite.precision) {
                          if (modalite.label.substr(-1, 1) === ":") {
                          } else {
                            label = label + " : ";
                          }
                        }
                        return (
                          <div key={modalite.value} className="modalite-container">
                            {/* <InputRadio /> */}
                            <FormControlLabel
                              value={modalite.id}
                              className="modalite"
                              control={<Radio color="secondary" />}
                              label={
                                <div className="precisez-container">
                                  <div
                                    className={
                                      this.props.errors &&
                                      this.props.errors[modalite.id] &&
                                      this.props.errors[modalite.id]["precisez"]
                                        ? "label error"
                                        : "label"
                                    }
                                  >
                                    <Interweave content={label} />
                                  </div>
                                  {modalite.precision ? (
                                    <input
                                      label="précisez"
                                      variant="outlined"
                                      type="text"
                                      className=" precisez colored"
                                      placeholder={t("entrez_precision")}
                                      name="precision"
                                      value={valuePrecision}
                                      onChange={(event) =>
                                        this.handleChangePrecision(event, modalite)
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              }
                              onChange={(event) => this.handleChange(event.target.value)}
                              checked={
                                modalite.id === this.state.selected && this.state.selected !== ""
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                ) : (
                  <p className="message-moda-vide">
                    {t("non_concerne_question")}
                    <br />
                    {t("passer_question_suivante")}
                  </p>
                )}
              </div>
            );
            //déplacement du compteur min
            compteurModalitesMin = compteurModalitesMin + nb_moda;
            return moda;
          })}
        </>
      );
    }

    return (
      <>
        <RadioGroup
          className={
            nb_colonnes > 1 && !isMobile ? "conteneur-modalites row" : "conteneur-modalites"
          }
        >
          {blocModalites}
        </RadioGroup>
      </>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

const Mult = withTranslation()(HOCChoixUnique);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function ChoixUnique(props) {
  return (
    <Suspense fallback="loading">
      <Mult {...props} />
    </Suspense>
  );
}
