//REACT
import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//MATERIAL UI
import { observer } from "mobx-react";
import questionStore from "../../store-mobx/questionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel, Radio } from "@mui/material";
import { scrollToTop } from "./HelpersQuestion";
import { isMobile } from "react-device-detect";
import { Interweave } from "interweave";

const MySwal = withReactContent(Swal);
@observer
class ImageChoixUnique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indexHover: null,
      hoverLoupe: false,
      selected: [],
      autres: [],
      imagesChargees: [],
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(event, selected) {
    this.setState({ selected: selected });
    questionStore.reponse = selected;
  }

  handleChangePrecision(event, modalite) {
    var newSelected = this.state.selected;
    var newAutres = this.state.autres;

    if (event.target.value !== "") {
      newAutres = [];
      newAutres.push({
        valueModalite: modalite.id,
        precision: event.target.value,
      });

      if (newSelected !== modalite.id) {
        newSelected = modalite.id;
      }
    } else {
      newAutres = [];

      if (newSelected === modalite.id) {
        newSelected = "";
      }
    }

    this.setState({ selected: newSelected, autres: newAutres });
    questionStore.reponse = newSelected;
    questionStore.reponse_autre = newAutres;
  }

  //Fonction appelée pour zoomer sur une image
  zoomImage(url) {
    return MySwal.fire({
      imageUrl: url,
      imageAlt: "Image",
      showCloseButton: true,
      showConfirmButton: false,
      customClass: "popup-image",
    });
  }

  //Affichage de la question
  afficheQuestion() {
    const blocImages = this.props.question.images.map((image, index) => {
      if (!image.sans_image) {
        return (
          <div
            key={image.value}
            className={
              this.state.selected === image.id ? "image-container selected" : "image-container "
            }
            onPointerEnter={() => this.setState({ indexHover: index })}
            onPointerLeave={() => this.setState({ indexHover: null })}
            onClick={(event) => this.handleChange(event, image.id)}
          >
            <div className="wrapper-image">
              <img
                alt="img"
                onLoad={() => console.log("onLoad")}
                src={
                  process.env.REACT_APP_URL_WEB +
                  "/questionnaires/" +
                  questionStore.questionnaire_id +
                  "/questions/" +
                  questionStore.question.id +
                  "/images/" +
                  image.id
                }
                className="image"
              />
            </div>

            {this.props.question.options.affichage_label_image === true ? (
              <div className="image-label">
                <Interweave content={image.label} />
              </div>
            ) : (
              ""
            )}
            {isMobile && (
              <div
                className="zoom-image-container"
                onPointerEnter={() => this.setState({ hoverLoupe: true })}
                onPointerLeave={() => this.setState({ hoverLoupe: false })}
                onClick={() =>
                  this.zoomImage(
                    process.env.REACT_APP_URL_WEB +
                      "/questionnaires/" +
                      questionStore.questionnaire_id +
                      "/questions/" +
                      questionStore.question.id +
                      "/images/" +
                      image.id
                  )
                }
              >
                <FontAwesomeIcon icon={faSearchPlus} className={"zoom-icon"} />
              </div>
            )}
            {!isMobile && this.state.indexHover !== null && this.state.indexHover === index ? (
              <div
                className="zoom-image-container"
                onPointerEnter={() => this.setState({ hoverLoupe: true })}
                onPointerLeave={() => this.setState({ hoverLoupe: false })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.zoomImage(
                    process.env.REACT_APP_URL_WEB +
                      "/questionnaires/" +
                      questionStore.questionnaire_id +
                      "/questions/" +
                      questionStore.question.id +
                      "/images/" +
                      image.id
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faSearchPlus}
                  className={this.state.hoverLoupe ? "zoom-icon hover" : "zoom-icon"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return null;
      }
    });

    const blocModas = this.props.question.images.map((image, index) => {
      if (image.sans_image) {
        return (
          <div key={image.value} className="modalite-container">
            <FormControlLabel
              value={image.id}
              className="image"
              control={<Radio color="secondary" />}
              label={<Interweave content={image.label} />}
              onChange={(event) => {
                this.setState({ selected: event.target.value });
                questionStore.reponse = event.target.value;
              }}
              checked={
                image.id === this.state.selected && this.state.selected !== "" ? true : false
              }
            />

            {/* {image.precision ? (
              <div>
                <input
                  label="précisez"
                  variant="outlined"
                  type="text"
                  className="colored"
                  placeholder={t("entrez_precision")}
                  name="precision"
                  value={valuePrecision}
                  onChange={(event) =>
                    this.handleChangePrecision(event, image)
                  }
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="conteneur-items">
        <div className="image-choix">{blocImages}</div>
        <div className="modas-choix">{blocModas}</div>
      </div>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

export default ImageChoixUnique;
