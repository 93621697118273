//REACT
import React, { Component, Fragment } from "react";
import questionStore from "../../store-mobx/questionStore";
import { isMobile } from "react-device-detect";
import MatricielleUniqueMobile from "./MatricielleUniqueMobile";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

class MatricielleUnique extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reponses: [],
      currentHover: {},
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleClick(ligne, colonne) {
    var newReponses = this.state.reponses;

    var reponseClick = { ligne_id: ligne.id, colonne_id: colonne.id };
    var indexObjet = 0;
    var included = false;

    //REGARDE SI LA REPONSE EST DEJA PRESENTE
    newReponses.forEach((rep, index) => {
      if (rep.ligne_id === ligne.id) {
        included = true;
        indexObjet = index;
      }
    });

    //AJOUT OU SUPPRESSION DU TABLEAU
    if (included) {
      newReponses.splice(indexObjet, 1);
      newReponses.push(reponseClick);
    } else {
      newReponses.push(reponseClick);
    }

    //MAJ
    this.setState({
      reponses: newReponses,
    });
    //this.props.setFieldValue(this.props.name, newReponses);
    questionStore.reponse = newReponses;
  }

  //check si la ligne fait partie des réponses
  verifLigne = (ligne) => {
    var nbrep = 0;
    this.state.reponses.forEach((rep) => {
      if (ligne.id === rep.ligne_id) {
        nbrep++;
      }
    });
    if (nbrep > 0) {
      return true;
    } else {
      return false;
    }
  };

  handleHover = (ligneValue, colonneValue, state) => {
    if (state === "show") {
      this.setState({
        currentHover: { ligneValue: ligneValue, colonneValue: colonneValue },
      });
    } else {
      this.setState({
        currentHover: {},
      });
    }
  };

  //Affichage de la question
  afficheQuestion() {
    const { lignes, colonnes } = this.props.question;

    return (
      <Fragment>
        {isMobile ? (
          <MatricielleUniqueMobile lignes={lignes} colonnes={colonnes} />
        ) : (
          <div className="table-container">
            <table className="matricielle unique">
              <thead>
                <tr>
                  <th className="vide"></th>
                  {colonnes.map((col, index) => {
                    return (
                      <th
                        key={index}
                        className={
                          this.state.currentHover.colonneValue &&
                          index + 1 === this.state.currentHover.colonneValue
                            ? "hover-light"
                            : ""
                        }
                      >
                        <Interweave content={col.label} />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {lignes.map((ligne, indexLigne) => {
                  var classLigne = "ligne-label ";

                  if (
                    (Object.values(this.props.errors).length > 0 &&
                      this.verifLigne(ligne) === false) ||
                    (this.props.errors && this.props.errors[ligne.id])
                  ) {
                    classLigne = "ligne-label error";
                  }

                  return (
                    <tr key={indexLigne}>
                      <td
                        className={
                          this.state.currentHover.ligneValue &&
                          indexLigne + 1 === this.state.currentHover.ligneValue
                            ? classLigne + " hover-light"
                            : classLigne
                        }
                      >
                        <Interweave content={ligne.label} />
                      </td>
                      {colonnes.map((col, indexCol) => {
                        return (
                          <td
                            key={indexCol}
                            className={
                              (this.state.currentHover.ligneValue &&
                                indexLigne + 1 === this.state.currentHover.ligneValue &&
                                this.state.currentHover.colonneValue &&
                                indexCol + 1 <= this.state.currentHover.colonneValue) ||
                              (this.state.currentHover.colonneValue &&
                                indexCol + 1 === this.state.currentHover.colonneValue &&
                                this.state.currentHover.ligneValue &&
                                indexLigne + 1 <= this.state.currentHover.ligneValue)
                                ? "choix-reponse hover-light"
                                : "choix-reponse"
                            }
                            onPointerEnter={() =>
                              this.handleHover(indexLigne + 1, indexCol + 1, "show")
                            }
                            onPointerLeave={() =>
                              this.handleHover(indexLigne + 1, indexCol + 1, "hide")
                            }
                            onClick={() => this.handleClick(ligne, col)}
                          >
                            <input
                              type="radio"
                              name={"reponse_" + ligne.id}
                              data-testid={"reponse_" + ligne.id}
                              onChange={() => ""}
                              checked={
                                this.state.reponses.filter(
                                  (rep) => rep.ligne_id === ligne.id && rep.colonne_id === col.id
                                ).length > 0
                                  ? true
                                  : false
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

export default MatricielleUnique;
