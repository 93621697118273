import * as React from "react";

function SvgEtoile(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.776 86.71" fill="#ccc">
      <path
        id="_10"
        data-name="10"
        d="M49.016,2.636,57.972,30.2H86.955A3.815,3.815,0,0,1,89.2,37.1L65.75,54.135,74.705,81.7a3.815,3.815,0,0,1-5.87,4.265L45.388,68.929,21.942,85.964a3.815,3.815,0,0,1-5.87-4.265l8.956-27.564L1.58,37.1a3.815,3.815,0,0,1,2.242-6.9H32.8L41.76,2.636a3.815,3.815,0,0,1,7.256,0"
        transform="translate(0 0)"
      />
    </svg>
  );
}

export default SvgEtoile;
