import * as React from "react";

function SvgSmiley6(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.927 122.927">
      <defs>
        <filter
          id="Ellipse_42"
          x="0"
          y="0"
          width="121.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-06" transform="translate(5.656 3.175)">
        <g id="Groupe_1927" data-name="Groupe 1927">
          <rect
            id="Rectangle_429"
            data-name="Rectangle 429"
            width="115"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1926" data-name="Groupe 1926" transform="translate(3.343 2.825)">
            <g id="Groupe_1925" data-name="Groupe 1925">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_42)">
                <ellipse
                  id="Ellipse_42-2"
                  data-name="Ellipse 42"
                  cx="51.964"
                  cy="52.464"
                  rx="51.964"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1928" data-name="Groupe 1928" transform="translate(8.715 8.197)">
          <ellipse
            id="Ellipse_43"
            data-name="Ellipse 43"
            cx="46.592"
            cy="47.092"
            rx="46.592"
            ry="47.092"
            fill="#efe7ad"
          />
        </g>
        <g id="Groupe_1931" data-name="Groupe 1931" transform="translate(37.854 38.094)">
          <g id="Groupe_1929" data-name="Groupe 1929">
            <path
              id="Tracé_1873"
              data-name="Tracé 1873"
              d="M93.783,102.284h0A4.869,4.869,0,0,1,88.9,97.4V92.383A4.869,4.869,0,0,1,93.783,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,93.783,102.284Z"
              transform="translate(-88.9 -87.5)"
            />
          </g>
          <g id="Groupe_1930" data-name="Groupe 1930" transform="translate(26.504)">
            <path
              id="Tracé_1874"
              data-name="Tracé 1874"
              d="M153.483,102.284h0A4.869,4.869,0,0,1,148.6,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,153.483,102.284Z"
              transform="translate(-148.6 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1933" data-name="Groupe 1933" transform="translate(33.64 70.118)">
          <g id="Groupe_1932" data-name="Groupe 1932">
            <path
              id="Tracé_1875"
              data-name="Tracé 1875"
              d="M122.153,164.2H81.4a2,2,0,1,1,0-4h40.755a2,2,0,1,1,0,4Z"
              transform="translate(-79.4 -160.2)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley6;
