import { reaction, makeAutoObservable } from "mobx";
import { isMobile } from "react-device-detect";
import QuestionnaireService from "../services/QuestionnaireService";
import QuestionService from "../services/QuestionService";
import questionStore from "./questionStore";

class QuestionnaireStore {
  constructor() {
    makeAutoObservable(this);

    this.QuestionnaireService = new QuestionnaireService();
    this.QuestionService = new QuestionService();

    reaction(
      () => this.listeSections,
      (sections) => {
        if (sections) {
          this.listeSections = this.resetNumerotationAuto();
        } else {
          // console.log("pas de sections");
        }
      }
    );
  }

  /**
   * OBSERVABLES
   */

  //DATA QUESTIONNAIRE
  questionnaire = {};
  questionnaire_short_id = "";
  listeSections = null;
  listeQuestions = [];

  //CONNEXION
  token = ""; //bearer token
  token_repondant = "";
  repondant = {};
  type_lien = sessionStorage.getItem("type");
  clef = "";
  switch = {};
  erreurs = [];

  //LOADERS & STATE
  loading = false;
  isClearing = false;
  bandeauIsOpen = true;
  bandeauIsLoaded = false;

  /**
   * ACTIONS
   */

  //Signaler un abus
  signalerAbus = (commentaire) => {
    return this.QuestionnaireService.signalerAbus(
      this.token_repondant,
      questionStore.questionnaire_id,
      commentaire
    ).then((retour) => {
      return retour;
    });
  };

  //MAJ NUMERO AUTO DE QUESTION
  resetNumerotationAuto = () => {
    var numero_incr = 1;
    var numero_transition = 1;
    this.listeSections.forEach((section) => {
      if (section.supprimable === true) {
        section.questions.forEach((question) => {
          if (question.type_question !== "Transition") {
            question.numero_auto = numero_incr;
            numero_incr++;
          } else {
            question.numero_auto = numero_transition;
            numero_transition++;
          }
        });
      }
    });

    return this.listeSections;
  };

  switchQuestionnaire(params, params_externe = null) {
    console.log("switchQuestionnaire");
    localStorage.clear();
    sessionStorage.setItem("type", params.type);
    localStorage.setItem("clef_partage", this.clef);
    localStorage.setItem("params_externe", JSON.stringify(params_externe));

    var body = {};
    var type_lien = "";
    if (this.type_lien === "test") {
      if (!isMobile) {
        this.bandeauIsOpen = true;
      }

      body = {
        clef_test: this.clef,
      };
      type_lien = "tester";
    } else if (this.type_lien === "share") {
      type_lien = "partager";
      //Paramètres pour le panel externe
      if (params_externe) {
        body = {
          clef_partage: this.clef,
          params_externe: [],
        };
        var liste_param = [];
        for (const param in params_externe) {
          liste_param.push({ [param]: params_externe[param] });
        }
        body.params_externe = liste_param;
      } else {
        body = { clef_partage: this.clef };
      }
    }

    return this.QuestionnaireService.switchQuestionnaire(type_lien, body);
  }

  listeQuestion() {
    return this.QuestionnaireService.listeQuestion(
      this.token_repondant,
      questionStore.questionnaire_id
    ).then((retour) => {
      return retour;
    });
  }
}

const store = new QuestionnaireStore();
export default store;
