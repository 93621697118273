//REACT
import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import questionStore from "../../store-mobx/questionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel, Checkbox } from "@mui/material";
import { scrollToTop } from "./HelpersQuestion";
import { isMobile } from "react-device-detect";
import { Interweave } from "interweave";

const MySwal = withReactContent(Swal);
class ImageChoixMultiple extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indexHover: null,
      hoverLoupe: false,
      selected: [],
      autres: [],
      modalites: this.props.question.images,
      options: this.props.question.options,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(event, modalite) {
    var newSelected = this.state.selected;
    var index = "";
    var aucunExists = false;
    this.state.modalites.forEach((moda) => {
      if (moda.aucun === true) {
        aucunExists = true;
      }
    });

    //SI AUCUN
    if (aucunExists) {
      if (event.target.checked && modalite.aucun === true) {
        newSelected = [];
        newSelected.push(event.target.value);
      } else if (event.target.checked) {
        if (this.state.options && this.state.options.max) {
          if (newSelected.length < this.state.options.max) {
            this.state.modalites.forEach((moda) => {
              if (moda.aucun === true) {
                index = newSelected.indexOf(moda.id);
                if (index > -1) {
                  newSelected.splice(index, 1);
                }
              }
            });
            newSelected.push(event.target.value);
          }
        } else {
          this.state.modalites.forEach((moda) => {
            if (moda.aucun === true) {
              index = newSelected.indexOf(moda.id);
              if (index > -1) {
                newSelected.splice(index, 1);
              }
            }
          });
          newSelected.push(event.target.value);
        }
      } else {
        index = newSelected.indexOf(event.target.value);
        if (index > -1) {
          newSelected.splice(index, 1);
        }
      }
    }
    //SINON
    else {
      // if(this.state.selected.includes(modalite.value))
      //   newSelected = this.state.selected.filter(moda => moda.value !== modalite.value);
      // else
      //   newSelected = this.state.selected.push(modalite.value);

      if (this.state.selected.includes(modalite.id)) {
        //newSelected = newSelected.filter(moda => modalite.value !== moda.value);

        index = newSelected.indexOf(modalite.id);
        if (index > -1) {
          newSelected.splice(index, 1);
        }
      } else {
        if (this.state.options && this.state.options.max) {
          if (newSelected.length < this.state.options.max) {
            newSelected.push(modalite.id);
          }
        } else {
          newSelected.push(modalite.id);
        }
      }
    }

    this.setState({ selected: newSelected });
    questionStore.reponse = newSelected;
  }

  handleChangePrecision(event, modalite) {
    var newSelected = this.state.selected;
    var newAutres = this.state.autres;
    var index;

    if (event.target.value !== "") {
      var exists = false;
      newAutres.forEach((autre) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
        }
      });
      if (exists === false) {
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      } else {
        index = newAutres.indexOf(modalite.id);
        newAutres.splice(index, 1);
        newAutres.push({
          modalite_id: modalite.id,
          precision: event.target.value,
        });
      }
      if (newSelected.includes(modalite.id) === false) {
        newSelected.push(modalite.id);
      }
    } else {
      newAutres.forEach((autre, index) => {
        if (autre.modalite_id === modalite.id) {
          exists = true;
          newAutres.splice(index, 1);
        }
      });

      index = newSelected.indexOf(modalite.id);
      if (newSelected.includes(modalite.id)) {
        newSelected.splice(index, 1);
      }
    }

    //SI LA PRECISION EST SUR UNE MODALITE AUCUN
    if (modalite.aucun === true) {
      newSelected = [];
      newSelected.push(modalite.id);

      newAutres = [];
      newAutres.push({
        modalite_id: modalite.id,
        precision: event.target.value,
      });
    }

    this.setState({ selected: newSelected, autres: newAutres });
    // this.props.setFieldValue("reponse_autre", newAutres);
    // this.props.setFieldValue("reponse", newSelected);

    questionStore.reponse = newSelected;
    questionStore.reponse_autre = newAutres;
  }

  //Fonction appelée pour zoomer sur une image
  zoomImage(url) {
    return MySwal.fire({
      imageUrl: url,
      imageAlt: "Image",
      showCloseButton: true,
      showConfirmButton: false,
      customClass: "popup-image",
    });
  }

  //Affichage de la question
  afficheQuestion() {
    var images = this.props.question.images;
    const blocImages = images.map((image, index) => {
      if (!image.sans_image) {
        return (
          <div
            key={image.value}
            className={
              this.state.selected.includes(image.id)
                ? "image-container selected"
                : "image-container "
            }
            onPointerEnter={() => this.setState({ indexHover: index })}
            onPointerLeave={() => this.setState({ indexHover: null })}
            onClick={(event) =>
              this.handleChange(
                {
                  target: {
                    value: image.id,
                    checked: this.state.selected.includes(image.id) ? false : true,
                  },
                },
                image
              )
            }
          >
            <div className="wrapper-image">
              <img
                alt="img"
                src={
                  process.env.REACT_APP_URL_WEB +
                  "/questionnaires/" +
                  questionStore.questionnaire_id +
                  "/questions/" +
                  questionStore.question.id +
                  "/images/" +
                  image.id
                }
                className="image"
              />
            </div>
            {this.props.question.options.affichage_label_image === true ? (
              <div className="image-label">
                <Interweave content={image.label} />
              </div>
            ) : (
              ""
            )}
            {isMobile && (
              <div
                className="zoom-image-container"
                onPointerEnter={() => this.setState({ hoverLoupe: true })}
                onPointerLeave={() => this.setState({ hoverLoupe: false })}
                onClick={() =>
                  this.zoomImage(
                    process.env.REACT_APP_URL_WEB +
                      "/questionnaires/" +
                      questionStore.questionnaire_id +
                      "/questions/" +
                      questionStore.question.id +
                      "/images/" +
                      image.id
                  )
                }
              >
                <FontAwesomeIcon icon={faSearchPlus} className={"zoom-icon"} />
              </div>
            )}
            {!isMobile && this.state.indexHover !== null && this.state.indexHover === index ? (
              <div
                className="zoom-image-container"
                onPointerEnter={() => this.setState({ hoverLoupe: true })}
                onPointerLeave={() => this.setState({ hoverLoupe: false })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.zoomImage(
                    process.env.REACT_APP_URL_WEB +
                      "/questionnaires/" +
                      questionStore.questionnaire_id +
                      "/questions/" +
                      questionStore.question.id +
                      "/images/" +
                      image.id
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faSearchPlus}
                  className={this.state.hoverLoupe ? "zoom-icon hover" : "zoom-icon"}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return null;
      }
    });

    const blocModas = this.props.question.images.map((image, index) => {
      if (image.sans_image === true) {
        return (
          <div key={image.value} className="modalite-container">
            <FormControlLabel
              value={image.id}
              className="image"
              control={
                <Checkbox
                  color="secondary"
                  onChange={(event) => this.handleChange(event, image)}
                  checked={this.state.selected.indexOf(image.id) > -1 ? true : false}
                  label={image.label}
                />
              }
              label={<Interweave content={image.label} />}
              onChange={(event) => {
                this.setState({ selected: event.target.value });
                questionStore.reponse = event.target.value;
              }}
              checked={
                image.id === this.state.selected && this.state.selected !== "" ? true : false
              }
            />

            {/* {image.precision ? (
              <div>
                <input
                  label="précisez"
                  variant="outlined"
                  type="text"
                  className="colored"
                  placeholder={t("entrez_precision")}
                  name="precision"
                  value={valuePrecision}
                  onChange={(event) =>
                    this.handleChangePrecision(event, image)
                  }
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="conteneur-items">
        <div className="image-choix">{blocImages}</div>
        <div className="modas-choix">{blocModas}</div>
      </div>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

export default ImageChoixMultiple;
