import * as React from "react";

function SvgPouce(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 418.1 429.8" fill="#ccc">
      <g>
        <path
          d="M112.2,303.9c0-33.3,0.1-66.5-0.1-99.8c0-3.6,1.2-5.6,4.1-7.4c22-13.7,43.9-27.5,65.6-41.5c2.9-1.9,5.7-5,7.1-8.2
		c18.9-43.5,37.6-87.1,56.4-130.7c7-16.4,23.9-21.2,38.7-10.9c11.3,7.8,16.3,19.5,18.3,32.5c2.7,18.2,1.7,36.2-4.4,53.8
		c-8.9,25.6-17.4,51.4-26,77.2c-2.8,8.4-0.4,11.6,8.5,11.6c28.4,0,56.7-0.1,85.1,0.1c18.8,0.1,33.8,8.2,45.1,23
		c7.1,9.4,9.2,20,6.2,31.6c-12.3,46.8-24.4,93.7-36.8,140.5c-3.6,13.5-8.8,26.4-17.7,37.5c-8.8,11-20,16.7-34.4,16.7
		c-55.6-0.3-111.1,0-166.7-0.5c-10.5-0.1-21.2-2.2-31.4-5c-13.7-3.7-17.5-9.8-17.5-24C112.2,368.2,112.2,336,112.2,303.9z"
        />
        <path
          d="M99.3,300.6c0,35.2,0,70.4,0,105.7c0,16.1-7.3,23.4-23.4,23.4c-18,0-36,0-54.1,0c-13.7,0-21.8-7.9-21.8-21.6
		C0,336.3,0,264.6,0,192.8c0-13.6,8.2-21.7,21.8-21.8c18.7-0.1,37.4-0.1,56,0c13.3,0,21.4,8.1,21.5,21.5
		C99.4,228.6,99.3,264.6,99.3,300.6z"
        />
      </g>
    </svg>
  );
}

export default SvgPouce;
