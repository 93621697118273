import * as React from "react";

function SvgSmiley3(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.927 122.927">
      <defs>
        <filter
          id="Ellipse_36"
          x="0"
          y="0"
          width="122.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-03" transform="translate(4.321 3.175)">
        <g id="Groupe_1902" data-name="Groupe 1902">
          <rect
            id="Rectangle_426"
            data-name="Rectangle 426"
            width="117"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1901" data-name="Groupe 1901" transform="translate(4.679 2.825)">
            <g id="Groupe_1900" data-name="Groupe 1900">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_36)">
                <ellipse
                  id="Ellipse_36-2"
                  data-name="Ellipse 36"
                  cx="52.464"
                  cy="52.464"
                  rx="52.464"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1903" data-name="Groupe 1903" transform="translate(10.051 8.197)">
          <ellipse
            id="Ellipse_37"
            data-name="Ellipse 37"
            cx="47.092"
            cy="47.092"
            rx="47.092"
            ry="47.092"
            fill="#fd6a38"
          />
        </g>
        <g id="Groupe_1906" data-name="Groupe 1906" transform="translate(39.045 38.094)">
          <g id="Groupe_1904" data-name="Groupe 1904">
            <path
              id="Tracé_1864"
              data-name="Tracé 1864"
              d="M92.383,102.284h0A4.869,4.869,0,0,1,87.5,97.4V92.383A4.869,4.869,0,0,1,92.383,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,92.383,102.284Z"
              transform="translate(-87.5 -87.5)"
            />
          </g>
          <g id="Groupe_1905" data-name="Groupe 1905" transform="translate(26.504)">
            <path
              id="Tracé_1865"
              data-name="Tracé 1865"
              d="M152.083,102.284h0A4.869,4.869,0,0,1,147.2,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,152.083,102.284Z"
              transform="translate(-147.2 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1907" data-name="Groupe 1907" transform="translate(40.279 65.291)">
          <path
            id="Tracé_1866"
            data-name="Tracé 1866"
            d="M92.513,160.275a2.3,2.3,0,0,1-1.554-.622,2.237,2.237,0,0,1-.044-3.152c1.332-1.332,8.435-7.991,18.779-7.1a23.074,23.074,0,0,1,14.34,6.792,2.229,2.229,0,1,1-3.152,3.152,19.041,19.041,0,0,0-11.632-5.505c-8.391-.755-14.162,4.662-15.228,5.771A1.923,1.923,0,0,1,92.513,160.275Z"
            transform="translate(-90.287 -149.317)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley3;
