import decode from "jwt-decode";

export default class QuestionnaireService {
  // Initializing important variables
  constructor() {
    this.domain = process.env.REACT_APP_URL_API; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
  }

  //Signalement de comportement
  signalerAbus(tokenRepondant, idQuestionnaire, commentaire) {
    var result = false;
    result = this.fetch(`${this.domain}/repondants/signaler-abus`, {
      method: "POST",
      body: {
        token_repondant: tokenRepondant,
        questionnaire_id: idQuestionnaire,
        commentaire: commentaire,
      },
    }).then((res) => {
      return res;
    });
    return result;
  }

  getQuestion(idQuestionnaire, idQuestion, tokenRepondant) {
    var result = false;
    result = this.fetch(
      `${this.domain}/repondants/recupere_question_courante`,
      {
        method: "POST",
        body: {
          questionnaire_id: idQuestionnaire,
          question_id: idQuestion,
          token_repondant: tokenRepondant,
        },
      }
    ).then((res) => {
      return res;
    });
    return result;
  }

  switchQuestionnaire(lien, body) {
    var result = false;
    result = this.fetch(`${this.domain}/questionnaires/${lien}`, {
      method: "POST",
      body: body,
    }).then((res) => {
      return res;
    });
    return result;
  }

  listeQuestion = (tokenRepondant, idQuestionnaire) => {
    const url =
      process.env.REACT_APP_URL_API +
      "/token_repondant/" +
      tokenRepondant +
      "/questionnaires/" +
      idQuestionnaire +
      "/sections/lister";
    return this.fetch(url, {
      method: "POST",
    });
  };

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken("token");
    }

    //on ajoute le type pour le bucket ciblé + le stringify
    var newOptions = { ...options };
    if (options.method !== "GET") {
      newOptions = {
        ...options,
        body: JSON.stringify({
          ...options.body,
          type: window.sessionStorage.getItem("type"),
        }),
      };
    }

    return fetch(url, {
      headers,
      ...newOptions,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken("token"); // GEtting token from localStorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  getToken(name) {
    // Retrieves the user token from localStorage
    return localStorage.getItem(name);
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
