//REACT
import React, { useEffect } from "react";

import questionStore from "../../store-mobx/questionStore";
import { useTranslation } from "react-i18next";
import { scrollToTop } from "./HelpersQuestion";
import { Interweave } from "interweave";

const ChampsTextes = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleChange = (champId, value) => {
    var tempReponse = Object.assign([], questionStore.reponse);

    //Si on a du texte
    if (value !== "") {
      let reponseIndex = tempReponse.findIndex((champ) => champ.id === champId);
      if (reponseIndex !== -1) {
        tempReponse[reponseIndex].value = value;
      } else {
        tempReponse.push({ id: champId, value: value });
      }
    }
    //Si on a un un texte vide
    else {
      let reponseIndex = tempReponse.findIndex((champ) => champ.id === champId);
      tempReponse.splice(reponseIndex, 1);
    }

    questionStore.reponse = tempReponse;
  };

  return (
    <div className="champs-textes">
      {props.question.champs.map((champ) => {
        return (
          <div key={champ.id} className="champ-container">
            <label htmlFor={champ.id} className={props.errors[champ.id] ? "label error" : "label"}>
              <Interweave content={champ.label} />
            </label>
            <input
              className={props.errors[champ.id] ? "input error" : "input"}
              placeholder={champ.facultatif ? t("entrez_reponse_facultatif") : t("entrez_reponse")}
              type="text"
              onChange={(event) => handleChange(champ.id, event.target.value)}
            />
          </div>
        );
      })}

      {/* <input
        id="outlined-textarea"
        label={t("entrez_reponse")}
        placeholder={t("reponse")}
        className="colored"
        onChange={(event) => this.handleChange(event.target.value)}
        type="number"
        data-testid="numerique"
        min={this.state.options.min ? this.state.options.min : ""}
        max={this.state.options.max ? this.state.options.max : ""}
      />
      {this.state.options.libelle ? (
        <div className="libelle" data-testid="libelle">
          {this.state.options.libelle}
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default ChampsTextes;
