import React from "react";
import Civilite from "../inputs/Civilite";
import Consentement from "../inputs/Consentement";
import Date from "../inputs/Date";
import Texte from "../inputs/Texte";
import Email from "../inputs/Email";
import NumeriqueUniquement from "../inputs/NumeriqueUniquement";
import Telephone from "../inputs/Telephone";
import TexteUniquement from "../inputs/TexteUniquement";
import { observer } from "mobx-react";

const Coordonnees = observer((props) => {
  let listeInput = {
    civilite: Civilite,
    texte_libre: Texte,
    email: Email,
    date: Date,
    telephone: Telephone,
    texte_uniquement: TexteUniquement,
    numerique: NumeriqueUniquement,
  };

  return (
    <div className="coordonnees-container">
      <br></br>
      {props.champs.map((champ, index) => {
        const Input = listeInput[champ.type];
        var err = [];
        if (props.errors && props.errors[champ.id]) {
          err = props.errors[champ.id];
        }

        return (
          <div key={index} className="champ">
            <Input
              className="input-coordonnees"
              errorsDisplay={err}
              idChamp={champ.id}
              champ={champ}
              {...props}
            />
          </div>
        );
      })}
      <br></br>
      {props.consentements.map((consentement, index) => {
        var err = [];
        if (props.errors && props.errors[consentement.id]) {
          err = props.errors[consentement.id];
        }
        return (
          <div key={index} className="consentement">
            <Consentement
              className="input-coordonnees "
              consentement={consentement}
              errorsDisplay={err}
              {...props}
            />
          </div>
        );
      })}
    </div>
  );
});

export default Coordonnees;
