//REACT
import React from "react";
import { useTranslation } from "react-i18next";

const QuestionnaireFerme = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", height: "90vh" }} className="error-404">
      <h1>{t("questionnaire_ferme.title")}</h1>
      <h2>{t("questionnaire_ferme.text")}</h2>
    </div>
  );
};

export default QuestionnaireFerme;
