//REACT
import React from "react";

//SERVICE & STORE
import questionStore from "../store-mobx/questionStore";

//MATERIAL UI
import { CircularProgress } from "@mui/material";
import questionnaireStore from "../store-mobx/questionnaireStore";
import history from "../history";
import { observer } from "mobx-react";
import DisplayFullscreenError from "./DisplayFullscreenError";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SwitchQuestionnaire = observer(() => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.idquestion) {
      questionStore.questionCiblee = params.idquestion;
    }

    //token du repondant
    if (params.token_repondant) {
      questionnaireStore.token_repondant = params.token_repondant;
    }
    questionnaireStore.type_lien = params.type;
    questionnaireStore.clef = params.clef;

    const queryString = require("query-string");
    const parametres = queryString.parse(history.location.search);

    //on passe dans le switch pour recup la reference et le token
    questionnaireStore.switchQuestionnaire(params, parametres).then((retour) => {
      if (retour.erreur === true) {
        questionnaireStore.erreurs.push(retour);
      } else if (retour.reference) {
        // history.replace("/questionnaires/" + retour.reference + "/" + retour.token);
        navigate("/questionnaires/" + retour.reference + "/" + retour.token);
      }
    });
  }, []);

  //Affichage du loader
  const afficheChargement = () => {
    if (questionnaireStore.erreurs.length === 0) {
      return (
        <div className="chargement">
          {t("chargement_switch")}
          <br />
          <br />
          <CircularProgress />
        </div>
      );
    }
  };

  //Affichage du loader
  const afficheErreurs = () => {
    if (questionnaireStore.erreurs.length > 0) {
      return (
        <div className="erreurs">
          {questionnaireStore.erreurs.map((erreur, index) => {
            return <DisplayFullscreenError key={index} erreur={erreur} />;
          })}
        </div>
      );
    }
  };

  return (
    <>
      {afficheErreurs()}
      {afficheChargement()}
    </>
  );
});

export default SwitchQuestionnaire;
