//REACT
import React, { Component, Fragment } from "react";
import { Suspense } from "react";

//OTHER
import { Interweave } from "interweave";
import { scrollToTop } from "./HelpersQuestion";
import questionnaireStore from "../../store-mobx/questionnaireStore";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { transformMedia } from "./HelpersMedia";

@observer
class HOCIntro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.selected,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleChange(value) {
    this.setState({ value: value });
    this.props.setFieldValue(this.props.name, value);
  }

  //Affichage de la question
  afficheQuestion() {
    const { t } = this.props;

    //traitement de la duree
    var textToDisplay = this.props.question.editor;

    //ON CHECK QUE LE TAG [DUREE - MIS Group] existe
    if (textToDisplay.search("[DUREE - MIS Group]") > 0) {
      //SI ON A L'INFO DE MISGROUP
      if (questionnaireStore.questionnaire.duree) {
        let texteMinutes = "";
        if (questionnaireStore.questionnaire.duree <= 1) {
          texteMinutes = t("minute");
        } else {
          texteMinutes = t("minutes");
        }
        //On remplace le tag par la durée de la fiche étude
        textToDisplay = textToDisplay.replace(
          "[DUREE - MIS Group]",
          questionnaireStore.questionnaire.duree + " " + texteMinutes
        );
      }
      //SINON 5MN PAR DEFAUT
      else {
        textToDisplay = textToDisplay.replace("[DUREE - MIS Group]", "5 " + t("minutes"));
      }
    }

    //ON CHECK QUE LE TAG [TITRE - MIS Group] existe
    if (textToDisplay.search("[TITRE - MIS Group]") > 0) {
      if (
        questionnaireStore.questionnaire?.options?.afficher_titre &&
        questionnaireStore.questionnaire.options.titre_custom
      ) {
        textToDisplay = textToDisplay.replace(
          "[TITRE - MIS Group]",
          questionnaireStore.questionnaire.options.titre_custom
        );
      } else {
        textToDisplay = textToDisplay.replace(
          "[TITRE - MIS Group]",
          questionnaireStore.questionnaire.nom
        );
      }
    }

    return (
      <div className="ck-content">
        <Interweave transform={transformMedia} content={textToDisplay} />
      </div>
    );
  }

  render() {
    return <Fragment>{this.afficheQuestion()}</Fragment>;
  }
}

const Intr = withTranslation()(HOCIntro);

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function Intro(props) {
  return (
    <Suspense fallback="loading">
      <Intr {...props} />
    </Suspense>
  );
}
