//REACT
import { Field } from "formik";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import questionStore from "../../store-mobx/questionStore";

const Texte = observer((props) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    const reponseDonnee = { id: props.champ.id, valeur: event.target.value };

    if (questionStore.reponse["champs"]) {
      var indexReponsePresente = questionStore.reponse["champs"].findIndex(
        (x) => x.id === reponseDonnee.id
      );
      if (questionStore.reponse["champs"] && indexReponsePresente !== -1) {
        questionStore.reponse["champs"][indexReponsePresente] = reponseDonnee;
      } else {
        questionStore.reponse["champs"].push(reponseDonnee);
      }
    }
  };

  //nom de classe dynamique pour afficher le champs en erreur etc
  var className = "";
  if (props.champ.taille) {
    className = className + " " + props.champ.taille;
  }
  if (props.errorsDisplay.length > 0) {
    className = className + " error";
  }

  return (
    <div className={props.className}>
      <Field
        type="text"
        name="texte"
        placeholder={
          props.champ.facultatif
            ? props.champ.label + " (" + t("facultatif") + ")"
            : props.champ.label
        }
        className={className}
        onChange={(e) => handleChange(e)}
      />
      {props.errorsDisplay.map((error, index) => {
        return (
          <div key={index} className="error-message">
            {error}
          </div>
        );
      })}
    </div>
  );
});

export default Texte;
