import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import questionStore from "../store-mobx/questionStore";
import questionnaireStore from "../store-mobx/questionnaireStore";

import Lottie from "lottie-react";
import loupeLoader from "../lotties/loupe";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loupeLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  style: {
    height: "35px",
    width: "35px",
  },
};

const ButtonNext = (props) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(props.tempsAttente - 1);

  useEffect(() => {
    setCounter(props.tempsAttente - 1);
  }, [questionStore.question.id]);

  useEffect(() => {
    let timer = counter > 0 && setInterval(() => setCounter((prev) => prev - 1), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  var texte = t("suivant");

  if (
    questionnaireStore.questionnaire.options?.theme === 10 &&
    questionnaireStore.questionnaire.options?.theme_custom?.suivant
  ) {
    texte = questionnaireStore.questionnaire.options.theme_custom.suivant;
  }

  if (props.texte) {
    texte = props.texte;
  }

  var className = "button-primary ";
  if (props.className) {
    className = className + props.className;
  }

  return (
    <Fragment>
      {props.isSubmitting ? (
        <div className="conteneur-loader">
          {props.loupe ? <Lottie {...defaultOptions} /> : <CircularProgress />}
        </div>
      ) : (
        <motion.button
          layout
          exit={{ x: -300 }}
          className={className}
          type="submit"
          disabled={props.isSubmitting || counter > 0}
        >
          {texte}
          {counter > 0 && <> ({counter})</>}
        </motion.button>
      )}
    </Fragment>
  );
};

export default ButtonNext;
