//REACT
import React, { useEffect, useMemo, useRef } from "react";

//MATERIAL UI
import { Interweave } from "interweave";
import { scrollToTop } from "./HelpersQuestion";
import { observer } from "mobx-react";
import { addZoomToImages, transformMedia } from "./HelpersMedia";
import Consentement from "../inputs/Consentement";

const Transition = observer((props) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const containerRef = useRef(null);

  const contentEditor = useMemo(() => {
    if (props.question?.editor) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(props.question.editor, "text/html");
      return doc.body.innerHTML;
    }
    return "";
  }, [props.question?.editor]);

  useEffect(() => {
    addZoomToImages(containerRef);
  }, [contentEditor]);

  return (
    <>
      <div className="ck-content" ref={containerRef}>
        <Interweave transform={transformMedia} content={contentEditor} />
      </div>
      <br />

      {props.consentements?.map((consentement, index) => {
        var err = [];
        if (props.errors && props.errors[consentement.id]) {
          err = props.errors[consentement.id];
        }
        return (
          <div key={index} className="consentement">
            <Consentement
              className="input-coordonnees "
              consentement={consentement}
              errorsDisplay={err}
              {...props}
            />
          </div>
        );
      })}
    </>
  );
});

export default Transition;
