import * as React from "react";

function SvgSmiley4(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.927 122.927">
      <defs>
        <filter
          id="Ellipse_38"
          x="0"
          y="0"
          width="121.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-04" transform="translate(4.319 3.175)">
        <g id="Groupe_1910" data-name="Groupe 1910">
          <rect
            id="Rectangle_427"
            data-name="Rectangle 427"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1909" data-name="Groupe 1909" transform="translate(4.681 2.825)">
            <g id="Groupe_1908" data-name="Groupe 1908">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_38)">
                <ellipse
                  id="Ellipse_38-2"
                  data-name="Ellipse 38"
                  cx="51.964"
                  cy="52.464"
                  rx="51.964"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1911" data-name="Groupe 1911" transform="translate(10.053 8.197)">
          <ellipse
            id="Ellipse_39"
            data-name="Ellipse 39"
            cx="46.592"
            cy="47.092"
            rx="46.592"
            ry="47.092"
            fill="#fd9338"
          />
        </g>
        <g id="Groupe_1914" data-name="Groupe 1914" transform="translate(38.521 38.094)">
          <g id="Groupe_1912" data-name="Groupe 1912">
            <path
              id="Tracé_1867"
              data-name="Tracé 1867"
              d="M92.983,102.284h0A4.869,4.869,0,0,1,88.1,97.4V92.383A4.869,4.869,0,0,1,92.983,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,92.983,102.284Z"
              transform="translate(-88.1 -87.5)"
            />
          </g>
          <g id="Groupe_1913" data-name="Groupe 1913" transform="translate(26.504)">
            <path
              id="Tracé_1868"
              data-name="Tracé 1868"
              d="M152.683,102.284h0A4.869,4.869,0,0,1,147.8,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,152.683,102.284Z"
              transform="translate(-147.8 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1916" data-name="Groupe 1916" transform="translate(55.638 67.146)">
          <g id="Groupe_1915" data-name="Groupe 1915">
            <path
              id="Tracé_1869"
              data-name="Tracé 1869"
              d="M129.013,161.713a1.9,1.9,0,0,1-1.6-.8,2,2,0,0,1,.444-2.8,25.144,25.144,0,0,1,14.828-4.617h0a2,2,0,0,1,0,4,21.13,21.13,0,0,0-12.475,3.862A2.353,2.353,0,0,1,129.013,161.713Z"
              transform="translate(-127.028 -153.5)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley4;
