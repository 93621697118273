import decode from "jwt-decode";
import questionnaireStore from "../store-mobx/questionnaireStore";

export default class AuthService {
  // Initializing important variables
  constructor() {
    this.domain = process.env.REACT_APP_URL_API; // API server domain
  }

  /**
   * Fonction flechée permettant de factoriser le fetch avec quelques paramètres
   */
  fetch = (url, options) => {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken("token");
    }

    var url_string = window.location.href;
    var uurl = url_string.split("/");
    uurl.pop();

    //on ajoute le type pour le bucket ciblé + le stringify
    var newOptions = { ...options };
    if (options.method !== "GET") {
      newOptions = {
        ...options,
        body: JSON.stringify({
          ...options.body,
          type: window.sessionStorage.getItem("type"),
        }),
      };
    }

    return fetch(url, {
      headers,
      ...newOptions,
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  };

  getTypeRepondant = (questionnaire_id, token_repondant) => {
    // Get a token from api server using the fetch api
    return this.fetch(`${this.domain}/getTypeRepondant`, {
      method: "POST",
      body: {
        questionnaire_id,
        token_repondant,
      },
    }).then((res) => {
      if (!res.erreur) {
        sessionStorage.setItem("type", res.type); // Setting the type in sessionStorage
      }
      return Promise.resolve(res);
    });
  };

  /**
   * Permet de se logger grâce au token envoyé par le module de création
   */
  logFromToken = (questionnaire_id, token_repondant, type) => {
    // Get a token from api server using the fetch api
    return this.fetch(`${this.domain}/logFromToken`, {
      method: "POST",
      body: {
        questionnaire_id,
        token_repondant,
      },
    }).then((res) => {
      questionnaireStore.token = res.token;
      questionnaireStore.token_repondant = res.token_repondant;
      this.setToken("token", res.token); // Setting the token in localStorage
      return Promise.resolve(res);
    });
  };

  /**
   * Check si l'on est loggé (bearer token)
   */
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken("token"); // GEtting token from localStorage
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(name, idToken) {
    // Saves user token to localStorage (redux)
    localStorage.setItem(name, idToken);
  }

  getToken(name) {
    // Retrieves the user token from localStorage
    // console.log("getToken : ", store.getState().token.token);
    //return store.getState().connect.token;
    return localStorage.getItem(name);
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
