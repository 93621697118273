import * as React from "react";

function SvgSmiley7(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.927 122.927">
      <defs>
        <filter
          id="Ellipse_44"
          x="0"
          y="0"
          width="122.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-07" transform="translate(4.726 3.175)">
        <g id="Groupe_1936" data-name="Groupe 1936">
          <rect
            id="Rectangle_430"
            data-name="Rectangle 430"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1935" data-name="Groupe 1935" transform="translate(4.274 2.825)">
            <g id="Groupe_1934" data-name="Groupe 1934">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_44)">
                <ellipse
                  id="Ellipse_44-2"
                  data-name="Ellipse 44"
                  cx="52.464"
                  cy="52.464"
                  rx="52.464"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1937" data-name="Groupe 1937" transform="translate(9.646 8.197)">
          <ellipse
            id="Ellipse_45"
            data-name="Ellipse 45"
            cx="47.092"
            cy="47.092"
            rx="47.092"
            ry="47.092"
            fill="#d3dd86"
          />
        </g>
        <g id="Groupe_1940" data-name="Groupe 1940" transform="translate(39.253 38.094)">
          <g id="Groupe_1938" data-name="Groupe 1938">
            <path
              id="Tracé_1876"
              data-name="Tracé 1876"
              d="M94.483,102.284h0A4.869,4.869,0,0,1,89.6,97.4V92.383A4.869,4.869,0,0,1,94.483,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,94.483,102.284Z"
              transform="translate(-89.6 -87.5)"
            />
          </g>
          <g id="Groupe_1939" data-name="Groupe 1939" transform="translate(26.504)">
            <path
              id="Tracé_1877"
              data-name="Tracé 1877"
              d="M154.183,102.284h0A4.869,4.869,0,0,1,149.3,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,154.183,102.284Z"
              transform="translate(-149.3 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1942" data-name="Groupe 1942" transform="translate(49.075 71.837)">
          <g id="Groupe_1941" data-name="Groupe 1941">
            <path
              id="Tracé_1878"
              data-name="Tracé 1878"
              d="M113.013,168.911a1.924,1.924,0,0,1-1.2-2.309,1.985,1.985,0,0,1,2.4-1.465,21.6,21.6,0,0,0,13.052-.843,2,2,0,1,1,1.554,3.685,25.452,25.452,0,0,1-15.494,1.065A2.164,2.164,0,0,1,113.013,168.911Z"
              transform="translate(-111.746 -164.13)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley7;
