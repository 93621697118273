//REACT
import React from "react";
import questionStore from "../../store-mobx/questionStore";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";
import { Interweave } from "interweave";

const Consentement = (props) => {
  useEffect(() => {
    const consentementInitial = {
      id: props.consentement.id,
      label: props.consentement.label,
      valeur: 0,
    };

    if (questionStore.reponse["consentements"]) {
      questionStore.reponse["consentements"].push(consentementInitial);
    } else {
      questionStore.reponse["consentements"] = [consentementInitial];
    }
  }, []);

  // console.log(questionStore.reponse);
  const handleClick = (event) => {
    var checked = event.target.checked ? 1 : 0;
    var indexReponsePresente = questionStore.reponse["consentements"].findIndex(
      (x) => x.id === props.consentement.id
    );
    if (questionStore.reponse["consentements"] && indexReponsePresente !== -1) {
      questionStore.reponse["consentements"][indexReponsePresente]["valeur"] = checked;
    }
  };

  return (
    <>
      <div className={props.className}>
        <input
          type="checkbox"
          name={"consentement-checkbox consentement-" + props.consentement.value}
          id={"consentement-" + props.consentement.value}
          value={1}
          onClick={(e) => handleClick(e)}
        />
        <label htmlFor={"consentement-" + props.consentement.value}>
          <Interweave content={props.consentement.label} />
        </label>
      </div>
      {props.errorsDisplay.map((error, index) => {
        return (
          <div key={index} className="error-message">
            {error}
          </div>
        );
      })}
    </>
  );
};

export default Consentement;
