import * as React from "react";

function SvgSmiley5(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.927 122.927">
      <defs>
        <filter
          id="Ellipse_40"
          x="0"
          y="0"
          width="122.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-05" transform="translate(5.243 3.175)">
        <g id="Groupe_1919" data-name="Groupe 1919">
          <rect
            id="Rectangle_428"
            data-name="Rectangle 428"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1918" data-name="Groupe 1918" transform="translate(3.757 2.825)">
            <g id="Groupe_1917" data-name="Groupe 1917">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_40)">
                <ellipse
                  id="Ellipse_40-2"
                  data-name="Ellipse 40"
                  cx="52.464"
                  cy="52.464"
                  rx="52.464"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1920" data-name="Groupe 1920" transform="translate(9.128 8.197)">
          <ellipse
            id="Ellipse_41"
            data-name="Ellipse 41"
            cx="47.092"
            cy="47.092"
            rx="47.092"
            ry="47.092"
            fill="#ffc664"
          />
        </g>
        <g id="Groupe_1923" data-name="Groupe 1923" transform="translate(38.742 38.094)">
          <g id="Groupe_1921" data-name="Groupe 1921">
            <path
              id="Tracé_1870"
              data-name="Tracé 1870"
              d="M94.183,102.284h0A4.869,4.869,0,0,1,89.3,97.4V92.383A4.869,4.869,0,0,1,94.183,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,94.183,102.284Z"
              transform="translate(-89.3 -87.5)"
            />
          </g>
          <g id="Groupe_1922" data-name="Groupe 1922" transform="translate(26.504)">
            <path
              id="Tracé_1871"
              data-name="Tracé 1871"
              d="M153.883,102.284h0A4.869,4.869,0,0,1,149,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.918,4.918,0,0,1,153.883,102.284Z"
              transform="translate(-149 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1924" data-name="Groupe 1924" transform="translate(46.92 67.558)">
          <path
            id="Tracé_1872"
            data-name="Tracé 1872"
            d="M125.7,159.861a2.6,2.6,0,0,1-.577-.089,27.435,27.435,0,0,0-6.926-.932,29.131,29.131,0,0,0-7.68.932,2.208,2.208,0,0,1-1.154-4.262,34.273,34.273,0,0,1,8.879-1.11,32.263,32.263,0,0,1,8.08,1.11,2.2,2.2,0,0,1,1.554,2.708A2.264,2.264,0,0,1,125.7,159.861Z"
            transform="translate(-107.729 -154.4)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley5;
