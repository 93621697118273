//REACT
import React from "react";

//SERVICE & STORE
import AuthService from "../services/AuthService";
import questionStore from "../store-mobx/questionStore";

//MATERIAL UI
import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
// import Question from "../components/Question";
import questionnaireStore from "../store-mobx/questionnaireStore";
import { motion } from "framer-motion";
import Bandeau from "../components/Bandeau";
import DisplayFullscreenError from "./DisplayFullscreenError";
import { isMobile } from "react-device-detect";
import Footer from "../components/Footer";
import Question from "../components/Question";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ReponseQuestionnaire = observer(() => {
  const { t } = useTranslation();
  const params = useParams();
  const Auth = new AuthService();

  useEffect(() => {
    questionStore.questionnaire_id = "questionnaires::" + params.id;

    //token du repondant
    if (params.token_repondant) {
      questionnaireStore.token_repondant = params.token;
    }

    fetchData();

    return () => {
      console.log("unmount");
      var clef = window.localStorage.getItem("clef_partage");
      localStorage.clear();
      window.localStorage.setItem("clef_partage", clef);
    };
  }, []);

  const fetchData = () => {
    //si le bearer token existe
    if (Auth.loggedIn() && questionnaireStore.token_repondant) {
      //on charge la question
      questionStore.fetchQuestion(
        questionStore.questionnaire_id,
        questionnaireStore.token_repondant
      );
    } else {
      //definition des valeurs dans le store
      questionnaireStore.token_repondant = params.token;
      questionnaireStore.questionnaire_short_id = params.id;

      //Cas rare où l'on arrive depuis un lien déjà utilisé
      if (!window.sessionStorage.getItem("type")) {
        Auth.getTypeRepondant(
          questionnaireStore.questionnaire_short_id,
          questionnaireStore.token_repondant
        ).then((retour) => {
          //log a partir du token repondant
          Auth.logFromToken(
            questionnaireStore.questionnaire_short_id,
            questionnaireStore.token_repondant,
            window.sessionStorage.getItem("type")
          ).then((result) => {
            if (result.erreur === false) {
              //on charge la premiere question du questionnaire
              questionStore.fetchQuestion(questionStore.questionnaire_id, result.token_repondant);
            } else {
              questionnaireStore.erreurs.push(result);
            }
          });
        });
      } else {
        //log a partir du token repondant
        Auth.logFromToken(
          questionnaireStore.questionnaire_short_id,
          questionnaireStore.token_repondant,
          window.sessionStorage.getItem("type")
        ).then((result) => {
          if (result.erreur === false) {
            //on charge la premiere question du questionnaire
            questionStore.fetchQuestion(questionStore.questionnaire_id, result.token_repondant);
          } else {
            questionnaireStore.erreurs.push(result);
          }
        });
      }
    }
  };

  //Affichage du loader
  const afficheChargement = () => {
    if (questionStore.pageIsLoading === true) {
      return (
        <div className="chargement">
          {t("chargement")}
          <br />
          <br />
          <CircularProgress />
        </div>
      );
    }
  };

  //Affichage du layout
  const afficheQuestion = () => {
    if (questionnaireStore.erreurs.length > 0) {
      return (
        <div className="erreurs">
          {questionnaireStore.erreurs.map((erreur, index) => {
            return <DisplayFullscreenError key={index} erreur={erreur} />;
          })}
        </div>
      );
    }
    if (questionStore.question && questionStore.question.message !== "Unauthenticated.") {
      if (questionnaireStore.repondant.source === "test") {
        if (isMobile) {
          return (
            <main className={"test-mode"} id={"main"}>
              {(!questionnaireStore.questionnaire.options.hasOwnProperty("afficher_volet_test") ||
                questionnaireStore.questionnaire.options.afficher_volet_test) &&
              questionnaireStore.bandeauIsOpen ? (
                <Bandeau tokenRepondant={params.token} />
              ) : (
                <motion.div className="contenu" id="contenu">
                  <Question tokenRepondant={params.token} />
                  <Footer />
                </motion.div>
              )}
            </main>
          );
        } else {
          return (
            <main className={"test-mode"} id={"main"}>
              {(!questionnaireStore.questionnaire.options.hasOwnProperty("afficher_volet_test") ||
                questionnaireStore.questionnaire.options.afficher_volet_test) &&
              questionnaireStore.bandeauIsOpen ? (
                <Bandeau tokenRepondant={params.token} />
              ) : (
                ""
              )}

              <motion.div className="contenu" id="contenu">
                <Question tokenRepondant={params.token} />
                <Footer />
              </motion.div>
            </main>
          );
        }
      } else {
        return (
          <main id={"main"}>
            <motion.div className="contenu" id="contenu">
              <Question tokenRepondant={params.token} />
              <Footer />
            </motion.div>
          </main>
        );
      }
    }
  };

  return (
    <>
      {afficheQuestion()}
      {afficheChargement()}
    </>
  );
});

export default ReponseQuestionnaire;
