import { motion } from "framer-motion";
import { observer } from "mobx-react";
import React from "react";
import questionStore from "../store-mobx/questionStore";

const ProgressBarMISMobile = observer((props) => {
  if (questionStore.progression > 0) {
    return (
      <div className="progress-mis-mobile">
        <motion.div className={"progress-container"}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, width: `${questionStore.progression}%` }}
            exit={{ opacity: 0 }}
            className="bar"
          ></motion.div>
        </motion.div>
      </div>
    );
  } else {
    return <></>;
  }
});

export default ProgressBarMISMobile;
