import * as React from "react";

function SvgSmiley10(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.927 122.927">
      <defs>
        <filter
          id="Ellipse_50"
          x="0"
          y="0"
          width="121.927"
          height="122.927"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="smiley-10" transform="translate(4.134 3.175)">
        <g id="Groupe_1962" data-name="Groupe 1962">
          <rect
            id="Rectangle_433"
            data-name="Rectangle 433"
            width="116"
            height="115"
            fill="none"
            opacity="0.2"
          />
          <g id="Groupe_1961" data-name="Groupe 1961" transform="translate(4.866 2.825)">
            <g id="Groupe_1960" data-name="Groupe 1960">
              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_50)">
                <ellipse
                  id="Ellipse_50-2"
                  data-name="Ellipse 50"
                  cx="51.964"
                  cy="52.464"
                  rx="51.964"
                  ry="52.464"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Groupe_1963" data-name="Groupe 1963" transform="translate(10.238 8.197)">
          <ellipse
            id="Ellipse_51"
            data-name="Ellipse 51"
            cx="46.592"
            cy="47.092"
            rx="46.592"
            ry="47.092"
            fill="#67aa64"
          />
        </g>
        <g id="Groupe_1966" data-name="Groupe 1966" transform="translate(38.701 38.094)">
          <g id="Groupe_1964" data-name="Groupe 1964">
            <path
              id="Tracé_1885"
              data-name="Tracé 1885"
              d="M92.983,102.284h0A4.869,4.869,0,0,1,88.1,97.4V92.383A4.869,4.869,0,0,1,92.983,87.5h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,92.983,102.284Z"
              transform="translate(-88.1 -87.5)"
            />
          </g>
          <g id="Groupe_1965" data-name="Groupe 1965" transform="translate(26.504)">
            <path
              id="Tracé_1886"
              data-name="Tracé 1886"
              d="M152.683,102.284h0A4.869,4.869,0,0,1,147.8,97.4V92.383a4.869,4.869,0,0,1,4.883-4.883h0a4.869,4.869,0,0,1,4.883,4.883v5.061A4.86,4.86,0,0,1,152.683,102.284Z"
              transform="translate(-147.8 -87.5)"
            />
          </g>
        </g>
        <g id="Groupe_1968" data-name="Groupe 1968" transform="translate(39.553 63.986)">
          <g id="Groupe_1967" data-name="Groupe 1967">
            <path
              id="Tracé_1887"
              data-name="Tracé 1887"
              d="M108.075,161.634a17.821,17.821,0,0,1-17.98-12.653,1.994,1.994,0,0,1,3.818-1.154,13.807,13.807,0,0,0,26.46,0,1.994,1.994,0,0,1,3.818,1.154,17.628,17.628,0,0,1-11.942,11.942A20.034,20.034,0,0,1,108.075,161.634Z"
              transform="translate(-90.011 -146.411)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSmiley10;
